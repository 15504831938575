<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>礼包管理</el-breadcrumb-item>
      <el-breadcrumb-item>礼包新增</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-border">
      <el-form ref="form" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="游戏id">
              <el-input size="small" clearable v-model="form.gameId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="礼包名称">
              <el-input size="small" clearable v-model="form.giftName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="礼包类型">
              <el-select placeholder="请选择" size="small" v-model="form.giftType">
                <el-option label="免费" value="0"></el-option>
                <el-option label="日冲" value="1"></el-option>
                <el-option label="累充" value="2"></el-option>
                <el-option label="限时单日充值" value="3"></el-option>
                <el-option label="限时累计充值" value="4"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="礼包发放方式">
              <el-select placeholder="请选择" size="small" v-model="form.giftIssueType">
                <el-option label="自动发码" value="0"></el-option>
                <el-option label="客服发码" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="领取次数限制">
              <el-select placeholder="请选择" size="small" v-model="form.giftNumberLimit">
                <el-option label="只能领取一次" value="0"></el-option>
                <el-option label="每天可以领取一次" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="礼包分组" >
              <el-input size="small" clearable v-model="form.giftGroup"
                        placeholder="没有就不填">></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="礼包限制金额" >
              <el-input size="small" clearable v-model="form.giftMoney"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="礼包开始时间">
              <el-date-picker
                  clearable
                  v-model="form.giftStart"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="礼包结束时间">
              <el-date-picker
                  clearable
                  v-model="form.giftEnd"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="领取条件" clearable>
              <el-input
                  v-model="form.giftConditions"
                  type="textarea"
                  :rows="3"
                  placeholder="请输入内容">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="礼包内容" clearable>
              <el-input
                  v-model="form.giftContent"
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="礼包说明" clearable>
              <el-input
                  v-model="form.giftExplain"
                  type="textarea"
                  :rows="3"
                  placeholder="请输入内容">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="16" align="center">
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
              <el-button @click="goBack">返回</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
import {addGift} from "@/api/request";

export default {
  name: "GiftAdd",
  data() {
    return {
      nowDate:new Date(),
      form:{
        gameId:'',
        giftName:'',
        giftType:'',
        giftIssueType:'',
        giftNumberLimit:'',
        giftGroup:'',
        giftMoney:'',
        giftStart:new Date().setFullYear((new Date().getFullYear()-1)),
        giftEnd:new Date().setFullYear((new Date().getFullYear()+1)),
        giftConditions:'',
        giftContent:'',
        giftExplain:'',
      }
    }
  },
  methods:{
    onSubmit() {
      let params = {
        gameId: this.form.gameId,
        giftName: this.form.giftName,
        giftType: this.form.giftType,
        giftIssueType: this.form.giftIssueType,
        giftNumberLimit: this.form.giftNumberLimit,
        giftGroup: this.form.giftGroup,
        giftMoney: this.form.giftMoney,
        giftStart: this.form.giftStart,
        giftEnd: this.form.giftEnd,
        giftConditions: this.form.giftConditions,
        giftContent: this.form.giftContent,
        giftExplain:this.form.giftExplain
      }
      addGift(params).then(res => {
        if (res.data.success) {
          this.$message.success('礼包新增成功');
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    goBack() {
      this.$router.push({path: "/gift/list"});
    },
  }
}
</script>

<style scoped>
.main-border {
  width: 100%;
  padding: 0 12px;
}

.el-form-item {
}

.el-input {
  width: 300px !important;
}

.el-select {
  width: 300px;
}

/deep/ .el-input__inner {
  border-radius: 2px !important;
  border: 1px solid #aaaaac !important;
}

.el-button {
  font-weight: 700;
  width: 100px;
}

/deep/ .el-form-item__label {
  font-weight: 600;
  font-size: 13px;
}
</style>