<template>
  <div class="main-container">
    <div class="main-border">
      <div class="line-panel">
        <el-image :src="logo" style="width: 35px; height: 35px;"></el-image>
        <span>会游CPS</span>
      </div>
      <el-divider></el-divider>
      <div class="form-border">
        <el-form :model="ruleForm" label-position="top" status-icon :rules="rules" ref="ruleForm"
                 label-width="4rem" class="demo-ruleForm">
          <el-form-item label="账号" prop="account">
            <el-input type="text" v-model="ruleForm.account" autocomplete="off" placeholder="请输入账号"
                      @keyup.enter.native="submitForm('ruleForm')"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="ruleForm.password" autocomplete="off" placeholder="请输入密码"
                      @keyup.enter.native="submitForm('ruleForm')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button :loading="loginLoading" size="medium" class="login-button"
                       @click="submitForm('ruleForm')">立即登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div>
      <el-dialog title="手机号验证" :visible.sync="dialogFormVisible" :center=true width="400px" :show-close=false
                 :close-on-click-modal=false :close-on-press-escape="false">
        <el-form :model="ruleForm" class="demo-ruleForm">
          <el-form-item label="手机号" label-width="100px">
            <el-input v-model="ruleForm.phone" disabled="disabled" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="验证码" label-width="100px">
            <el-input v-model="ruleForm.checkCode" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="" label-width="100px">
            <el-button plain native-type="button" :disabled="disabledSend" class="send-button"
                       color="#8A8888" @click="sendLoginMsg">
              <span>{{ buttonName }}</span>
            </el-button>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" size="medium" @click="submitForm('ruleForm')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import {doLogin, sendMsg} from '@/api/request'
import crypto from '@/utils/crypto.js';
import logo from '@/assets/logo.png'

import backgroundImg from '@/assets/background.jpg'

export default {
  name: 'login',
  data() {
    return {
      backgroundImg,
      logo,
      activeName: 'first',
      dialogFormVisible: false,
      disabledSend: false,
      showSendText: true,
      buttonName: '获取验证码',
      time: 60,
      loginLoading: false,
      ruleForm: {
        password: '',
        account: '',
        checkCode: '',
        phone: ''
      },
      rules: {
        account: [
          {pattern: /^\w{3,20}$/, trigger: 'blur', message: '请输入正确的账号'},
          {required: true, message: '请输入账号'}
        ],
        password: [
          {pattern: /^.{6,18}$/, trigger: 'blur', message: '密码长度在6-18位之间'},
          {required: true, message: '请输入密码'}
        ]
      }
    };
  },
  methods: {

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogFormVisible == true && !this.ruleForm.checkCode) {
            this.$alert("请输入验证码");
          } else {
            this.loginLoading = true;
            let params = {
              loginName: this.ruleForm.account,
              password: crypto.encrypt(this.ruleForm.password),
              checkCode: this.ruleForm.checkCode
            }
            doLogin(params).then(res => {
              this.loginLoading = false;
              if (res.data.success) {
                //需要二次验证
                if (res.data.data.needCheck) {
                  this.ruleForm.phone = res.data.data.phone;
                  this.dialogFormVisible = true;
                } else {
                  localStorage.setItem('token', res.data.data.token);
                  localStorage.setItem('userInfo', JSON.stringify(res.data.data));
                  this.dialogFormVisible = false;
                  this.$router.push({path: "/home"});
                }
              } else {
                this.$alert(res.data.error.message, '提示', {
                  confirmButtonText: '确定',
                  callback: action => {

                  }
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    sendLoginMsg() {
      let me = this;
      this.disabledSend = true
      let interval = window.setInterval(function () {
        me.buttonName = '（' + me.time + '秒）后重新发送';
        --me.time;
        if (me.time < 0) {
          me.buttonName = "重新发送";
          me.time = 60;
          me.disabledSend = false;
          window.clearInterval(interval);
        }
      }, 1000);

      sendMsg(this.ruleForm.phone).then(res => {
        if (res.data.success) {
          this.$message({
            message: '验证码发送成功',
            type: 'success'
          });
        } else {
          this.$alert(res.data.error.message, '提示', {
            confirmButtonText: '确定',
            callback: action => {

            }
          });
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.main-container {
  background: url("../../assets/bk.png");
  width: 100%;
  height: 100vh;
  //position: fixed;
  background-size: 100% 100%;
  overflow: hidden;

  .main-border {
    position: fixed;
    right: 100px;
    text-align: center;
    color: white;
    background-color: rgba(255, 255, 255, .1);
    width: 300px;
    border: 1px solid #eceaea;
    margin: 8rem auto;
    padding: 20px 50px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .el-divider--horizontal {
      margin: 14px 0;
    }

    .line-panel {
      display: flex;
      align-items: center;
      text-align: center;
      line-height: 50px;
      width: fit-content;
      margin: 0 auto;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      span {
        margin-left: .5rem;
        font-size: 1.6rem;
        font-weight: 600;
      }
    }

    .form-border {
      text-align: left;

      /deep/ label {
        color: white;
      }

      .el-form-item {
        //margin-bottom: 0;
      }

      /deep/ .el-form-item__label {
        padding: 0 !important;
        font-weight: 600;
        font-size: 17px;
      }

      .button-div {
        text-align: center;
        width: 200px;
        margin: 30px auto 0;
        border: 1px solid white;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        font-weight: 600;
        font-size: 17px;
      }

      .login-button {
        display: block;
        width: 200px;
        margin: 30px auto 0;
        font-weight: 600;
        font-size: 17px;
        color: white;
        background-color: rgba(0, 0, 0, 0);
        border: 2px solid white;
      }
    }
  }
}
</style>
