<template>
    <div class="cps-main-page">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
            <el-breadcrumb-item>交易管理</el-breadcrumb-item>
            <el-breadcrumb-item>交易查询</el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>

        <div class="page-content">
            <el-row @keyup.enter.native="loadData(1)">
                <el-input v-model="queryField.orderId" size="small" placeholder="订单id"></el-input>
                <el-input v-model="queryField.platformName" size="small" placeholder="所属平台"></el-input>
                <el-input v-model="queryField.gameName" size="small" placeholder="游戏名"></el-input>
                <el-input v-model="queryField.serverName" size="small" placeholder="游戏区服"></el-input>
                <el-select v-model="queryField.orderStatus" size="small" placeholder="订单状态">
                    <el-option label="订单状态" value=""/>
                    <el-option label="待支付" value="1"/>
                    <el-option label="已支付" value="2"/>
                    <el-option label="已完成" value="3"/>
                    <el-option label="已撤销" value="4"/>
                </el-select>
                <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
            </el-row>
            <el-table class="cps-table-data"
                      :data="tableData" size="small"
                      border
                      :height="height"
                      style="width: 100%"
                      id="rebateSetTable">
                <el-table-column
                        prop="order_id"
                        label="订单id"
                        width="60">
                </el-table-column>
                <el-table-column
                        prop="goods_price"
                        label="订单金额"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="goods_title"
                        label="商品名字"
                        width="110">
                </el-table-column>
                <el-table-column
                        prop="goods_type"
                        label="交易类型"
                        :formatter="goodTypeFormat"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="platform_name"
                        label="所属平台"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="game_name"
                        label="游戏名称"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="sale_server_name"
                        label="区服"
                        width="90">
                </el-table-column>
                <el-table-column
                        prop="sale_user_name"
                        label="出售账号"
                        width="100">
                </el-table-column>
                <el-table-column
                        prop="wx"
                        label="卖家wx"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="qq"
                        label="卖家QQ"
                        width="90">
                </el-table-column>
                <el-table-column
                        prop="sale_token"
                        label="预留口令"
                        width="105">
                </el-table-column>
                <el-table-column
                        label="查看图片"
                        width="80" >
                    <template slot-scope="scope">
                        <el-image
                                style="width: 50px; height: 50px"
                                fit="cover"
                                :src="tableData[scope.$index].srcList[0]"
                                :preview-src-list="tableData[scope.$index].srcList">
                        </el-image>
                    </template>
                </el-table-column>

                <el-table-column
                        prop="buy_user_id"
                        label="购买人id"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="buy_user_name"
                        label="购买人帐号"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="role_name"
                        label="收货角色"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="server_name"
                        label="收货区服"
                        width="90">
                </el-table-column>
                <el-table-column
                        prop="pay_status"
                        label="订单状态"
                        :formatter="orderStatusFormat"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="add_time"
                        label="下单时间"
                        width="140">
                </el-table-column>
                <el-table-column
                        width="280"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button @click="payed(scope.row.order_id)" type="text" size="small" v-if="scope.row.pay_status === 1">已支付</el-button>
                        <el-button @click="complete(scope.row.order_id)" type="text" size="small" v-if="scope.row.pay_status === 2">已完成</el-button>
                        <el-button @click="orderCancel(scope.row.order_id)" type="text" size="small" v-if="scope.row.pay_status === 1 || scope.row.pay_status === 2">撤销</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="cps-table-data"
                           @current-change="loadData"
                           background
                           :page-size="15"
                           :current-page="currentIndex"
                           layout="total, prev, pager, next"
                           :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import {queryTransactionOrderList, payedOrder, completeOrder,orderCancel,queryHistoryPrice} from '@/api/request';
    import crypto from '@/utils/crypto.js';

    export default {
        name: 'transactionList',
        data() {
            return {
                tableData: [],
                queryField: {
                    orderId: '',
                    gameName: '',
                    platformName: '',
                    serverName: '',
                    orderStatus: '',
                    price: '',
                },
                // srcList:[
                //     'https://qili-data.oss-cn-qingdao.aliyuncs.com/transaction/1038/1.jpeg',
                //     'https://qili-data.oss-cn-qingdao.aliyuncs.com/transaction/1038/2.jpeg',
                //
                // ],
                total: 0,
                height: window.innerHeight - 220,
                currentIndex: 1,
                loading: false,
                historyPriceList:[],
                salePassword: '',
            };
        },
        mounted() {
            this.loadData(1);
            console.log(this.queryField.price);
        },
        methods: {
            loadData(index) {
                let params = {
                    pageNo: index,
                    pageSize: 15,
                    params: this.queryField,
                }
                queryTransactionOrderList(params).then(res => {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                    this.currentIndex = index;
                });
            },
            viewPassword(origin) {
                this.salePassword = crypto.decrypt(origin);
            },
            payed(orderId) {
                this.$confirm('确认买家已付款?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    payedOrder(orderId).then(res => {
                        if (res.data.success) {
                            this.$message.success("操作成功");
                            this.loadData(1);
                        } else {
                            this.$message.error(res.data.error.message);
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    })
                })
            },
            complete(orderId) {
                this.$confirm('订单已经完成, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    completeOrder(orderId).then(res => {
                        if (res.data.success) {
                            this.$message.success("操作成功");
                            this.loadData(1);
                        } else {
                            this.$message.error(res.data.error.message);
                        }
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    })
                })
            },
            orderCancel(orderId) {
                this.$confirm('撤销订单?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    orderCancel(orderId).then(res => {
                        if (res.data.success) {
                            this.$message.success("操作成功");
                            this.loadData(1);
                        } else {
                            this.$message.error(res.data.error.message);
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    })
                })
            },
            goodsDetail(goodsId) {
                let detailUrl = "https://g.7li.cn/#/trade/detail?id=" + goodsId;

            },
            queryPrice(goodsId){
                queryHistoryPrice(goodsId).then(res => {
                    this.historyPriceList = res.data.data
                })
            },
            orderStatusFormat(row, column, cellValue) {//订单状态（1：待支付 2：已支付 3：已完成 4:撤销）
                let ret = ''
                if (cellValue === 1) {
                    ret = "待支付"
                } else if (cellValue === 2) {
                    ret = "已支付"
                } else if (cellValue === 3) {
                    ret = "已完成"
                } else if (cellValue === 4) {
                    ret = "已撤销"
                }
                return ret;
            },
            goodTypeFormat(row, column, cellValue) {//1：账号 2：材料 ）
                let ret = ''
                if (cellValue === 1) {
                    ret = "账号"
                } else if (cellValue === 2) {
                    ret = "材料"
                }
                return ret;
            }
        }
    }
</script>
<style scoped>

</style>
