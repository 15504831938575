console.log('process.env',process.env)
const serverList = [
  {
    BASEURL: 'http://cpsapi.huiyouyx.com/',
  },
  {
    BASEURL: 'http://localhost:8991/',
  }];
let serverArr = serverList[0]
export default serverArr
