<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>充值</el-breadcrumb-item>
      <el-breadcrumb-item>给会长充值</el-breadcrumb-item>
    </el-breadcrumb>

    <el-divider></el-divider>
    <div class="main-border">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px" @keyup.enter.native="onSubmit()">
        <el-form-item label="会长id" prop="admin_id">
          <el-input v-model="form.admin_id" size="small" placeholder="会长id"></el-input>
        </el-form-item>
        <el-form-item label="会长账号" prop="admin_name">
          <el-input v-model="form.admin_name" size="small" autocomplete="off" placeholder="会长账号"></el-input>
        </el-form-item>
        <el-form-item label="充值金额" prop="recharge_amount">
          <el-input v-model="form.recharge_amount" size="small" autocomplete="off" placeholder="充值金额"></el-input>
        </el-form-item>
        <el-form-item label="支付密码" prop="pay_pwd">
          <el-input v-model="form.pay_pwd" size="small" placeholder="支付密码" autocomplete="new-password"
                    show-password></el-input>
        </el-form-item>
        <el-form-item label="剩余金额">
          <el-input v-model="form.balance" size="small" placeholder="剩余金额" disabled></el-input>
        </el-form-item>

        <el-form-item v-if="form.recharge_amount >= 10000" label="验证码" prop="code">
          <el-input v-model="form.code" size="small" placeholder="验证码" autocomplete="off"></el-input>
          <el-button @click="sendCode" style="margin-left: 15px" size="small" :disabled="disabledSend">
            {{ buttonName }}
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button class="main-button" type="primary" @click="onSubmit" :loading="submitting">确认充值</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import {findChildById, findChildByName, adminRecharge, sendRechargeCode, queryRechargeBalance} from '@/api/request'
import crypto from '@/utils/crypto.js';

export default {
  name: 'adminCoin',
  data() {
    let validateId = (rule, value, callback) => {
      findChildById(this.form.admin_id).then(res => {
        if (!res.data.success) {
          this.form.admin_name = '';
          callback(new Error(res.data.error.message));
        } else {
          let adminName = res.data.data;
          if (adminName !== this.form.admin_name) {

            let params = {
              admin_id: this.form.admin_id,
            }
            queryRechargeBalance(params).then(res => {
              if (!res.data.success) {
                this.form.balance = '';
                callback(new Error(res.data.error.message));
              } else {
                let balance = res.data.data;
                if (balance !== this.form.balance) {
                  this.form.balance = balance;
                  console.log('set value')
                }
                callback();
              }

            })
            this.form.admin_name = adminName;
            console.log('set value')
          }
          callback();
        }
      });
      //}
    };
    let validateName = (rule, value, callback) => {
      findChildByName(this.form.admin_name).then(res => {
        if (!res.data.success) {
          this.form.admin_id = '';
          callback(new Error(res.data.error.message));
        } else {
          let adminId = res.data.data;
          if (adminId !== this.form.admin_id) {
            this.form.admin_id = adminId;
            console.log('set value')
          }

          let params = {
            admin_id: this.form.admin_id,
          }
          queryRechargeBalance(params).then(res => {
            if (!res.data.success) {
              this.form.balance = '';
              callback(new Error(res.data.error.message));
            } else {
              let balance = res.data.data;
              if (balance !== this.form.balance) {
                this.form.balance = balance;
                console.log('set value')
              }
              callback();
            }
          }),

              callback();
        }
      });
      //}
    };
    return {
      form: {
        admin_id: '',
        admin_name: '',
        recharge_amount: '',
        pay_pwd: '',
        code: '',
        balance: '',
      },
      submitting: false,
      disabledSend: false,
      buttonName: '获取验证码',
      time: 60,
      rules: {
        recharge_amount: [
          {required: true, message: '请输入充值金额'},
          {pattern: /^-?\d+([.]\d{1,2})?$/, trigger: 'blur', message: '充值金额格式不正确'},
        ],
        pay_pwd: [
          {required: true, message: '请输入支付密码'},
        ],
        admin_id: [
          {required: true, message: '请输入会长id'},
          {pattern: /^\d+$/, trigger: 'blur', message: '会长id格式不正确'},
          {validator: validateId, trigger: 'blur'}
        ],
        admin_name: [
          {required: true, message: '请输入会长账号'},
          {validator: validateName, trigger: 'blur'}
        ],
        code: [
          {required: true, message: '请输入验证码'},
          {pattern: /^\d{6}$/, trigger: 'blur', message: '验证码格式不正确'},
        ],
      }
    }
  },
  methods: {
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.submitting = true;
          let params = {
            admin_id: this.form.admin_id,
            recharge_amount: this.form.recharge_amount,
            pay_pwd: crypto.encrypt(this.form.pay_pwd),
            code: this.form.code,
          }
          adminRecharge(params).then(res => {
            if (res.data.success) {
              this.$message.success('充值成功！');
              // this.$refs['form'].resetFields();
              // this.form.code = '';
              location.reload();
            } else {
              this.$message.error(res.data.error.message);
            }
            this.submitting = false;
          });
        } else {
          return false;
        }
      });
    },
    sendCode() {
      let me = this;
      this.disabledSend = true
      sendRechargeCode().then(res => {
        if (res.data.success) {
          this.$message.success('验证码发送成功');
          me.buttonName = '（' + me.time + '秒）后重新发送';
          let interval = window.setInterval(function () {
            --me.time;
            if (me.time < 0) {
              me.buttonName = "重新发送";
              me.time = 59;
              me.disabledSend = false;
              window.clearInterval(interval);
            }
          }, 1000);
        } else {
          this.$message.error(res.data.error.message);
          this.disabledSend = false
          me.buttonName = '重新发送';
        }
      })
    },
    openCodeBox() {
      this.$prompt('充值超过一万元，请提供手机验证码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\d{6}/,
        inputErrorMessage: '手机验证码格式不正确'
      }).then(({value}) => {
        this.$message({
          type: 'success',
          message: '你的邮箱是: ' + value
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    }
  }
}
</script>

<style scoped lang="scss">
.el-input {
  width: 250px !important;
  min-width: 200px;
}

/deep/ .el-input__inner {
  border-radius: 2px !important;
  border: 1px solid #aaaaac !important;
}

/deep/ .el-form-item__label {
  font-weight: 600;
  font-size: 13px;
}

.main-button {
  font-weight: 700;
  width: 120px;
}
</style>
