<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>礼包管理</el-breadcrumb-item>
      <el-breadcrumb-item>红包发放</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-border">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px" @keyup.enter.native="onSubmit()">
        <el-form-item label="用户ID" prop="addUserId">
          <el-input size="small" v-model="form.addUserId" disabled></el-input>
        </el-form-item>
        <el-form-item label="红包名称" prop="num">
          <el-input size="small" v-model="form.envelopeName" placeholder="请输入红包名称"></el-input>
        </el-form-item>
        <el-form-item label="红包个数" prop="num">
          <el-input size="small" v-model="form.num" placeholder="请输入红包个数"></el-input>
        </el-form-item>
        <el-form-item label="红包总金额" prop="money">
          <el-input size="small" v-model="form.money" placeholder="请输入红包总金额"></el-input>
        </el-form-item>
        <el-form-item label="红包1" prop="money">
          <el-input size="small" v-model="envelopesList[0]" placeholder="请输入红包总金额"></el-input>
        </el-form-item>
        <el-form-item label="红包2" prop="money">
          <el-input size="small" v-model="envelopesList[1]" placeholder="请输入红包总金额"></el-input>
        </el-form-item>
        <el-form-item label="红包3" prop="money">
          <el-input size="small" v-model="envelopesList[2]" placeholder="请输入红包总金额"></el-input>
        </el-form-item>
        <el-form-item label="红包4" prop="money">
          <el-input size="small" v-model="envelopesList[3]" placeholder="请输入红包总金额"></el-input>
        </el-form-item>
        <el-form-item label="红包5" prop="money">
          <el-input size="small" v-model="envelopesList[4]" placeholder="请输入红包总金额"></el-input>
        </el-form-item>
        <el-form-item label="红包6" prop="money">
          <el-input size="small" v-model="envelopesList[5]" placeholder="请输入红包总金额"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" :loading="submitting">发送红包</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {sendRedForTS} from "@/api/request";

export default {
  name: "RedAdd",
  inject: ['reload'],
  data() {
    return {
      submitting: false,
      envelopesList: [],
      form: {
        envelopeName:'',
        addUserId: '',
        money: '',
        num: '',
        envelopes:[],
      },
      rules: {}
    }
  },
  mounted() {
    this.form.addUserId = JSON.parse(localStorage.getItem("userInfo")).adminId;
  },
  methods: {
    onSubmit() {
      this.filterNull();
      this.$confirm('此操作将发放红包, 是否继续?', {

        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = this.form
        sendRedForTS(params).then(res => {
              if (res.data.success) {
                this.$message.success('红包发放成功！');
                this.submitting = false;
                this.reload();
              } else {
                this.$message.error(res.data.error.message);
                this.submitting = false;
                this.reload();
              }
              this.submitting = false;
            }
        );
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
        this.submitting = false;
      })
    },
    filterNull(){
      this.form.envelopes = this.envelopesList.filter(function(n){return n});
    },
  }
}
</script>

<style scoped>
/deep/ .el-input {
  width: 250px !important;
  min-width: 200px;
}

/deep/ .el-input__inner {
  border-radius: 2px !important;
  border: 1px solid #aaaaac !important;
}

/deep/ .el-form-item__label {
  font-weight: 600;
  font-size: 13px;
}

.main-button {
  font-weight: 700;
  width: 120px;
}

.el-button {
  font-weight: 700;
  width: 180px;
}
</style>