<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单查询</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>

    <div class="page-content">
      <el-row @keyup.enter.native="loadData(1)">
        <el-input v-model="queryField.order_id" size="small" placeholder="订单id"></el-input>
        <el-input v-model="queryField.cp_order_id" size="small" placeholder="cp订单id"></el-input>
        <el-input v-model="queryField.user_id" size="small" placeholder="用户id"></el-input>
        <el-input v-model="queryField.user_name" size="small" placeholder="用户名"></el-input>
        <!--      <el-input v-model="queryField.nick_name" size="small" placeholder="用户昵称"></el-input>-->
        <el-select
            size="small"
            v-model="game"
            filterable
            remote
            reserve-keyword
            placeholder="游戏名称"
            @change="handleSelect3"
            :remote-method="loadGame">
          <el-option
              v-for="item in restaurants3"
              :key="item.key"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="queryField.server_code" size="small" placeholder="游戏区服"></el-input>
        <el-input v-model="queryField.role_code" size="small" placeholder="游戏角色"></el-input>

        <el-autocomplete
            v-if="isSupper"
            class="inline-input"
            v-model="first"
            :fetch-suggestions="querySearch"
            value-key="nickName"
            placeholder="一级渠道"
            @select="handleSelect1"
        ></el-autocomplete>
        <el-autocomplete
            v-if="isSupper"
            class="inline-input"
            value-key="nickName"
            v-model="second"
            :fetch-suggestions="querySearch2"
            placeholder="二级渠道"
            @select="handleSelect2"
        ></el-autocomplete>

        <br/><br/>

        <el-select v-model="queryField.order_status" size="small" placeholder="订单状态">
          <el-option label="订单状态" value=""/>
          <el-option label="待支付" value="1"/>
          <el-option label="支付成功" value="2"/>
          <el-option label="通知成功" value="3"/>
        </el-select>
        <el-select v-model="queryField.order_type" size="small" placeholder="订单类型">
          <el-option label="全部类型" value=""/>
          <el-option label="游戏订单" value="1"/>
          <el-option label="会游币订单" value="2"/>
        </el-select>
        <el-select v-model="queryField.pay_type" size="small" placeholder="支付方式">
          <el-option label="支付方式" value=""/>
          <el-option label="会游币" value="1"/>
          <el-option label="支付宝" value="2"/>
          <el-option label="微信" value="3"/>
          <el-option label="支付宝扫码" value="4"/>
          <el-option label="微信扫码" value="5"/>
          <el-option label="支付宝SDK" value="6"/>
          <el-option label="微信SDK" value="7"/>
          <el-option label="绑币" value="8"/>
          <el-option label="联运" value="99"/>
        </el-select>

        <el-date-picker
            size="small"
            v-model="queryField.start_time"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="开始日期">
        </el-date-picker>
        <el-date-picker
            size="small"
            v-model="queryField.end_time"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="结束日期">
        </el-date-picker>
        <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
        <el-button
            type="primary"
            @click="moveSission"
            size="small"
        >重置</el-button
        >
        <el-button size="small" type="success" @click="exportExcel" :loading="loading" loading-text="下载中">导出</el-button>
      </el-row>

      <el-row style="background-color: #f7f5f5; margin-top: 15px;padding-left: 10px;">
        <el-col :lg="3"  key="总金额">
          <div class="dashboard-statistic">
            <div class="grid-right">
              <p class="grid-name">总金额(￥):{{allAmount}}</p>
            </div>
          </div>
        </el-col>
        <el-col :lg="3"  key="成功金额">
          <div class="dashboard-statistic">
            <div class="grid-right">
              <p class="grid-name">成功金额(￥):{{allSuccessAmount}}</p>
            </div>
          </div>
        </el-col>
        <el-col :lg="3"  key="会游币">
          <div class="dashboard-statistic">
            <div class="grid-right">
              <p class="grid-name">会游币(￥):{{qlSuccessAmount}}</p>
            </div>
          </div>
        </el-col>
        <el-col :lg="3"  key="绑币">
          <div class="dashboard-statistic">
            <div class="grid-right">
              <p class="grid-name">绑币(￥):{{bindSuccessAmount}}</p>
            </div>
          </div>
        </el-col>
        <el-col :lg="3"  key="微信">
          <div class="dashboard-statistic">
            <div class="grid-right">
              <p class="grid-name">微信(￥):{{wxSuccessAmount}}</p>
            </div>
          </div>
        </el-col>
        <el-col :lg="3"  key="支付宝">
          <div class="dashboard-statistic">
            <div class="grid-right">
              <p class="grid-name">支付宝(￥):{{aliSuccessAmount}}</p>
            </div>
          </div>
        </el-col>
        <el-col :lg="3"  key="通知失败">
          <div class="dashboard-statistic">
            <div class="grid-right">
              <p class="grid-name">通知失败(￥):{{noNotifyAmount}}</p>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-table class="cps-table-data"
                :data="tableData" size="small"
                border
                :height="height"
                style="width: 100%"
                id="rebateSetTable">
        <el-table-column
            prop="order_id"
            label="订单id"
            width="80">
        </el-table-column>
        <el-table-column
            prop="cp_order_id"
            label="cp订单id"
            width="240">
        </el-table-column>
        <el-table-column
            prop="user_id"
            label="玩家id"
            width="60">
        </el-table-column>
        <el-table-column
            prop="user_name"
            label="玩家账号"
            width="120">
        </el-table-column>
        <el-table-column
            prop="game_name"
            label="游戏名称"
            width="120">
        </el-table-column>
        <el-table-column
            prop="order_service_id"
            label="下单区服"
            width="90">
        </el-table-column>
        <el-table-column
            prop="order_role_code"
            label="下单角色"
            width="120">
        </el-table-column>
        <el-table-column
            prop="order_desc"
            label="物品名称"
            width="120">
        </el-table-column>
        <el-table-column
            prop="order_amount"
            label="订单金额"
            width="70">
        </el-table-column>
        <el-table-column
            prop="discount_amount"
            label="优惠金额"
            width="70">
        </el-table-column>
        <el-table-column
            prop="order_status"
            label="订单状态"
            :formatter="orderStatusFormat"
            width="80">
        </el-table-column>
        <el-table-column
            prop="pay_type"
            label="支付方式"
            :formatter="payTypeFormat"
            width="70">
        </el-table-column>
        <el-table-column
            prop="order_time"
            label="下单时间"
            width="140">
        </el-table-column>
        <el-table-column
            prop="admin_name"
            label="归属渠道"
            width="110">
        </el-table-column>
        <el-table-column
            width="160"
            fixed="right"
            label="操作">
          <template slot-scope="scope">
            <el-button v-if="showDetail" @click="detail(scope.row)" type="text" size="small">详情</el-button>
            <el-button v-if="showNotify" @click="reNotify(scope.row.order_id)" type="text" size="small">重新通知</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="cps-table-data"
                     @current-change="loadData"
                     background
                     :page-size="15"
                     :current-page="currentIndex"
                     layout="total, prev, pager, next"
                     :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  queryOrderList,
  getPageButtons,
  channelDropDown,
  subChannelDropDown,
  getOrderCount,
  orderReNotify,
  exportOrder,
  loadGame
} from '@/api/request';
import fileDownload from 'js-file-download'
import moment from "moment";

export default {
  name: 'orderList',
  data() {
    return {
      allAmount:'',
      allSuccessAmount:'',
      noNotifyAmount:'',
      qlSuccessAmount:'',
      bindSuccessAmount: '',
      wxSuccessAmount:'',
      aliSuccessAmount:'',
      isSupper:false,
      restaurants1: [],
      restaurants2: [],
      restaurants3: [],
      tableData: [],
      first:'',
      second:'',
      queryField: {
        admin_id: '',
        order_id: '',
        cp_order_id: '',
        user_id: '',
        user_name: '',
        nick_name: '',
        game_name: '',
        order_status: '',
        pay_type: '',
        start_time:'',
        end_time: '',
        channel_first: '',
        channel_second: '',
        order_type: '',
        server_code: '',
        role_code: '',
      },
      gameType: '',
      gameStatus: '',
      total: 0,
      height: window.innerHeight - 320,
      showDetail: false,
      showNotify: false,
      currentIndex:1,
      loading:false,
      game: "",
      
    };
  },
  mounted() {
    this.queryField.order_status = "3";
    this.queryField.order_type = "1";
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.isSupper = user.groupId==="1";
    this.startTimeSet();
    this.uporderListStor();
    this.loadData(1);
    this.loadAll1();
    this.loadAll2('0');
    this.getPageButtons();
    this.loadCountOrder();
  },
  methods: {
    startTimeSet(){
      if (sessionStorage.getItem("orderListStor") == null) {
        let newdata=new Date();
        if ((newdata.getMonth() + 1)< 1){
          var y = newdata.getFullYear()-1;
          var m = newdata.getMonth() + 12;
        }else {
          var y = newdata.getFullYear();
          var m = newdata.getMonth() + 1;
        }
        m = m < 10 ? '0' + m : m;
        var d = newdata.getDate();
        d = d < 10 ? ('0' + d) : d;
        d = d === 31 ? 30 : d;
        this.queryField.start_time = y + '-' + m + '-' + d;
      }
    },
    //写入数据
    uporderListStor() {
      if (sessionStorage.getItem("orderListStor") != null) {
        let params = JSON.parse(sessionStorage.getItem('orderListStor'));
        let params2 = JSON.parse(sessionStorage.getItem('orderListStor2'));
        this.queryField = params;
        this.second = params2.second,
        this.first = params2.first
      }
    },
    moveSission(){
      sessionStorage.removeItem("orderListStor");
      sessionStorage.removeItem("orderListStor2");
      this.$router.go(0)
    },
    loadCountOrder(){
      getOrderCount(this.queryField).then(res => {
        if (res.data.success) {
          let result = res.data.data;
          this.allAmount=result.allAmount;
          this.allSuccessAmount=result.allSuccessAmount;
          this.qlSuccessAmount=result.qlSuccessAmount;
          this.wxSuccessAmount=result.wxSuccessAmount;
          this.aliSuccessAmount=result.aliSuccessAmount;
          this.noNotifyAmount=result.noNotifyAmount;
          this.bindSuccessAmount=result.bindSuccessAmount;
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    getPageButtons() {
      let path = this.$route.path.replace("/", "");
      getPageButtons(path).then(res => {
        if (res.data.success) {
          let menuCodes = res.data.data;
          this.showDetail = menuCodes.includes("order_query_detail");
          this.showNotify = menuCodes.includes("order_query_notify");
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    loadData(index) {
      if (this.queryField.start_time) {
        this.queryField.start_time += " 00:00:00";
      } else {
        this.queryField.start_time = "";
      }
      if (this.queryField.end_time) {
        this.queryField.end_time += " 23:59:59";
      } else {
        this.queryField.end_time = "";
      }
      let params = {
        pageNo: index,
        pageSize: 15,
        params:this.queryField,
      }
      queryOrderList(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentIndex=index;
      });
      this.loadCountOrder();
      //渠道写入sission
      let sission = {
        first:this.first,
        second:this.second,
      }
      sessionStorage.setItem('orderListStor', JSON.stringify(params.params))
      sessionStorage.setItem('orderListStor2', JSON.stringify(sission))
    },
    detail(row) {
      this.$router.push({path: `/orderList/orderDetail/${row.order_id}`});
    },
    reNotify(orderId) {
      orderReNotify(orderId).then(res => {
        if (res.data.success) {
          this.$message.success("通知成功");
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    orderStatusFormat(row, column, cellValue) {//订单状态（1：待支付 2：支付成功 3：通知成功）
      let ret = ''
      if (cellValue === 1) {
        ret = "待支付"
      } else if (cellValue === 2) {
        ret = "支付成功"
      } else if (cellValue === 3) {
        ret = "通知成功"
      }
      return ret;
    },
    payTypeFormat(row, column, cellValue) {
      let ret = ''
      if (cellValue === 1) {
        ret = "会游币"
      } else if (cellValue === 2) {
        ret = "支付宝"
      } else if (cellValue === 3) {
        ret = "微信"
      } else if (cellValue === 4) {
        ret = "支付宝扫码"
      } else if (cellValue === 5) {
        ret = "微信扫码"
      }else if (cellValue === 99) {
        ret = "联运"
      } else if (cellValue === 7) {
        ret = "微信app"
      } else if (cellValue === 8){
        ret = "绑币"
      }
      return ret;
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.nickName.indexOf(queryString.toLowerCase()) === 0)
      }
    },
    querySearch(queryString, cb) {
      let restaurants = this.restaurants1
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    querySearch2(queryString, cb) {
      let restaurants = this.restaurants2
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    handleSelect1(item) {
      this.queryField.channel_first = item.value
      if (item.value === '') {
        this.loadAll2('0')
      } else {
        this.loadAll2(item.value)
      }

    },
    handleSelect2(item) {
      this.queryField.channel_second = item.value
    },
    handleSelect3(item){
      this.queryField.game_id = item
    },
    loadAll1() {
      let me = this
      channelDropDown().then(res => {
        me.restaurants1 = []
        let item1 = {}
        item1.value = ''
        item1.nickName = '全部'
        me.restaurants1.push(item1)
        res.data.data.forEach(function (e) {
          if (!e.nick_name) {
            e.nick_name = e.admin_id
          }
          let item = {}
          item.value = e.admin_id
          item.nickName = e.nick_name
          me.restaurants1.push(item)
        })
      })
    },
    exportExcel(){
      if (this.queryField.start_time) {
        this.queryField.start_time += " 00:00:00";
      } else {
        this.queryField.start_time = "";
      }

      if (this.queryField.end_time) {
        this.queryField.end_time += " 23:59:59";
      } else {
        this.queryField.end_time = "";
      }
      this.loading=true;
      exportOrder(this.queryField).then(res => {
        this.downloadGO(res.data)
      });
    },

    downloadGO (data) {
      if (!data) {
        return
      }
      let url = window.URL.createObjectURL(new Blob([data], {type: "application/vnd.ms-excel"}))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download',"订单导出.xls")
      document.body.appendChild(link)
      link.click()
      this.loading=false;
    },

    loadAll2(parentId) {
      let me = this
      subChannelDropDown(parentId).then(res => {
        me.restaurants2 = []
        let item1 = {}
        item1.value = ''
        item1.nickName = '全部'
        me.restaurants2.push(item1)
        let item2 = {}
        item2.value = '-1'
        item2.nickName = '一级自有'
        me.restaurants2.push(item2)
        res.data.data.forEach(function (e) {
          if (!e.nick_name) {
            e.nick_name = e.admin_id
          }
          let item = {}
          item.value = e.admin_id
          item.nickName = e.nick_name
          me.restaurants2.push(item)
        })
      })
    },
    loadGame(query){
      if(query !== ''){
        let me = this;
        loadGame(query).then(res => {
          console.log(res)
          me.restaurants3 = [];
          res.data.data.forEach((e) => {
            let item = {};
            item.key = e.game_id;
            item.value = e.game_id;
            item.label = e.game_name;
            me.restaurants3.push(item);
          })
        })
      }
    }
  },

}
</script>
