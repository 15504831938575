<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
      <el-breadcrumb-item>开服新增</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-border">
      <div class="form-grid form-title">
        <div>游戏id</div>
        <div>游戏区服</div>
        <div>开服时间</div>
        <div></div>
      </div>
      <el-form ref="rulesForm" :model="form" label-width="120px" class="domains_form">
        <div class="form-grid form-view" v-for="(item, index) in form.serverInfo" :key="index">
          <el-form-item
              :prop="'serverInfo.' + index + '.gameId'"
              :rules="rules.gameId">
            <el-input v-model="item.gameId"></el-input>
          </el-form-item>
          <el-form-item 
              :prop="'serverInfo.' + index + '.gameServer'"
              :rules="rules.gameServer">
            <el-input v-model="item.gameServer"></el-input>
          </el-form-item>
          <el-form-item 
              :prop="'serverInfo.' + index + '.openTime'"
              :rules="rules.openTime">
            <el-date-picker
                v-model="item.openTime"
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="text" @click.prevent="removeServer(item)">删除</el-button>
          </el-form-item>
        </div>
      </el-form>
      <div class="bottom_btn">
        <el-button type="primary" @click="submit('rulesForm')">提交</el-button>
        <el-button type="success" @click="addServer">新增</el-button>
        <el-button type="warning" @click="resetForm('rulesForm')">重置</el-button>
        <el-button @click="$router.push('/game/server/list')">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {batchAddServer} from "@/api/request";

export default {
  name: "gameserverbatchadd",
  data(){
    return {
      form:{
        serverInfo: [
          {
            gameId: '',
            gameServer: '',
            openTime: ''
          },
        ]
      },
      rules: {
        gameId: [
          { required: true, message: '请输入游戏id', trigger: 'blur' },
        ],
        gameServer: [
          { required: true, message: '请输入游戏区服', trigger: 'blur' },
        ],
        openTime: [
          { required: true, message: '请选择开服时间', trigger: 'blur' },
        ]
      }
    }
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          batchAddServer(this.form.serverInfo).then(res => {
            if(res.data.success){
              this.$message.success("添加成功")
              this.resetForm();
            }else{
              this.$message.error(res.data.error.message)
            }
          })
        } else {
          return false;
        }
      });
    },
    addServer() {
      if(this.form.serverInfo.length >= 20){
        this.$message.warning("一次最多提交20条")
        return;
      }
      this.form.serverInfo.push({
        gameId: '',
        gameServer: '',
        openTime: '',
        key: Date.now()
      });
    },
    removeServer(item) {
      let index = this.form.serverInfo.indexOf(item)
      if (index !== -1) {
        this.form.serverInfo.splice(index, 1)
      }
    },
    resetForm(formName) {
      this.form = {
        serverInfo: [
          {
            gameId: '',
            gameServer: '',
            openTime: ''
          },
        ]
      }
    },
  }
}
</script>

<style scoped>
  /deep/ .el-form-item {
    margin: 0;
  }
  /deep/ .el-form-item__content{
    margin: 0 !important;
  }
  

  .main-border {
    width: 900px;
    margin: 0 auto;
  }
  .domains_form{
    height: calc(100vh - 220px);
    overflow: hidden auto;
  }
  .bottom_btn{
    width: 310px;
    height: 70px;
    line-height: 70px;
    margin: 0 auto;
  }
  .form-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4,24%);
    justify-content: space-between;
    text-align: center;
    border-bottom: 1px solid #f2f2f2;
  }
  .form-title {
    font-weight: 700;
    padding-bottom: 10px;
  }
  .form-view{
    padding: 20px 0;
  }
</style>