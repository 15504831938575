<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>提成管理</el-breadcrumb-item>
      <el-breadcrumb-item>游戏提成配置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>

    <div class="page-content">
    <el-row @keyup.enter.native="loadData(currentIndex)">
      <el-input v-model="queryField.game_name" size="small" placeholder="游戏名称"></el-input>

      <el-select popper-class="cps-select" v-model="queryField.game_type" size="small" placeholder="游戏类型">
        <el-option key="" label="游戏类型" value=""/>
        <el-option key="1" label="H5游戏" value="1"/>
        <el-option key="2" label="小游戏" value="2"/>
        <el-option key="3" label="手游" value="3"/>
      </el-select>

      <el-select v-model="queryField.game_topic" size="small" placeholder="游戏类别">
        <el-option key="" label="游戏类别" value=""/>
        <el-option key="1" label="传奇" value="1"/>
        <el-option key="2" label="玄幻" value="2"/>
        <el-option key="4" label="三国" value="4"/>
        <el-option key="3" label="经营模拟" value="3"/>
        <el-option key="5" label="卡牌" value="5"/>
        <el-option key="6" label="奇迹" value="6"/>
        <el-option key="8" label="武侠" value="8"/>
        <el-option key="7" label="角色" value="7"/>
      </el-select>

      <el-button type="primary" @click="loadData(currentIndex)" icon="el-icon-search" size="small">查询</el-button>
    </el-row>
    <el-table class="cps-table-data"
            :data="tableData" size="small"
            border :height="height"
            style="width: 100%;">
      <el-table-column
              prop="config_id"
              label="ID"
              width="80">
      </el-table-column>
      <el-table-column
              prop="game_name"
              label="游戏名"
              width="200">
      </el-table-column>
      <el-table-column
              prop="game_topic"
              label="游戏类别"
              :formatter="formatGameTopic"
              width="100">
      </el-table-column>
      <el-table-column
              prop="game_type"
              label="游戏类型"
              :formatter="formatGameType"
              width="100">
      </el-table-column>
      <el-table-column
              prop="regist_status"
              label="注册状态"
              width="140">
        <template slot-scope="scope">
          <el-select popper-class="cps-select" v-model="tableData[scope.$index].regist_status" size="small" placeholder="游戏类型">
            <el-option key="1" label="开启" :value="1"/>
            <el-option key="2" label="禁止" :value="2"/>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
              prop="push_status"
              label="推广状态"
              width="140">
        <template slot-scope="scope">
          <el-select popper-class="cps-line-select" v-model="tableData[scope.$index].push_status" size="small" placeholder="游戏类型">
            <el-option key="1" label="常规" :value="1"/>
            <el-option key="2" label="严控" :value="2"/>
            <el-option key="3" label="禁止" :value="3"/>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
              prop="first_money"
              label="首冲金额"
              width="140">
        <template slot-scope="scope">
          <el-input class="cps-line-input" v-model="tableData[scope.$index].first_money" size="small" placeholder="首冲金额"></el-input>
        </template>
      </el-table-column>
      <el-table-column
              label="首充提成"
              width="140">
        <template slot-scope="scope">
          <el-input class="cps-line-input" v-model="tableData[scope.$index].first_share" size="small" placeholder="首充提成"></el-input>
        </template>

      </el-table-column>
      <el-table-column
              label="续充提成"
              width="140">
        <template slot-scope="scope">
          <el-input  class="cps-line-input" v-model="tableData[scope.$index].continue_share" size="small" placeholder="续充提成"></el-input>
        </template>
      </el-table-column>
      <el-table-column
              label="操作">
          <template slot-scope="scope">
            <el-button @click="save(scope.row)" type="text" size="small">保存</el-button>
            <!--<el-button type="primary" @click="save(scope.row)" size="small" icon="el-icon-check" circle></el-button>-->
          </template>
      </el-table-column>


    </el-table>

    <el-pagination class="cps-table-data"
            @current-change="loadData"
            background
            :page-size="15"
            :current-page="currentIndex"
            layout="total, prev, pager, next"
            :total="total">
    </el-pagination>
  </div>
  </div>
</template>
<script>
  import { queryGameConfigList,updateGameShare } from '@/api/request'
  export default {
    name: 'gamePercentage',
    data() {
      return {
        tableData: [],
        queryField: {
          game_name: '',
          game_type: '',
          game_topic: '',
        },
        total: 0,
        currentIndex: 1,
        height: window.innerHeight - 210,
      }
    },
    mounted() {
      this.loadData(1);
    },
    methods: {
      formatGameType(row, column, cellValue) {
        let ret = ''
        if (cellValue === 1) {
          ret = "H5"
        } else {
          ret = "手游"
        }
        return ret;
      },
      formatGameTopic(row, column, cellValue) {
        let ret = ''
        if (cellValue === 1) {
          ret = "卡牌"
        } else if(cellValue === 2) {
          ret = "武侠"
        } else if(cellValue === 3) {
          ret = "传奇"
        } else if(cellValue === 4) {
          ret = "角色"
        } else if(cellValue === 5) {
          ret = "养成"
        }else {
          ret = "其他"
        }
        return ret;
      },
      loadData(index) {
        let params = {
          pageNo: index,
          pageSize: 15,
          params : this.queryField,
        }
        queryGameConfigList(params).then(res => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.currentIndex = index;
        });
      },
      save(row) {
        const firstReg = /^\d+([.]\d{1,2})?$/
        if (!firstReg.test(row.first_money + "")) {
          this.$message.error('首冲金额格式不正确');
          return ;
        }

        const reg = /^[01]([.][0-9]{0,4})?$/;
        if (!reg.test(row.first_share + "")) {
          this.$message.error('首冲提成格式不正确');
          return ;
        }

        if (!reg.test(row.continue_share + "")) {
          this.$message.error('续冲提成格式不正确');
          return ;
        }

        if (row.first_share < 0 || row.first_share > 1
            || row.continue_share < 0 || row.continue_share > 1) {
          this.$message.error('提成比例应设置在0~1.0之间');
          return ;
        }

        let params = {
          config_id: row.config_id,
          first_share: row.first_share,
          continue_share: row.continue_share,
          regist_status: row.regist_status,
          push_status: row.push_status,
          first_money: row.first_money
        };
        updateGameShare(params).then(res => {
          if (res.data.success) {
            this.$message.success('游戏提成配置修改成功');
          } else {
            this.$message.error(res.data.error.message);
          }
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.cps-line-input{
    width: 100px !important;
    .el-input__inner {
      border-radius: 2px !important;
      border: 1px solid #bfc1c1;
    }
  }
  /deep/.cell{
    .el-select{
      width: 100px !important;
      .el-input{
        .el-input__inner {
          border-radius: 2px !important;
          border: 1px solid #bfc1c1;
        }
      }
    }
  }
  /deep/.el-select-dropdown__item{
    font-size: 12px;
  }
  .cps-select{
    font-size: 12px;
  }
</style>
