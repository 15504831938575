<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>提成管理</el-breadcrumb-item>
      <el-breadcrumb-item>我的提成配置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>

    <div class="page-content">
    <el-row @keyup.enter.native="loadData(1)">
      <el-input v-model="queryField.game_name" size="small" placeholder="游戏名称"></el-input>
      <el-select popper-class="cps-select" v-model="queryField.game_type" size="small" placeholder="游戏类型">
        <el-option key="" label="游戏类型" value=""/>
        <el-option key="1" label="H5游戏" value="1"/>
        <el-option key="3" label="手游" value="2"/>
      </el-select>

      <el-select v-model="queryField.game_topic" size="small" placeholder="游戏类别">
        <el-option key="" label="游戏类别" value=""/>
        <el-option key="1" label="传奇" value="1"/>
        <el-option key="2" label="玄幻" value="2"/>
        <el-option key="4" label="三国" value="4"/>
        <el-option key="3" label="经营模拟" value="3"/>
        <el-option key="5" label="卡牌" value="5"/>
        <el-option key="6" label="奇迹" value="6"/>
        <el-option key="8" label="武侠" value="8"/>
        <el-option key="7" label="角色" value="7"/>
      </el-select>

      <el-button type="primary" @click="loadData(1)" icon="el-icon-search" size="small">查询</el-button>
    </el-row>
    <el-table class="cps-table-data"
            :data="tableData" size="small"
            border :height="height"
            style="width: 100%">
      <el-table-column
              prop="game_name"
              label="游戏名"
              width="180">
      </el-table-column>
      <el-table-column
              prop="regist_status"
              label="注册开关"
              :formatter="formatType"
              width="110">
      </el-table-column>
      <el-table-column
              prop="recharge_status"
              label="充值开关"
              :formatter="formatType"
              width="110">
      </el-table-column>
      <el-table-column
              prop="push_status"
              label="推广状态"
              :formatter="pushStatusFormat"
              width="110">
      </el-table-column>
<!--      <el-table-column-->
<!--              prop="parent_name"-->
<!--              label="一级渠道"-->
<!--              width="140">-->
<!--      </el-table-column>-->
      <el-table-column
              prop="first_money"
              label="首冲金额"
              width="120">
      </el-table-column>
      <el-table-column
              label="首充提成"
              prop="first_share"
              width="120">
      </el-table-column>
      <el-table-column
              prop="continue_share"
              label="续充提成"
              width="120">
      </el-table-column>
    </el-table>
    <el-pagination class="cps-table-data"
            @current-change="loadData"
            background
            :page-size="15"
            :current-page="currentIndex"
            layout="total, prev, pager, next"
            :total="total">
    </el-pagination>
  </div>
  </div>
</template>
<script>
  import { queryMyGameShareList } from '@/api/request'
  export default {
    name: 'myPercentage',
    data() {
      return {
        tableData: [],
        queryField: {
          admin_name: '',
          game_name: '',
          game_type: '',
          game_topic: '',
        },
        total: 0,
        currentIndex: 1,
        height: window.innerHeight - 210,
      }
    },
    mounted() {
      this.loadData(1);
    },
    methods: {
      loadData(index) {
        let params = {
          pageNo: index,
          pageSize: 15,
          params : this.queryField,
        }
        queryMyGameShareList(params).then(res => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.currentIndex = index;
        });
      },
      formatType(row, column, cellValue) {
        let ret = ''
        if (cellValue===1) {
          ret = "开启"
        } else {
          ret = "禁止"
        }
        return ret;
      },
      pushStatusFormat(row, column, cellValue) {//推广状态（1：常规 2：严控 3：禁止）
        let ret = ''
        if (cellValue===1) {
          ret = "常规"
        } else if (cellValue===2) {
          ret = "严控"
        } else {
          ret = "禁止"
        }
        return ret;
      },
      levelFormat(row, column, cellValue) {//级别
        let ret = ''
        if (cellValue===1) {
          ret = "一级"
        } else if (cellValue===2){
          ret = "二级"
        } else if (cellValue===3){
          ret = "三级"
        } else if (cellValue===4){
          ret = "四级"
        }
        return ret;
      },
    },
  }
</script>

<style lang="scss" scoped>
  .cps-table-data {
    /deep/ td {
      padding: 7.5px 0 !important;
    }
  }
</style>
