<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>礼包管理</el-breadcrumb-item>
      <el-breadcrumb-item>抽奖记录列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="page-content">
      <el-row @keyup.enter.native="loadData(1)">
        <el-input v-model="queryField.userId" size="small" placeholder="用户ID"></el-input>
        <el-input v-model="queryField.awardId" size="small" placeholder="奖励ID"></el-input>

        <el-select v-model="queryField.provide" size="small" placeholder="奖励状态">
          <el-option
              v-for="item in optionsProvide"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search" size="small" @click="loadData(1)">查询
        </el-button>
      </el-row>
      <el-table class="cps-table-data"
                :data="tableData" size="small"
                border
                :height="height"
                style="width: 100%">
        <el-table-column
            prop="userId"
            label="用户ID"
            width="80">
        </el-table-column>
        <el-table-column
            prop="awardId"
            label="奖品ID"
            width="80">
        </el-table-column>
        <el-table-column
            prop="awardName"
            label="奖品名"
            width="100">
        </el-table-column>
        <el-table-column
            prop="thirdUserName"
            label="微信昵称"
            width="100">
        </el-table-column>
        <el-table-column
            prop="infoCode"
            label="微信唯一标志"
            width="250">
        </el-table-column>
        <el-table-column
            prop="gainTime"
            label="中奖时间"
            width="140">
        </el-table-column>
        <el-table-column
            prop="provide"
            label="是否已发放奖品"
            width="120">
          <template slot-scope="scope">
            <span style="color: #E6A23C" v-if="scope.row.provide === 0">未通过</span>
            <span style="color: #67C23A" v-if="scope.row.provide === 1">已通过</span>
          </template>
        </el-table-column>

        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.provide === 0" type="text" size="small" @click="providePass(scope.row,1)">通过</el-button>
            <el-button v-if="scope.row.provide === 1" type="text" size="small" @click="providePass(scope.row,0)">撤销</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="cps-table-data"
                     @current-change="loadData"
                     background
                     :page-size="18"
                     :current-page="currentIndex"
                     layout="total, prev, pager, next"
                     :total="total">
      </el-pagination>


    </div>
  </div>
</template>
<script>
import {queryAwardProvideList, updateProvide} from "@/api/request";

export default {
  name: 'provideList',
  inject: ['reload'],
  data() {
    return {
      //判断
      isSupper: false,

      //数据
      tableData: [],

      currentIndex:1,
      total: 0,
      height: window.innerHeight - 210,


      optionsProvide: [
        {
          value: '',
          label: ' 奖品状态'
        }, {
          value: '0',
          label: '未通过'
        }, {
          value: '1',
          label: '已通过'
        }],
      //模型
      queryField: {
        userId:'',
        awardId:'',
        provide:'',
      },

      form: {

      },
    };
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.isSupper = user.groupId === "1";
    this.loadData(1);
  },
  methods: {


    loadData(index) {
      let params = {
        pageNo: index,
        pageSize: 18,
        params: this.queryField,
      }
      queryAwardProvideList(params).then(res => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.currentIndex = index;
      })
    },

    providePass(row,provide){
      let params = {
        id : row.id,
        provide : provide,
      }
      updateProvide(params).then(res => {
        if (res.data.success) {
          this.$message.success('已修改');
          this.refresh();
        } else {
          this.$message.error(res.data.error.message);
        }
      })
    },


    //工具类
    refresh() {
      this.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
.cps-main-page {
  .cps-table-data {
    td {
      .el-image {
        display: inherit;
      }
    }
  }
}
</style>