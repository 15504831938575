<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>礼包管理</el-breadcrumb-item>
      <el-breadcrumb-item>礼包码管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="page-content">
      <el-row @keyup.enter.native="loadData(1)">
        <el-input v-model="queryField.giftCodeId" size="small" placeholder="礼包码ID"></el-input>
        <el-input v-model="queryField.giftId" size="small" placeholder="礼包ID"></el-input>
        <el-input v-model="queryField.gameId" size="small" placeholder="游戏ID"></el-input>
        <el-input v-model="queryField.giftCode" size="small" placeholder="礼包码"></el-input>
        <el-input v-model="queryField.giftName" size="small" placeholder="礼包名字"></el-input>
        <el-select v-model="queryField.giftCodeState" size="small" placeholder="是否领取">
          <el-option
              v-for="item in optionsCodeState"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search" size="small" @click="loadData(1)">查询
        </el-button>
        <el-button type="primary" icon="el-icon-plus" size="small" @click="toAdd">新增</el-button>
      </el-row>
      <el-table class="cps-table-data"
                :data="tableData" size="small"
                border
                :height="height"
                style="width: 100%">
        <el-table-column
            prop="giftCodeId"
            label="礼包码ID"
            width="80">
        </el-table-column>
        <el-table-column
            prop="gameId"
            label="游戏ID"
            width="80">
        </el-table-column>
        <el-table-column
            prop="giftId"
            label="礼包ID"
            width="80">
        </el-table-column>
        <el-table-column
            prop="gameName"
            label="游戏名称"
            width="140">
        </el-table-column>
        <el-table-column
            prop="giftName"
            label="礼包名称"
            width="140">
        </el-table-column>
        <el-table-column
            prop="giftCode"
            label="礼包码"
            width="100">
        </el-table-column>
        <el-table-column
            prop="giftCodeState"
            label="礼包码状态"
            :formatter="formatCodeState"
            width="100">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="updateCodeState(scope.row)">修改状态</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="cps-table-data"
                     @current-change="loadData"
                     background
                     :page-size="18"
                     :current-page="currentIndex"
                     layout="total, prev, pager, next"
                     :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {queryGiftCodeList, updateGiftCodeState} from "@/api/request";

export default {
  name: 'GameAppList',
  props: {
    pictureUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isSupper: false,
      tableData: [],
      queryField: {
        giftCodeId:'',
        giftId:'',
        gameId:'',
        giftCode:'',
        giftName:'',
        giftCodeState:''
      },
      optionsCodeState: [
        {
          value: '',
          label: '领取状态'
        }, {
          value: '0',
          label: '未领取'
        }, {
          value: '1',
          label: '已领取'
        },],
      total: 0,
      height: window.innerHeight - 210,
      currentIndex: 1,
    };
  },
  mounted() {
    this.queryField.giftId = this.$route.params.pathMatch;
    this.loadData(this.currentIndex);
  },
  methods: {
    loadData(index) {
      let params = {
        pageNo: index,
        pageSize: 18,
        params: this.queryField,
      }
      queryGiftCodeList(params).then(res => {
            this.tableData = res.data.list;
            this.total = res.data.total;
            this.currentIndex = index;
          }
      )
    },
    toAdd() {
      this.$router.replace(`/gift/code/add/`)
    },
    updateCodeState(item) {
      this.$confirm('此操作将修改改礼包码状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (item.giftCodeState !== 1){
          item.giftCodeState = 1
        }else {
          item.giftCodeState = 0
        }
        let params = {
          giftCodeId: item.giftCodeId,
          giftCodeState:item.giftCodeState,
        }
        updateGiftCodeState(params).then(res => {
          this.$message.success('礼包码状态修改成功');
          this.loadData(this.currentIndex);
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消修改'
        });
      });
    },
    formatCodeState(row, column, cellValue) {
      let ret = ''
      if (cellValue === 0) {
        ret = "未领取"
      } else {
        ret = "已领取"
      }
      return ret;
    },
  }
}
</script>
<style lang="scss" scoped>
.cps-main-page {
  .cps-table-data {
    td {
      .el-image {
        display: inherit;
      }
    }
  }
}
</style>

