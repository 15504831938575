<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>管理员管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>

    <div class="page-content">
    <el-row>
      <el-input v-model="queryField.admin_id" size="small" placeholder="管理员ID"></el-input>
      <el-input v-model="queryField.admin_name" size="small" placeholder="管理用户名"></el-input>
      <el-input v-model="queryField.nick_name" size="small" placeholder="昵称"></el-input>
      <el-input v-model="queryField.admin_phone" size="small" placeholder="手机号码"></el-input>

      <el-select v-model="queryField.admin_level" size="small" placeholder="管理员等级">
        <el-option key="" label="管理员等级" value=""/>
        <el-option key="1" label="1级" value="1"/>
        <el-option key="2" label="2级" value="2"/>
        <el-option key="3" label="3级" value="3"/>
        <el-option key="4" label="4级" value="4"/>
      </el-select>
      <el-select v-model="queryField.examine_status" size="small" placeholder="审核状态">
        <el-option key="" label="审核状态" value=""/>
        <el-option key="0" label="未审核" value="0"/>
        <el-option key="1" label="审核通过" value="1"/>
        <el-option key="2" label="审核失败" value="2"/>
      </el-select>

      <el-button v-if="showQuery" type="primary" @click="loadData(1)" icon="el-icon-search" size="small">查询</el-button>
      <el-button v-if="showAdd" type="primary" icon="el-icon-plus" size="small" @click="toAdminAdd">新增</el-button>
    </el-row>
    <el-table class="cps-table-data"
            :data="tableData" size="small"
            border :height="height"
            style="width: 100%">
      <el-table-column
              prop="admin_id"
              label="管理员ID"
              width="80">
      </el-table-column>
      <el-table-column
              prop="admin_name"
              label="管理用户名"
              width="200">
      </el-table-column>
      <el-table-column
              prop="nick_name"
              label="昵称"
              width="140">
      </el-table-column>
      <el-table-column
              prop="admin_phone"
              label="手机号码"
              width="120">
      </el-table-column>
      <el-table-column
              prop="not_check_amount"
              label="免密额度"
              width="80">
      </el-table-column>
      <el-table-column
              prop="icon"
              label="头像"
              width="80">
        <template slot-scope="scope">
          <el-image v-if="scope.row.icon"
                  style="width:30px; height:30px;border-radius: 3px"
                  :src="scope.row.icon"
                  fit="cover"></el-image>
        </template>
      </el-table-column>
      <el-table-column
              prop="examine_status"
              label="审核状态"
              width="100">

        <template slot-scope="scope">
          <div :style="{'color':scope.row.examine_status === 2 ? 'red' : '#333'}">
            {{formatExamineStatus(scope.row.examine_status)}}
          </div>
        </template>

      </el-table-column>
      <el-table-column
              prop="is_child"
              label="子后台"
              :formatter="formatIsChild"
              width="100">
      </el-table-column>
      <el-table-column
              prop="admin_level"
              label="管理员等级"
              width="100">
      </el-table-column>
      <el-table-column
              prop="add_time"
              label="新增时间"
              width="160">
      </el-table-column>
      <el-table-column
          prop="balance"
          label="余额（充值）"
          width="160">
      </el-table-column>
      <el-table-column
          prop="profit"
          label="收益余额（提现）"
          width="160">
      </el-table-column>
      <el-table-column
              label="操作">
          <template slot-scope="scope">
            <el-button v-if="showEdit" type="text" size="small" @click="editPage(scope.row.admin_id)">编辑</el-button>
            <el-button v-if="showExamine && scope.row.examine_status === 0" type="text" @click="openExaminePage(scope.row.admin_id)" size="small">审核</el-button>
          </template>
      </el-table-column>
    </el-table>
    <el-pagination class="cps-table-data"
            @current-change="loadData"
            background
            :page-size="18"
            :current-page="currentIndex"
            layout="total, prev, pager, next"
            :total="total">
    </el-pagination>
    </div>
  </div>
</template>
<script>
  import { queryAdminList,examineAdmin,getPageButtons } from '@/api/request'
  export default {
    name: 'adminList',
    data() {
      return {
        tableData: [],
        queryField: {
          admin_id: '',
          admin_name: '',
          nick_name: '',
          admin_phone: '',
          admin_level: '',
          examine_status: ''
        },
        options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        total: 0,
        currentIndex: 1,
        height: window.innerHeight - 210,
        showQuery: false,
        showAdd: false,
        showEdit: false,
        showExamine: false,
      }
    },
    mounted() {
      this.loadData(1);
      this.getPageButtons();
    },
    methods: {
      getPageButtons() {
        let path = this.$route.path.replace("/", "");
        getPageButtons(path).then(res => {
          if (res.data.success) {
            let menuCodes = res.data.data;
            this.showQuery = menuCodes.includes("system_admin_query");
            this.showAdd = menuCodes.includes("system_admin_add");
            this.showEdit = menuCodes.includes("system_admin_edit");
            this.showExamine = menuCodes.includes("system_admin_examine");
          } else {
          }
        });
      },
      loadData(index) {
        let params = {
          pageNo: index,
          pageSize: 18,
          params : this.queryField,
        }
        queryAdminList(params).then(res => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.currentIndex = index;
        })

      },
      editPage(adminId) {
        this.$router.push({ path: `/adminList/adminEdit/${adminId}` });
      },
      toAdminAdd(){
        this.$router.push({ path: "/adminList/adminAdd" });
      },
      openExaminePage(adminId) {
        this.$router.push({ path: `/adminList/adminExamine/${adminId}` });
      },
      doExamine() {
        this.examineVisible = false;
        let status = this.examineValue === '1' ? 1 : 2;
        let params = {
          admin_id: this.examineAdminId,
          examine_status: status
        }
        examineAdmin(params).then(res => {
          if (res.data.success){
            this.$message.success('管理员审核成功');
            this.loadData(this.currentIndex);
          }
          else {
            this.$message.error(res.data.error.message);
          }
        });
      },
      formatExamineStatus(cellValue) {
        if (cellValue === 0) {
          return "未审核"
        } else if (cellValue === 1) {
          return "审核通过"
        } else {
          return "审核失败"
        }
      },
      formatIsChild(row, column, cellValue) {
        if (cellValue) {
          return '是'
        } else {
          return '否'
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .cps-main-page{
    .cps-table-data{
      td {
        .el-image {
          display: inherit;
        }
      }
    }
  }
</style>
