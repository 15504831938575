<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
      <el-breadcrumb-item>游戏攻略编辑</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div></div>
    <div class="main-border">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
        <div class="form-border">
          <el-row :gutter="30">
            <el-col :span="8">
              <el-form-item label="攻略id">
                <el-input size="small" disabled v-model="ruleForm.contentId"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="攻略名称">
                <el-input size="small" v-model="ruleForm.contentName"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏名称">
                <el-input size="small" disabled v-model="ruleForm.gameName"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="30">
<!--            <el-col :span="8">
              <el-form-item label="攻略类型">
                <el-select size="small" v-model="ruleForm.contentType" disabled>
                  <el-option label="攻略" value="1"></el-option>
                  <el-option label="视频" value="5"></el-option>
                </el-select>
              </el-form-item>
            </el-col>-->
            <el-col :span="8">
              <el-form-item label="攻略状态">
                <el-select size="small" v-model="ruleForm.status">
                  <el-option label="正常" value="1"></el-option>
                  <el-option label="下架" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="攻略描述">
                <el-input size="small" type="textarea" :autosize="{ minRows: 1, maxRows: 8}" resize="none" v-model="ruleForm.contentDes"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="ruleForm.contentType === '1'">
            <el-col :span="23">
              <el-form-item label="攻略内容">
                <tiny-edit v-if="tinyOpen" v-model="ruleForm.contentText" :height="500" ref="tinyEdit"></tiny-edit>
              </el-form-item>
            </el-col>
          </el-row>
          <div style="margin: 20px auto;width: 150px">
            <el-button size="small" type="primary" @click="submitForm('ruleForm')">修改</el-button>
            <el-button size="small" @click="$router.replace('/game/content/list')">取消</el-button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import richTextEditor from '@/components/richTextEditor.vue';
import {getContent, saveContent} from "@/api/request";

export default {
  name: "contentEdit",
  components: {
    'tinyEdit': richTextEditor
  },
  data() {
    return {
      ruleForm: {
        contentId: '',
        gameName: '',
        contentName: '',
        contentType: '',
        contentDes: '',
        contentText: '',
        status: ''
      },
      contentId: '',
      tinyOpen: true
    }
  },
  mounted() {
    this.contentId = this.$route.params.pathMatch;
    this.loadContent();

  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.contentText = this.formatRichText(this.ruleForm.contentText);
          saveContent(this.ruleForm).then(res => {
            if (res.data.success) {
              this.$message.success("修改成功");
              this.$router.replace({path: '/game/content/list'});
            }else {
              this.$message.error(res.data.error.message);
            }
          })
        } else {
          return false;
        }
      });
    },
    loadContent(){
      getContent(this.contentId).then(res => {
        if(res.data.success){
          this.ruleForm.contentId = res.data.data.content_id.toString();
          this.ruleForm.contentName = res.data.data.content_name.toString();
          this.ruleForm.gameName = res.data.data.game_name.toString();
          this.ruleForm.contentType = res.data.data.content_type.toString();
          this.ruleForm.status = res.data.data.status.toString();
          if(res.data.data.content_des){
            this.ruleForm.contentDes = res.data.data.content_des;
          }
          if(res.data.data.content_text){
            this.ruleForm.contentText = res.data.data.content_text.toString();
          }
        }
      })
    },
    // 处理富文本展示
    formatRichText(html) {
      let newContent = html.replace(/<img[^>]*>/gi, function (match, capture) {
        match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '')
        match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '')
        match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '')
        return match
      })
      // newContent = newContent.replace(/style="[^"]+"/gi, function (match, capture) {
      //   match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;')
      //   return match
      // })
      // newContent = newContent.replace(/<br[^>]*\/>/gi, '')
      newContent = newContent.replace(/\<img/gi, '<img style="max-width:100% !important;height:auto;display:block;margin-top:0;margin-bottom:0;"');

      newContent = newContent.replace(/<td style=[^>]*/gi,(match, capture) => {
        let style = match.substring(match.indexOf("style"));
        if(!/border:[^]*/gi.test(style)){
          style = style.slice(0,style.length - 1) + "; border:1px solid #ddd;" + style.slice(style.length - 1);
          match = match.replace(/style="[^"]+"/gi, style).replace(/style='[^']+'/gi, style)
        }
        return match;
      })

      return newContent
    }
  },
  /*解决富文本切换路由无法使用的问题*/
  beforeRouteEnter(to,from,next){
    next(vm => {
      vm.tinyOpen = true;
    })
  },
  beforeRouteLeave(to,from,next){
    this.tinyOpen = false;
    next();
  }
}
</script>

<style scoped>
.main-border {
  width: 100%;
  padding: 0 12px;
  margin: 20px 0;
}
.el-input,.el-select,/deep/ .el-textarea__inner {
  width: 350px;
}
</style>