<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
      <el-breadcrumb-item>游戏角色</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="page-content">
    <el-row @keyup.enter.native="loadData(1)">
      <el-input v-model="queryField.gameId" size="small" placeholder="游戏ID"></el-input>
      <el-input v-model="queryField.gameName" size="small" placeholder="游戏名称"></el-input>
      <el-input v-model="queryField.userId" size="small" placeholder="玩家id"></el-input>
      <el-input v-model="queryField.userName" size="small" placeholder="玩家账号"></el-input>
      <el-input v-model="queryField.serverCode" size="small" placeholder="区服"></el-input>
      <el-input v-model="queryField.serverName" size="small" placeholder="区服名称"></el-input>
      <el-input v-model="queryField.roleCode" size="small" placeholder="角色编号"></el-input>
      <el-input v-model="queryField.roleName" size="small" placeholder="角色名称"></el-input>
      <el-button v-if="showQuery"  type="primary" icon="el-icon-search" size="small" @click="loadData(1)">查询</el-button>
      <el-button
          type="primary"
          @click="moveSission"
          size="small"
      >重置</el-button
      >
    </el-row>
    <el-table class="cps-table-data"
              :data="tableData" size="small"
              border
              :height="height"
              style="width: 100%">
      <el-table-column
          prop="game_id"
          label="游戏ID"
          width="80">
      </el-table-column>
      <el-table-column
          prop="game_name"
          label="游戏名称"
          width="240">
      </el-table-column>
      <el-table-column
          prop="user_id"
          label="账号id"
          width="80">
      </el-table-column>
      <el-table-column
          prop="user_name"
          label="玩家账号"
          width="120">
      </el-table-column>
      <el-table-column
          prop="admin_name"
          label="归属渠道"
          width="120">
      </el-table-column>
      <el-table-column
          prop="server_code"
          label="区服"
          width="120">
      </el-table-column>
      <el-table-column
          prop="server_name"
          label="区服名称"
          width="240">
      </el-table-column>
      <el-table-column
          prop="role_code"
          label="角色id"
          width="240">
      </el-table-column>
      <el-table-column
          prop="role_name"
          label="角色名字"
          width="240">
      </el-table-column>
    </el-table>
    <el-pagination  class="cps-table-data"
        @current-change="loadData"
        background
        :page-size="15"
        layout="total, prev, pager, next"
        :total="total">
    </el-pagination>
    </div>
  </div>
</template>
<script>
import {queryGameRoleList,getPageButtons} from "@/api/request";
export default {
  name: 'gameRole',
  data() {
    return {
      tableData: [],
      queryField: {
        roleCode:'',
        roleName:'',
        serverCode:'',
        serverName:'',
        gameId: '',
        gameName: '',
        userId: '',
        userName: '',
      },
      total: 0,
      height: window.innerHeight - 210,
      showQuery: false,
    };
  },
  mounted() {
    this.upgameRole();
    this.loadData(1);
    this.getPageButtons();
  },
  methods: {
    upgameRole() {
      if (sessionStorage.getItem("gameroleStor") != null) {
        let params = JSON.parse(sessionStorage.getItem('gameroleStor'));
        console.log(params);
        this.queryField.gameId=params.game_id,
        this.queryField.gameName=params.game_name,
        this.queryField.serverCode=params.server_code,
            this.queryField.serverName=params.server_name,
            this.queryField.roleCode=params.role_code,
            this.queryField.roleName=params.role_name,
            this.queryField.userId=params.user_id,
            this.queryField.userName=params.user_name
      }
    },
    moveSission(){
      sessionStorage.removeItem("gameroleStor");
      this.$router.go(0)
    },
    getPageButtons() {
      let path = this.$route.path.replace("/", "");
      getPageButtons(path).then(res => {
        if (res.data.success) {
          let menuCodes = res.data.data;
          //let menuCodes = [];
          this.showQuery = menuCodes.includes("game_role_query");
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    loadData(index) {
      let params = {
        pageNo: index,
        pageSize: 15,
        params: {
          game_id: this.queryField.gameId,
          game_name: this.queryField.gameName,
          server_code: this.queryField.serverCode,
          server_name: this.queryField.serverName,
          role_code: this.queryField.roleCode,
          role_name: this.queryField.roleName,
          user_id: this.queryField.userId,
          user_name: this.queryField.userName,
        }
      }
      sessionStorage.setItem('gameroleStor', JSON.stringify(params.params))
      queryGameRoleList(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      })
    },
  }

}
</script>
<style lang="scss" scoped>
  .cps-main-page {
    .cps-table-data {
      td {
        .el-image {
          display: inherit;
        }
      }
    }
  }
</style>

