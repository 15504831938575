<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>充值</el-breadcrumb-item>
      <el-breadcrumb-item>给玩家充值</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-border">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px" @keyup.enter.native="onSubmit()">
        <el-form-item label="玩家id" prop="user_id">
          <el-input v-model="form.user_id" size="small" placeholder="请输入玩家id"></el-input>
        </el-form-item>
        <el-form-item label="玩家账号" prop="user_name">
          <el-input v-model="form.user_name" size="small" placeholder="请输入玩家账号"></el-input>
        </el-form-item>
        <el-form-item label="充值游戏" prop="game_id">
          <el-select v-model="form.game_id" size="small">
            <el-option v-for="item in gameList"
                       :value="item.game_id" :label="item.game_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充值会游币" prop="amount">
          <el-input v-model="form.amount" size="small" placeholder="请输入充值会游币金额"></el-input>
          <span style="margin-left: 20px;color: #ff4b4b">注：1:1扣除余额</span>
        </el-form-item>
        <el-form-item label="充值绑币" prop="bindAmount">
          <el-input v-model="form.bindAmount" size="small" placeholder="请输入充值绑币金额"></el-input>
        </el-form-item>
        <el-form-item label="支付密码" prop="pay_pwd">
          <el-input type="password" v-model="form.pay_pwd" size="small" placeholder="请输入支付密码"></el-input>
        </el-form-item>
        <el-form-item label="收款金额" prop="receive_money">
          <el-input v-model="form.receive_money" size="small" placeholder="请输入实际收到金额"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" size="small" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item label="会游币余额">
          <el-input v-model="form.balance" size="small" placeholder="请先选择游戏" disabled></el-input>
        </el-form-item>
        <el-form-item label="绑币余额">
          <el-input v-model="form.bindBalance" size="small" placeholder="请先选择游戏" disabled></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" :loading="submitting">确认充值</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import {
  findUserById,
  findUserByUserName,
  userRecharge,
  checkDiscount,
  forbidUser,
  queryUserRecharge, queryRechargeBalance
} from '@/api/request'
import crypto from '@/utils/crypto.js';

export default {
  name: 'userCoin',
  data() {
    let validateId = (rule, value, callback) => {
      findUserById(this.form.user_id).then(res => {
        if (!res.data.success) {
          this.form.user_name = '';
          this.gameList = [];
          this.form.game_id = '';
          callback(new Error(res.data.error.message));
        } else {
          let userName = res.data.data.user_name;
          if (userName !== this.form.user_name) {
            this.form.user_name = userName;
            this.gameList = res.data.data.gameList;
          }
          callback();
        }
      });
      //}
    };
    let validateName = (rule, value, callback) => {
      findUserByUserName(this.form.user_name).then(res => {
        if (!res.data.success) {
          this.form.user_id = '';
          this.gameList = [];
          this.form.game_id = '';
          callback(new Error(res.data.error.message));
        } else {
          let userId = res.data.data.user_id;
          if (userId !== this.form.user_id) {
            this.form.user_id = userId;
            this.gameList = res.data.data.gameList;
          }
          callback();
        }
      });
    };

    let validateBalance = (rule, value, callback) => {
      let params = {
        user_id: this.form.user_id,
        game_id: this.form.game_id
      }
      queryUserRecharge(params).then(res => {
        if (!res.data.success) {
          this.form.balance = '';
          callback(new Error(res.data.error.message));
        } else {
          let balance = res.data.data.balance;
          let bindBalance = res.data.data.bindBalance;
          
          if (balance !== this.form.balance || bindBalance !== this.form.bindBalance) {
            this.form.balance = balance;
            this.form.bindBalance = bindBalance;
          }
          callback();
        }
      });
    };

    let validate = (rule, value, callback) => {
      if(this.form.amount && this.form.bindAmount ||
          !this.form.amount && !this.form.bindAmount){
        callback(new Error("请在会游币和绑币选择其中一个进行充值"));
      }
      callback();
    };
    
    return {
      form: {
        user_id: '',
        user_name: '',
        game_id: '',
        amount: '',
        bindAmount: '',
        pay_pwd: '',
        receive_money: '',
        remark: '',
        balance: '',
        bindBalance: ''
      },
      rules: {
        user_id: [
          {required: true, message: '请输入玩家id'},
          {pattern: /^\d+$/, trigger: 'blur', message: '玩家id格式不正确'},
          {validator: validateId, trigger: 'blur'}
        ],
        user_name: [
          {required: true, message: '请输入玩家帐号'},
          {validator: validateName, trigger: 'blur'}
        ],
        game_id: [
          {required: true, message: '请选择充值的游戏'},
          {validator: validateBalance, trigger: 'change'}
        ],
        amount: [
          {pattern: /^-?\d+([.]\d{1,2})?$/, trigger: 'blur', message: '充值金额格式不正确'},
          {validator: validate, trigger: 'change'}
        ],
        bindAmount: [
          // {required: true, message: '请输入充值金额'},
          {pattern: /^-?\d+([.]\d{1,2})?$/, trigger: 'blur', message: '充值金额格式不正确'},
          {validator: validate, trigger: 'change'}
        ],
        pay_pwd: [
          {required: true, message: '请输入支付密码'},
        ],
        receive_money: [
          {required: false, message: '请输入实收金额'},
          {pattern: /^-?\d+([.]\d{1,2})?$/, trigger: 'blur', message: '实收金额格式不正确'},
        ],
      },
      submitting: false,
      gameList: [],
    }
  },
  methods: {
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.submitting = true;
          let params = {
            user_id: this.form.user_id,
            game_id: this.form.game_id,
            recharge_amount: this.form.amount,
            recharge_bind_amount: this.form.bindAmount,
            pay_pwd: crypto.encrypt(this.form.pay_pwd),
            receive_money: this.form.receive_money,
            remark: this.form.remark
          }
          checkDiscount(params).then(res => {
            if (res.data.success) {
              userRecharge(params).then(res => {
                if (res.data.success) {
                  this.$message.success('充值成功！');
                  location.reload();
                } else {
                  this.$message.error(res.data.error.message);
                }
                this.submitting = false;
              });
            } else {
              this.$confirm(res.data.error.message, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                userRecharge(params).then(res => {
                  if (res.data.success) {
                    this.$message.success('充值成功！');
                    location.reload();
                  } else {
                    this.$message.error(res.data.error.message);
                  }
                  this.submitting = false;
                });
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消充值'
                })
                this.submitting = false;
              })
            }
          });
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-input {
  width: 250px !important;
  min-width: 200px;
}

/deep/ .el-input__inner {
  border-radius: 2px !important;
  border: 1px solid #aaaaac !important;
}

/deep/ .el-form-item__label {
  font-weight: 600;
  font-size: 13px;
}

.main-button {
  font-weight: 700;
  width: 120px;
}

.el-button {
  font-weight: 700;
  width: 180px;
}
</style>
