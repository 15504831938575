<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>礼包管理</el-breadcrumb-item>
      <el-breadcrumb-item>礼包列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="page-content">
      <el-row @keyup.enter.native="loadData(1)">
        <el-input v-model="queryField.giftId" size="small" placeholder="礼包ID"></el-input>
        <el-input v-model="queryField.gameId" size="small" placeholder="游戏ID"></el-input>
        <el-input v-model="queryField.gameName" size="small" placeholder="礼包名"></el-input>
        <el-input v-model="queryField.giftGroup" size="small" placeholder="礼包分组"></el-input>
        <el-select v-model="queryField.giftType" clearable placeholder="礼包类型">
          <el-option
              v-for="item in options.giftType"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="queryField.giftIssueType" clearable placeholder="礼包发放方式">
          <el-option
              v-for="item in options.giftIssueType"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-button  type="primary" icon="el-icon-search" size="small" @click="loadData(1)">查询
        </el-button>
        <el-button type="primary" size="small">重置
        </el-button>
        <el-button type="primary" icon="el-icon-plus" size="small" @click="toAddGift">新增</el-button>
      </el-row>
      <el-table class="cps-table-data"
                :data="tableData" size="small"
                border
                :height="height"
                style="width: 100%">
        <el-table-column
            prop="giftId"
            label="礼包id"
            width="80">
        </el-table-column>
        <el-table-column
            prop="gameId"
            label="游戏id"
            width="80">
        </el-table-column>
        <el-table-column
            prop="gameName"
            label="游戏名称"
            width="160">
        </el-table-column>
        <el-table-column
            prop="giftName"
            label="礼包名字"
            width="200">
        </el-table-column>
        <el-table-column
            prop="giftGroup"
            label="礼包分组"
            width="100">
        </el-table-column>
        <el-table-column
            prop="giftType"
            label="礼包类型"
            :formatter="formatGiftType"
            width="80">
        </el-table-column>
        <el-table-column
            prop="giftIssueType"
            label="礼包发放方式"
            :formatter="formatGiftIssueType"
            width="100">
        </el-table-column>
        <el-table-column
            prop="giftNumberLimit"
            label="领取次数限制"
            :formatter="formatGiftNumberLimit"
            width="120">
        </el-table-column>
        <el-table-column
            prop="giftMoney"
            label="礼包限制金额"
            width="100">
        </el-table-column>
        <el-table-column
            prop="giftStart"
            label="开始时间"
            width="100"
            align="center"
            :formatter="dateFormat">
        </el-table-column>
        <el-table-column
            prop="giftEnd"
            label="结束时间"
            width="100"
            align="center"
            :formatter="dateFormat">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="toEditGift(scope.row.giftId)">编辑</el-button>
            <el-button type="text" size="small" @click="toCodeList(scope.row.giftId)">查看礼包码</el-button>
            <el-button type="text" size="small" @click="toCodeAdd(scope.row.giftId)">添加礼包码</el-button>
            <el-button type="text" size="small" @click="deleteGift(scope.row.giftId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="cps-table-data"
                     @current-change="loadData"
                     background
                     :page-size="15"
                     :current-page="currentIndex"
                     layout="total, prev, pager, next"
                     :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {deleteGift, queryGiftList} from "@/api/request";
import moment from "moment"

export default {
  name: "GiftList",
  data(){
    return{
      tableData: [],

      queryField:{
        giftId:'',
        gameId:'',
        gameName:'',
        giftGroup:'',
        giftType:'',
        giftIssueType:'',
      },

      options:{
        giftType:[{
          value: '0',
          label: '免费'
        }, {
          value: '1',
          label: '日冲'
        }, {
          value: '2',
          label: '累充'
        }, {
          value: '3',
          label: '限时单日充值'
        }, {
          value: '4',
          label: '限时累计充值'
        }],
        giftIssueType:[{
          value: '1',
          label: '客服发放'
        }, {
          value: '0',
          label: '自动发放'
        }],
      },

      total: 0,
      currentIndex: 1,
      height: window.innerHeight - 210,
    }
  },
  mounted(){
    this.loadData(1);
  },
  methods:{
    //  --------------跳转 请求 处理-------------
    loadData(index) {
      let params = {
        pageNo: index,
        pageSize: 18,
        params: this.queryField,
      }
      queryGiftList(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentIndex = index;
      })
    },

    toAddGift() {
      this.$router.push("/gift/add")
    },

    toEditGift(giftId) {
      this.$router.push(`/gift/edit/${giftId}`)
    },

    toCodeList(giftId) {
      this.$router.push(`/gift/code/list/${giftId}`)
    },

    toCodeAdd(giftId) {
      this.$router.push(`/gift/code/add/${giftId}`)
    },

    deleteGift(giftId){
      this.$confirm('此操作将永久删除该礼包, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteGift(giftId).then(res => {
          this.$message.success('礼包删除成功');
          this.refresh();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

  //  ---------展现样式---------------
    formatGiftType(row, column, cellValue) {
      let ret = ''
      if (cellValue === 0) {
        ret = "免费"
      } else if (cellValue === 1) {
        ret = "日冲"
      } else if (cellValue === 2){
        ret = "累充"
      } else if (cellValue === 3) {
        ret = "限时单日充值"
      } else if (cellValue === 4) {
        ret = "限时累计充值"
      }
      return ret;
    },
    formatGiftIssueType(row, column, cellValue) {
      let ret = ''
      if (cellValue === 0) {
        ret = "自动发码"
      } else if (cellValue === 1) {
        ret = "客服审核"
      }
      return ret;
    },
    formatGiftNumberLimit(row, column, cellValue) {
      let ret = ''
      if (cellValue === 0) {
        ret = "只能领取一次"
      } else if (cellValue === 1) {
        ret = "每天可以领取一次"
      }
      return ret;
    },


  //  ---------工具方法------------
    /*日期处理*/
    dateFormat:function(row,column){
      var date = row[column.property];
      if(date === undefined){
        return ''
      }
      return moment(date).format("YYYY-MM-DD")
    },

    //刷新页面
    refresh() {
      this.reload()
    }
  }
}
</script>

<style scoped>

</style>