<template>
    <div class="cps-main-page">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
            <el-breadcrumb-item>交易管理</el-breadcrumb-item>
            <el-breadcrumb-item>交易商品</el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>

        <div class="page-content">

            <el-row @keyup.enter.native="loadData(1)">
                <el-input v-model="queryField.goodsId" size="small" placeholder="商品id"></el-input>
                <el-select v-model="queryField.goodsType" size="small" placeholder="商品类型">
                    <el-option label="账号" value="1"/>
                    <el-option label="材料" value="2"/>
                </el-select>
                <el-input v-model="queryField.serverName" size="small" placeholder="游戏区服"></el-input>
                <el-input v-model="queryField.saleUserName" size="small" placeholder="卖家账号"></el-input>
                <el-select v-model="queryField.status" size="small" placeholder="商品状态">
                    <el-option label="待售" value="1"/>
                    <el-option label="已出售" value="2"/>
                    <el-option label="下架" value="3"/>
                </el-select>
                <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
                <el-button size="small" @click="reset">重置</el-button>
            </el-row>

            <el-table class="cps-table-data"
                      :data="tableData"
                      size="small"
                      border
                      v-loading=loading
                      element-loading-text="拼命加载中"
                      :height="height"
                      style="width: 100%"
                      id="rebateSetTable">
                <el-table-column
                        prop="goods_id"
                        label="商品id"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="user_id"
                        label="出售人"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="user_name"
                        label="卖家帐号"
                        width="100">
                </el-table-column>
                <el-table-column
                        label="商品类型"
                        width="80">
                    <template slot-scope="scope">
                        {{scope.row.goods_type == 1 ? '账号' : '材料'}}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="goods_title"
                        label="商品标题"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="goods_desc"
                        label="商品描述"
                        width="300">
                </el-table-column>
                <el-table-column
                        prop="goods_price"
                        label="商品价格"
                        width="120">
                </el-table-column>
                <el-table-column
                        label="查看图片"
                        width="80" >
                    <template slot-scope="scope">
                        <el-image
                                style="width: 50px; height: 50px"
                                fit="cover"
                                :src="tableData[scope.$index].srcList[0]"
                                :preview-src-list="tableData[scope.$index].srcList">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="platform_name"
                        label="所属平台"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="game_name"
                        label="游戏名称"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="server_name"
                        label="区服"
                        width="100">
                </el-table-column>
                <el-table-column
                        prop="vip"
                        label="vip等级"
                        width="70">
                </el-table-column>
                <el-table-column v-if="false"
                        prop="see_num"
                        label="商品查看次数"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="sale_user_name"
                        label="出售账号"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="wx"
                        label="卖家wx"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="qq"
                        label="卖家QQ"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="sale_token"
                        label="预留口令"
                        width="105">
                </el-table-column>
                <el-table-column
                        label="商品状态"
                        prop="status"
                        :formatter="GoodsStatusFormat"
                        width="110">
                </el-table-column>
                <el-table-column
                        prop="add_time"
                        label="上架时间"
                        width="140">
                </el-table-column>
                <el-table-column
                        label="操作"
                        width="80">
                    <template slot-scope="scope">
                        <el-button type="text" v-if="scope.row.status == 1" size="small" @click="soldOut(scope.row.goods_id)">下架</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--底部分页-->
            <el-pagination
                    class="cps-table-data"
                    @current-change="loadData"
                    background
                    :page-size="10"
                    :current-page="currentIndex"
                    layout="total,prev, pager, next"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>

    import {queryTransactionGoodsList,soldOutGoods} from '@/api/request';

    export default {
        name: "transactionGoods",
        data(){
            return {
                tableData:[],
                total: 0,
                currentIndex: 1,
                height: window.innerHeight - 230,
                loading:false,
                queryField:{
                    goodsId: '',
                    goodsType: '',
                    saleUserName: '',
                    serverName: '',
                    status: '',
                },
            }
        },
        mounted() {
            this.loadData(this.currentIndex)
        },
        methods:{
            loadData(index) {
                this.loading = true;
                let params = {
                    pageNo: index,
                    pageSize: 15,
                    params: this.queryField,
                }
                queryTransactionGoodsList(params).then(res => {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                    this.currentIndex = index;
                    this.loading = false;
                })
            },
            reset(){
                this.queryField = {
                    goodsId: '',
                    goodsType: '',
                    saleUserName: '',
                    serverName: '',
                    status: '',
                }
            },
            soldOut(goodsId){
                this.$confirm('确认要下架商品吗?','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then( () => {
                    soldOutGoods(goodsId).then(res => {
                        if(res.data.success){
                            this.loadData(this.currentIndex);
                            this.$message.success("操作成功！");
                        }else {
                            this.$message.error(res.data.error.message);
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    })
                })
            },
            GoodsStatusFormat(row, column, cellValue){  //1:待售,2:已出售,3:下架
                let status = '';
                if (cellValue == 1){
                    status = '待售';
                }else if(cellValue == 2){
                    status = '已出售';
                }else if(cellValue == 3){
                    status = '下架'
                }
                return status;
            }
        }
    }
</script>

<style>

</style>