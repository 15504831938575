<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>充值</el-breadcrumb-item>
      <el-breadcrumb-item>会长充值记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>

    <div class="page-content">
    <el-row @keyup.enter.native="loadData(1)">
      <el-input v-if="show" v-model="queryField.admin_id" size="small" placeholder="会长id"></el-input>
      <el-input v-if="show" v-model="queryField.admin_name" size="small" placeholder="会长帐号"></el-input>
      <el-select v-model="queryField.change_type">
        <el-option value="" label="变动类型"></el-option>
        <el-option value="1" label="给下级发币"></el-option>
        <el-option value="2" label="被上级发币"></el-option>
        <el-option value="3" label="给玩家发币"></el-option>
      </el-select>
      <el-date-picker
              v-model="queryField.time"
              size="small"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
      </el-date-picker>

      <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
    </el-row>
    <el-table class="cps-table-data"
            :data="tableData" size="small"
            border
            :height="height"
            style="width: 100%">
      <el-table-column
              prop="admin_id"
              label="会长id"
              width="100">
      </el-table-column>
      <el-table-column
              prop="admin_name"
              label="会长名称"
              width="230">
      </el-table-column>
      <el-table-column
              prop="change_type"
              label="变动类型"
              :formatter="formatChangeType"
              width="120">
      </el-table-column>
      <el-table-column
          prop="before_balance"
          label="变动前余额"
          width="120">
      </el-table-column>
      <el-table-column
          prop="after_balance"
          label="变动后余额"
          width="120">
      </el-table-column>
      <el-table-column
              prop="add_time"
              label="充值时间"
              width="160">
      </el-table-column>
    </el-table>
    <el-pagination  class="fixed-pagination"
            @current-change="loadData"
            background
            :page-size="15"
            :current-page="currentIndex"
            layout="total, prev, pager, next"
            :total="total">
    </el-pagination>
    </div>
  </div>
</template>
<script>
  import { adminRechargeList } from '@/api/request'

  export default {
    name: 'adminCoinLogList',
    data() {
      return {
        queryField: {
          admin_id: '',
          admin_name: '',
          change_type: '',
          time: [],
          start_time: '',
          end_time: ''
        },
        tableData: [],
        total: 0,
        height: window.innerHeight - 210,
        show: false,
        currentIndex:1
      };
    },
    mounted() {
      let user = JSON.parse(localStorage.getItem("userInfo"));
      if (user.groupId === '1') {
        this.show = true;
      }
      this.loadData(1);
    },
    methods: {
      loadData(index) {
        if (this.queryField.time != null && this.queryField.time.length > 0) {
          this.queryField.start_time = this.queryField.time[0] + " 00:00:00";
          this.queryField.end_time = this.queryField.time[1] + " 23:59:59";
        }
        else {
          this.queryField.start_time = "";
          this.queryField.end_time = "";
        }
        let params = {
          pageNo: index,
          pageSize: 15,
          params : this.queryField,
        }
        adminRechargeList(params).then(res => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.currentIndex=index;
        });
      },
      formatChangeType(row, column, cellValue) {
        let ret = ''
        if (cellValue===1) {
          ret = "给下级发币"
        } else if (cellValue===2){
          ret = "被上级发币"
        } else if (cellValue===3) {
          ret = "给玩家发币"
        }
        return ret;
      }
    },
  }
</script>
<style lang="scss" scoped>
  /deep/ .is-active {
    background-color: unset !important;
  }
</style>
