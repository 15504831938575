<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
      <el-breadcrumb-item>游戏推广新增</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-border">
      <el-form ref="form" :model="form" label-width="120px">
        <div class="form-border">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="游戏ID">
                <el-input v-model="form.gameId" size="small"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="form-border">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="是否在广告位">
                <el-radio-group v-model="form.indexShow">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="详细页展示类型">
                <el-radio-group v-model="form.detailViewType">
                  <el-radio :label="1">图片</el-radio>
                  <el-radio :label="2">视频</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="16" align="center">
              <el-form-item>
                <el-button type="primary" @click="onSubmit">提交</el-button>
                <el-button @click="goBack">返回</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {insertGameExtend} from "@/api/request";

export default {
  name: 'GameAppEdit',
  data() {
    return {
      form: {
        gameId:'',
        indexShow:'',
        detailViewType:'',
      }
    }
  },
  mounted() {
  },
  methods: {
    onSubmit() {
      let params = this.form;
      insertGameExtend(params).then(res => {
        if (res.data.success) {
          this.$message.success('新增成功');
          this.$router.push({path: "/game/extend/list"});
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    goBack() {
      this.$router.push({path: "/game/extend/list"});
    },

  }
}
</script>

<style scoped lang="scss">
.main-border {
  width: 100%;
  padding: 0 12px;
}

.el-form-item {
}

.el-input {
  width: 300px !important;
}

.el-select {
  width: 300px;
}

/deep/ .el-input__inner {
  border-radius: 2px !important;
  border: 1px solid #aaaaac !important;
}

.el-button {
  font-weight: 700;
  width: 100px;
}

/deep/ .el-form-item__label {
  font-weight: 600;
  font-size: 13px;
}

</style>
