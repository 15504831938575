<template>
  <el-container class="cps-all">
    <el-header class="cps-header">
      <el-row>
        <el-col :span="12">
          <div class="header-left">
            <div class="logo-panel">
              <el-image :src="logo" style="width: 35px; height: 35px;"></el-image>
              <span class="web-title">会游CPS管理系统</span>
            </div>
          </div>

        </el-col>
        <el-col :span="12">
          <div class="header-right">
            <span style="color: white;font-size: .8rem;margin-right: 25px" >欢迎您，{{username}}&可用余额:￥{{balance}}<el-button type="success" size="mini" @click="recharge">充值</el-button></span>
            <el-dropdown @command="handleCommand" style="cursor: pointer;" trigger="click">
              <i class="el-icon-setting" style="margin-right: 15px;color: white;font-size: .8rem">
                <span style="margin-left: 6px">设置</span>
              </i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="/modifyPassword">修改登录密码</el-dropdown-item>
                <el-dropdown-item command="/modifyPayWord">修改支付密码</el-dropdown-item>
<!--                <el-dropdown-item command="/modifyPhone">修改手机号</el-dropdown-item>-->
                <el-dropdown-item command="/modifyUserinfo">修改个人信息</el-dropdown-item>
                <el-dropdown-item command="/logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-col>
      </el-row>
    </el-header>
    <el-container class="cps-menu-main">
      <el-aside class="cps-menu" width="220px">



        <el-menu :default-active="'/' + $route.path.split('/')[1]"
                 :router="true"
                 style="outline: none;"
                 :unique-opened="true"
                 background-color="#545c64"
                 text-color="#fff"
                 active-text-color="#ffd04b">

          <el-menu-item index="/home">
            <i class="el-icon-s-home"></i>
            <span slot="title">首页</span>
          </el-menu-item>

          <el-submenu  v-for="(item) in menuData" :index="item.menu_id + ''">
            <template slot="title"><i :class="item.icon_class"></i>{{item.menu_name}}</template>
            <el-menu-item v-for="childItem in item.childList" :index="childItem.path">{{childItem.menu_name}}</el-menu-item>
          </el-submenu>

        </el-menu>
      </el-aside>
      <el-main class="cps-main">
        <router-view/>
      </el-main>
    </el-container>
    <el-dialog title="会游币充值" :visible.sync="dialogFormVisible" :center=true width="400px" :show-close=true
               :close-on-click-modal=false :close-on-press-escape="false">
      <div class="main-border" v-show="showForm">
        <el-tabs v-model="activeName">
          <el-tab-pane label="支付宝" name="first">
            <el-form :model="ruleForm" label-position="right" label-width="100px" status-icon :rules="rules" ref="ruleForm" :span="12"  class="demo-ruleForm">
              <el-form-item label="充值金额" prop="amount">
                <el-input type="text"   v-model="ruleForm.amount"></el-input>
              </el-form-item>
              <el-form-item label="支付宝昵称" prop="nickName">
                <el-input type="text" v-model="ruleForm.nickName"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">确认充值</el-button>
                <el-button @click="resetForm('ruleForm')">重置</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="bar-container" v-show="!showForm">
        <el-tabs v-model="activeName">
          <el-tab-pane label="支付宝" name="first">
            <div class="qrcode" ref="qrCodeUrl"></div>
            <div class="qr-bottom">
              付款金额
              <span>￥{{preAmount}}</span><br>
              <el-button plain icon="el-icon-arrow-left" style="margin-top: 20px" @click="back">已成功充值，返回</el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
import {initMenu, logout, getWallet, createOrder} from '@/api/request'
  import logo from '@/assets/logo.png'
import QRCode from "qrcodejs2";
  const validateMoney = (rule,value,callback) =>{
    if(!value){
      callback(new Error('请输入充值金额'))
    }else if(/^\./.test(value) || /\.$/.test(value)){
      callback(new Error('请输入正确格式的充值金额')) //防止输入多个小数点
    }
    else if(value.indexOf(".") !== -1 && value.split('.').length > 2){
      callback(new Error('请输入正确格式的充值金额')) //防止输入多个小数点
    }else if(value.indexOf(".") !== -1 && value.split('.')[1].length > 2){
      callback(new Error('请输入正确的小数位数')) //小数点后两位
    }else{
      callback();
    }
  };
  export default {
    data: function() {
      return {
        preAmount:'',
        activeName: 'first',
        logo,
        menuData: [],
        activeIndex: '/',
        username: '',
        balance:'',
        dialogFormVisible:false,
        showForm:true,
        ruleForm: {
          password: '',
          account: '',
          checkCode: '',
          phone: ''
        },
        rules: {
          amount: [
            { required: true, message: '请输入充值金额'},
            { validator: validateMoney, trigger: 'blur' },
            { min: 1, max: 13, trigger: 'blur', message: '请输入正确的充值金额' }
          ],
          nickName: [
            { min: 1, max: 30, trigger: 'blur', message: '请输入正确的支付宝昵称' },
            { required: true, message: '请输入支付宝昵称'}
          ]
        }
      };

    },
    mounted() {
      let user = JSON.parse(localStorage.getItem("userInfo"));
      this.username = user.nickName ? user.nickName : user.adminName;
      if (user.status !== 1) {
        this.$confirm(`尊敬的用户您好，由于您的账号还未通过审核，请先点击右上角【设置】-【修改个人信息】，
                完善个人信息，等待管理员审核后再次登录。`, '温馨提示', {
          confirmButtonText: '确定',
          type: 'warning'
        }).then(() => {
        }).catch(() => {
        });
      } else {
        initMenu().then(res => {
          if (res.data.success) {
            this.menuData = res.data.data;
            console.log('menuData', this.menuData);
          }
        });
        this.refreshBalance();
      }
    },
    beforeCreate() {

    },
    created:function(){
      this.defaultIndex();
    },
    methods: {
      back() {
        //this.showForm = true;
        window.location.reload();
      },
      formatMoney (number, decimals = 0, decPoint = '.', thousandsSep = ',')  {
        number = (number + '').replace(/[^0-9+-Ee.]/g, '')
        let n = !isFinite(+number) ? 0 : +number
        let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
        let sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
        let dec = (typeof decPoint === 'undefined') ? '.' : decPoint
        let s = ''
        let toFixedFix = function (n, prec) {
          let k = Math.pow(10, prec)
          return '' + Math.ceil(n * k) / k
        }
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
        let re = /(-?\d+)(\d{3})/
        while (re.test(s[0])) {
          s[0] = s[0].replace(re, '$1' + sep + '$2')
        }
        if ((s[1] || '').length < prec) {
          s[1] = s[1] || ''
          s[1] += new Array(prec - s[1].length + 1).join('0')
        }
        return s.join(dec)
      },
      creatQrCode(m, u) {
        let qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: 'alipays://platformapi/startapp?appId=20000123&actionType=scan&biz_data={"s": "money","u": "' + u + '","a": "' + m + '","m":""}', // 需要转换为二维码的内容
          width: 300,
          height: 300,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        });
        this.$refs.qrCodeUrl.title = "";
        this.preAmount = this.formatMoney(m,2);
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let params = {
              order_money: this.ruleForm.amount,
              pay_nick_name: this.ruleForm.nickName,
              pay_type: 2
            }
            createOrder(params).then(res => {
              if (res.data.success){
                this.creatQrCode(res.data.data.amount, res.data.data.receive);
                this.showForm = false;
              }
              else {
                this.$alert(res.data.error.message, '提示', {
                  confirmButtonText: '确定',
                  callback: action => {

                  }
                });
              }
            });
          } else {
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      refreshBalance(){
        getWallet().then(res => {
          if (res.data.success) {
            this.balance = res.data.data.balance;
          }
        });
      },
      recharge(){
        this.dialogFormVisible=true;
        // this.$message.success('充值成功');
      },
      handleCommand(command) {
        if (command === '/logout') {
          logout().then(res => {
            if (res.data.success) {
              localStorage.removeItem('token');
              localStorage.removeItem('userInfo');
              this.$message.success('已成功退出登录');
              this.$router.replace("/login");
            } else {
              this.$message.error(res.data.error.message);
            }
          });
        }
        else {
          this.$router.push({ path: command });
        }
      },
      handleSelect(key, keyPath) {
        //console.log(key, keyPath);
      },
      defaultIndex(){
        let path = this.$route.path;
        console.log(path, path.split("/")[1]);
      }
    },
    beforeUpdate:function(){
      return{
        activeIndex:this.$route.matched[1].path
      }
    },

  };
</script>
<style lang="scss" scoped>
  body{
    margin: 0;
    padding: 0;
  }
  .cps-all {
    height: 100vh;
    overflow: hidden;
  }
  .cps-header {
    text-align: right;
    font-size: 12px;
    background-color: #37414d;
    color: #333;
    line-height: 60px;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;

    .header-left{
      text-align: left;
      color: white;
      .logo-panel{
        width: fit-content;
        display: flex;
        align-items: center;
        span {
          font-size: 1.4rem;
          margin-left: .8rem;
        }
      }
    }
    .header-right{
      font-size: .8rem;
    }

  }

  .el-dropdown-menu{
    li{
      font-size: .8rem;
    }

  }

  .cps-menu-main {
    .cps-menu {
      background-color: #545c64;
    }
    .cps-main {
      font-size: 14px;
      height: calc(100vh - 60px);
      overflow: hidden;
      padding: 10px 0 0 0;
      /deep/.cps-main-page {
        .el-breadcrumb {
          margin-left: 20px;

          span {
            font-size: 13px;
          }
        }

        .el-divider--horizontal {
          margin: 14px 0;
        }

        .page-content{
          margin: 15px 0 0 15px;

          .el-row{
            .el-input {
              width: 130px;
              margin-right: 20px;
              input{
                height: 32px;
                font-size: 13px;
              }
              .el-input__inner {
                border-radius: 2px !important;
                border: 1px solid #afafb2;
              }
            }
            .el-range-editor{
              margin-right: 20px;
              border-radius: 2px !important;
              border: 1px solid #afafb2;
            }
          }

          .cps-table-data {
            margin-top: 10px;
            thead {
              color: #2b2929;
              tr{
                th{
                  background-color: #f5f5f5;
                }
              }
            }

            td {
              font-weight: 500;
              .cell{
                button{
                  padding: 0;
                }
              }
            }
          }

          .fixed-pagination {
            position: fixed;
            bottom: 10px;
          }
        }

      }
    }
  }

  /deep/.is-active {
    background-color: rgb(67,74,80) !important;
  }

  .el-menu{
    border-right: none !important;
  }

  .el-aside {
    color: #333;
  }
</style>

<style lang="scss" scoped>


</style>

