<template>
    <div class="cps-main-page">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>管理员管理</el-breadcrumb-item>
        <el-breadcrumb-item>新增管理员</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider></el-divider>
        <div class="main-border">
          <el-form ref="form" :model="form" :rules="rules" label-width="120px">
            <el-form-item label="管理员账号" prop="adminName">
              <el-input v-model="form.adminName" size="small" placeholder="3-20位(英文、数字、下划线)"></el-input>
            </el-form-item>
            <el-form-item label="管理员密码" prop="adminPwd">
              <el-input v-model="form.adminPwd" size="small" placeholder="6-20位"></el-input>
            </el-form-item>
            <el-form-item label="会长级别" prop="adminLevel">
              <el-select v-model="form.adminLevel" placeholder="会长级别" size="small">
                <el-option label="一级" value=1 v-if="adminLevel === 1"></el-option>
                <el-option label="二级" value=2 v-if="adminLevel <= 2"></el-option>
                <el-option label="三级" value=3 v-if="adminLevel <= 3"></el-option>
                <el-option label="四级" value=4 v-if="adminLevel <= 4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
              <el-button @click="back">返回</el-button>
            </el-form-item>
          </el-form>
        </div>
    </div>
</template>

<script>
  import { addAdmin,checkAdminName } from '@/api/request'
  import crypto from '@/utils/crypto.js';
  export default {
    name:'gameAdd',
    data() {
      let validateAdminName = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入管理员账号'));
        }
        else {
          let param = {'adminName': value};
          checkAdminName(param).then(res => {
            if (!res.data.success) {
              callback(new Error(res.data.error.message));
            }
            else {
              callback();
            }
          });
        }
      };
      return {
        form: {
          adminName: '',
          adminPwd: '',
          adminLevel: '',
        },
        rules: {
          adminName: [
            { required: true, message: '请输入管理员账号'},
            { pattern: /^\w{3,20}$/, trigger: 'blur', message: '管理员账号格式错误(3-20位英文、数字、下划线)'},
            { validator: validateAdminName, trigger: 'blur' }
          ],
          adminPwd: [
            { required: true, message: '请输入管理员密码'},
            { pattern: /^.{6,20}$/, trigger: 'blur', message: '管理员密码格式错误(6-20位)'},
          ],
          adminLevel: [
            { required: true, message: '请选择会长级别'}
          ],
        },
        adminLevel: 9,
      }
    },
    mounted() {
      let user = JSON.parse(localStorage.getItem("userInfo"));
      this.adminLevel = user.adminLevel;
    },
    methods: {
      onSubmit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            let params = {
              admin_name: this.form.adminName,
              admin_pwd: crypto.encrypt(this.form.adminPwd),
              admin_level: this.form.adminLevel,
            }
            addAdmin(params).then(res => {
              if (res.data.success){
                this.$message.success('管理员新增成功');
                this.$refs['form'].resetFields();
              }
              else {
                this.$message.error(res.data.error.message);
              }
            });
          } else {
            return false;
          }
        });
      },
      back() {
        this.$router.push({path: '/adminList'});
      }
    }
  }
</script>

<style scoped>
  .el-input {
    width: 201px !important;
  }
  /deep/.el-input__inner {
    border-radius: 2px !important;
    border: 1px solid #aaaaac !important;
  }
  .el-button{
    font-weight: 700;
    width: 80px;
  }
  /deep/.el-form-item__label{
    font-weight: 600;
    font-size: 13px;
  }
</style>
