<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>充值</el-breadcrumb-item>
      <el-breadcrumb-item>抽奖次数</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-border">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px" @keyup.enter.native="onSubmit()">
        <el-form-item label="玩家id" prop="user_id">
          <el-input v-model="form.user_id" size="small" placeholder="请输入玩家id"></el-input>
        </el-form-item>
        <el-form-item label="玩家账号" prop="user_name">
          <el-input v-model="form.user_name" size="small" placeholder="请输入玩家账号"></el-input>
        </el-form-item>
        <el-form-item label="增加次数">
          <el-input v-model="form.num" size="small" placeholder="请输入次数"></el-input>
        </el-form-item>
        <el-form-item label="剩余次数">
          <el-input v-model="residueNum" size="small" placeholder="请先输入玩家信息" disabled></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" :loading="submitting">确认增加</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {editDrawNum, findUserById, findUserByUserName, getDrawNum} from "@/api/request";

export default {
  name: "drawAddNum",
  data() {
    let validateId = (rule, value, callback) => {
      findUserById(this.form.user_id).then(res => {
        if (!res.data.success) {
          this.form.user_name = '';
          callback(new Error(res.data.error.message));
        }
        else {
          let userName = res.data.data.user_name;
          if (userName !== this.form.user_name) {
            this.form.user_name = userName;
            this.getDrawNum();
          }
          callback();
        }
      });
      //}
    };
    let validateName = (rule, value, callback) => {
      findUserByUserName(this.form.user_name).then(res => {
        if (!res.data.success) {
          this.form.user_id = '';
          this.gameList = [];
          this.form.game_id = '';
          callback(new Error(res.data.error.message));
        } else {
          let userId = res.data.data.user_id;
          if (userId !== this.form.user_id) {
            this.form.user_id = userId;
            this.getDrawNum();
          }
          callback();
        }
      });
    };
    return {
      submitting: false,
      residueNum:'',
      form: {
        user_id:'',
        user_name:'',
        num: '',
        addUserId: ''
      },
      rules: {
        user_id: [
          {required: true, message: '请输入玩家id'},
          {pattern: /^\d+$/, trigger: 'blur', message: '玩家id格式不正确'},
          {validator: validateId, trigger: 'blur'}
        ],
        user_name: [
          {required: true, message: '请输入玩家帐号'},
          {validator: validateName, trigger: 'blur'}
        ],
      }
    }
  },
  mounted() {
    this.form.addUserId = JSON.parse(localStorage.getItem("userInfo")).adminId;
  },
  methods: {

    getDrawNum(){
      let param = {
        userId: this.form.user_id,
        userName: this.form.user_name,
        num:this.form.num,
      }
      getDrawNum(param).then(res => {
        if (res.data.success) {
           this.residueNum = res.data.data.num;
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },


    onSubmit() {
      let params = {
        userId : this.form.user_id,
        userName : this.form.user_name,
        num: this.form.num,
        addUserId: this.form.addUserId
      }

      this.$confirm('此操作将增加抽奖次数, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        editDrawNum(params).then(res => {
          if (res.data.success) {
            this.$message.success('增加次数成功！');
            this.form.num = 0;
            this.getDrawNum();
          } else {
            this.$message.error(res.data.error.message);
          }
          this.submitting = false;
        });


      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
        this.submitting = false;
      })
    }
  }
}
</script>

<style scoped>
/deep/ .el-input {
  width: 250px !important;
  min-width: 200px;
}

/deep/ .el-input__inner {
  border-radius: 2px !important;
  border: 1px solid #aaaaac !important;
}

/deep/ .el-form-item__label {
  font-weight: 600;
  font-size: 13px;
}

.main-button {
  font-weight: 700;
  width: 120px;
}

.el-button {
  font-weight: 700;
  width: 180px;
}
</style>