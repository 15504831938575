<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <!--<el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>-->
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>用户充值</el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <div class="main-border" v-show="showForm">
            <el-tabs v-model="activeName">
                <el-tab-pane label="支付宝" name="first">
                    <el-form :model="ruleForm" label-position="right" label-width="100px" status-icon :rules="rules" ref="ruleForm" :span="12"  class="demo-ruleForm">
                        <el-form-item label="玩家账号" prop="account">
                            <el-input type="text"  v-model="ruleForm.account" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="充值金额" prop="amount">
                            <el-input type="text"   v-model="ruleForm.amount"></el-input>
                        </el-form-item>
                        <el-form-item label="支付宝昵称" prop="nickName">
                            <el-input type="text" v-model="ruleForm.nickName"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')">确认充值</el-button>
                            <el-button @click="resetForm('ruleForm')">重置</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>

        <div class="bar-container" v-show="!showForm">
            <el-tabs v-model="activeName">
                <el-tab-pane label="支付宝" name="first">

                    <!--<el-image :src="alipay" style="width: 200px; height: 60px;display: block; margin-bottom: 20px"></el-image>-->

                    <div class="qrcode" ref="qrCodeUrl"></div>

                    <div class="qr-bottom">
                        付款金额
                        <span>￥{{preAmount}}</span><br>
                        <el-button plain icon="el-icon-arrow-left" style="margin-top: 20px" @click="back">已成功充值，返回</el-button>
                    </div>


                </el-tab-pane>
            </el-tabs>

        </div>

    </div>
</template>

<script>
  import { recharge,checkAccount } from '@/api/request'
  import QRCode from 'qrcodejs2'
  export default {
    name: 'recharge',
    data() {
      let validateAccount = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入玩家账号'));
        }
        else {
          let param = {'userAccount': value};
          checkAccount(param).then(res => {
            if (!res.data.success) {
              callback(new Error(res.data.error.message));
            }
            else {
              if (res.data.data) {
                if (this.ruleForm.nickName === '') {
                  this.ruleForm.nickName = res.data.data;
                }
              }
              callback();
            }
          });
        }
      };
      const validateMoney = (rule,value,callback) =>{
        if(!value){
          callback(new Error('请输入充值金额'))
        }else if(/^\./.test(value) || /\.$/.test(value)){
          callback(new Error('请输入正确格式的充值金额')) //防止输入多个小数点
        }
        else if(value.indexOf(".") !== -1 && value.split('.').length > 2){
          callback(new Error('请输入正确格式的充值金额')) //防止输入多个小数点
        }else if(value.indexOf(".") !== -1 && value.split('.')[1].length > 2){
          callback(new Error('请输入正确的小数位数')) //小数点后两位
        }else{
          callback();
        }
      };
      return {
        activeName: 'first',
        showForm: true,
        preAmount: '0',
        ruleForm: {
          amount: '',
          account: '',
          nickName: ''
        },
        rules: {
          account: [
            //{ pattern: /^\w{3,20}$/, trigger: 'blur', message: '请输入正确的玩家账号' },
            { required: true, message: '请输入玩家账号'},
            { validator: validateAccount, trigger: 'blur' }

          ],
          amount: [
            { required: true, message: '请输入充值金额'},
            { validator: validateMoney, trigger: 'blur' },
            { min: 1, max: 13, trigger: 'blur', message: '请输入正确的充值金额' }
          ],
          nickName: [
            { min: 1, max: 30, trigger: 'blur', message: '请输入正确的支付宝昵称' },
            { required: true, message: '请输入支付宝昵称'}
          ]
        }
      };
    },
    mounted() {

    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let params = {
                account: this.ruleForm.account,
                amount: this.ruleForm.amount,
                nickName: this.ruleForm.nickName
            }
            recharge(params).then(res => {
              if (res.data.success){
                this.creatQrCode(res.data.data.amount, res.data.data.receive);
                this.showForm = false;
              }
              else {
                this.$alert(res.data.error.message, '提示', {
                  confirmButtonText: '确定',
                  callback: action => {

                  }
                });
              }
            });


          } else {
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      creatQrCode(m, u) {
        let qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: 'alipays://platformapi/startapp?appId=20000123&actionType=scan&biz_data={"s": "money","u": "' + u + '","a": "' + m + '","m":""}', // 需要转换为二维码的内容
          width: 300,
          height: 300,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        });
        this.$refs.qrCodeUrl.title = "";
        this.preAmount = this.formatMoney(m,2);
      },
      back() {
        //this.showForm = true;
        window.location.reload();
      },
      formatMoney (number, decimals = 0, decPoint = '.', thousandsSep = ',')  {
        number = (number + '').replace(/[^0-9+-Ee.]/g, '')
        let n = !isFinite(+number) ? 0 : +number
        let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
        let sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
        let dec = (typeof decPoint === 'undefined') ? '.' : decPoint
        let s = ''
        let toFixedFix = function (n, prec) {
          let k = Math.pow(10, prec)
          return '' + Math.ceil(n * k) / k
        }
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
        let re = /(-?\d+)(\d{3})/
        while (re.test(s[0])) {
          s[0] = s[0].replace(re, '$1' + sep + '$2')
        }
        if ((s[1] || '').length < prec) {
          s[1] = s[1] || ''
          s[1] += new Array(prec - s[1].length + 1).join('0')
        }
        return s.join(dec)
      }
    }
  }
</script>

<style scoped lang="scss">
    .main-border{
        width: 100%;

        //border: 1px solid #eceaea;
        //padding: 20px 120px 20px 50px;
        //margin-left: 50px;
        //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .bar-container{
        width: 100%;
        //border: 1px solid #eceaea;
        //padding: 20px 120px 20px 50px;
       // margin-left: 50px;
        //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        .qrcode{
            margin-top: 30px;
        }
        .qr-bottom{
            width: 100%;
            text-align: left;
            margin-top: 30px;
            span{
                font-size: 30px;
                color: red;
                margin-left: 20px;
            }
        }
    }
    .el-form{
        max-width: 600px;
    }
</style>
