<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>

    <div class="page-content">
    <el-row>
      <el-button type="primary" @click="goAddPage" icon="el-icon-plus" size="small">新增</el-button>
    </el-row>
    <el-table class="cps-table-data"
            :data="tableData" size="small"
            border :height="height"
            style="width: 100%;">
      <el-table-column
              prop="group_id"
              label="ID"
              width="80">
      </el-table-column>
      <el-table-column
              prop="group_name"
              label="角色名"
              width="160">
      </el-table-column>
      <el-table-column
              prop="group_code"
              label="编号"
              width="100">
      </el-table-column>
      <el-table-column
              prop="remark"
              label="备注"
              width="240">
      </el-table-column>
      <el-table-column
              label="操作">
          <template slot-scope="scope">

            <router-link style="margin-right: 10px;" :to="{ name: 'AuthManage', params: scope.row}">
              <el-button type="text" size="small">授权</el-button>
            </router-link>

            <el-button v-if="scope.row.group_id !== 1" type="text" size="small"
                       @click="deleteGroup(scope.row.group_id, scope.row.group_name)">删除</el-button>
          </template>
      </el-table-column>
    </el-table>
    <el-pagination class="cps-table-data"
            @current-change="loadData"
            background
            :page-size="15"
            :current-page="currentIndex"
            layout="total, prev, pager, next"
            :total="total">
    </el-pagination>

    <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="30%">
    <span>确认删除角色[{{deleteName}}]吗?</span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="doDelete">确 定</el-button>
    </span>
    </el-dialog>
    </div>
  </div>
</template>
<script>
  import { getGroupList,deleteRole } from '@/api/request'
  export default {
    name: 'roleManage',
    data() {
      return {
        tableData: [],
        total: 0,
        currentIndex : 1,
        dialogVisible: false,
        selectedId: -1,
        deleteName: '',
        height: window.innerHeight - 210,
      }
    },
    mounted() {
      this.loadData(1);
    },
    methods: {
      loadData(index) {
        this.currentIndex = index;
        let params = {
          pageNo: index,
          pageSize: 10
        }
        getGroupList(params).then(res => {
          this.tableData = res.data.list;
          this.total = res.data.total;
        });
      },
      auth(row) {
        this.$router.push({ path: "/roleManage/authManage"});
      },
      goAddPage() {
        this.$router.push({ path: "/roleManage/roleAdd"});
      },
      deleteGroup(id, name) {
        this.selectedId = id;
        this.deleteName = name;
        this.dialogVisible = true;
      },
      doDelete() {
        deleteRole(this.selectedId).then(res => {
          this.dialogVisible = false;
          if (res.data.success) {
            this.selectedId = -1;
            this.deleteName = '';
            this.$message.success('角色删除成功');
            this.loadData(this.currentIndex);
          } else {
            this.$message.error(res.data.error.message);
          }
        });
      }
    },
  }
</script>


