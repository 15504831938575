<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
      <el-breadcrumb-item>游戏攻略列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="page-content">
      <el-row @keyup.enter.native="loadData(1)">
        <el-input v-model="queryField.guideId" size="small" placeholder="推广ID"></el-input>
        <el-input v-model="queryField.gameId" size="small" placeholder="游戏ID"></el-input>
        <el-select v-model="queryField.guidType" size="small" placeholder="攻略类型">
          <el-option
              v-for="item in optionsGuidType"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <!--        <el-select v-model="queryField.detailViewType" size="small" placeholder="详细页展示类型">-->
        <!--          <el-option-->
        <!--              v-for="item in optionsDetailViewType"-->
        <!--              :key="item.value"-->
        <!--              :label="item.label"-->
        <!--              :value="item.value">-->
        <!--          </el-option>-->
        <!--        </el-select>-->
        <el-button type="primary" icon="el-icon-search" size="small" @click="loadData(1)">查询
        </el-button>
        <!--        <el-button type="primary" icon="el-icon-plus" size="small" @click="toAdd">新增</el-button>-->
      </el-row>
      <el-table class="cps-table-data"
                :data="tableData" size="small"
                border
                :height="height"
                style="width: 100%">
        <el-table-column
            prop="guideId"
            label="攻略ID"
            width="60">
        </el-table-column>
        <el-table-column
            prop="gameId"
            label="游戏ID"
            width="60">
        </el-table-column>
        <el-table-column
            prop="gameName"
            label="游戏名称"
            width="140">
        </el-table-column>
        <el-table-column
            prop="guidName"
            label="攻略名称"
            width="180">
        </el-table-column>
        <el-table-column
            prop="guidType"
            label="攻略类型"
            :formatter="formatGuidType"
            width="100">
        </el-table-column>
        <el-table-column
            prop="guidUrl"
            label="攻略展示地址"
            width="250">
        </el-table-column>
        <el-table-column
            prop="imgUrl"
            label="攻略首页图"
            width="250">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <!--            <el-button type="text" size="small" @click="toEdit(scope.row.extendId)">编辑</el-button>-->
            <!--            <el-button type="text" size="small" @click="toUpload(scope.row.extendId)">视图上传</el-button>-->
            <!--            <el-button type="text" size="small" @click="toUploadIMG(scope.row.extendId)">视频图片上传-->
            <!--            </el-button>-->
            <!--            <el-button type="text" size="small" @click="deleteById(scope.row.extendId)">删除-->
            <!--            </el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="cps-table-data"
                     @current-change="loadData"
                     background
                     :page-size="10"
                     :current-page="currentIndex"
                     layout="total, prev, pager, next"
                     :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  deleteGameExtend,
  deleteGift,
  queryGameExtendList, queryGameGuideList,
  submitGameIco,
  upload,
  uploadIMG,
} from "@/api/request";

export default {
  name: 'GuideList',
  data() {
    return {
      isSupper: false,
      tableData: [],
      queryField: {
        guideId: '',
        gameId: '',
        guidType: ''
      },
      optionsGuidType: [
        {
          value: '',
          label: '攻略类型'
        }, {
          value: '1',
          label: '视频'
        }, {
          value: '2',
          label: '图片'
        },],
      total: 0,
      height: window.innerHeight - 210,
      currentIndex: 1,
    };
  },
  mounted() {
    this.loadData(1);
  },
  methods: {
    loadData(index) {
      let params = {
        pageNo: index,
        pageSize: 11,
        params: this.queryField,
      }
      queryGameGuideList(params).then(res => {
            this.tableData = res.data.list;
            this.total = res.data.total;
            this.currentIndex = index;
          }
      )
    },
    toAdd() {
      this.$router.replace("/game/extend/add")
    },
    toEdit(gameId) {
      this.$router.replace(`/game/extend/edit/${gameId}`)
    },
    formatGuidType(row, column, cellValue) {
      let ret = ''
      if (cellValue === 1) {
        ret = "视频"
      } else {
        ret = "图片"
      }
      return ret;
    },
  }
}
</script>
<style lang="scss" scoped>
.cps-main-page {
  .cps-table-data {
    td {
      .el-image {
        display: inherit;
      }
    }
  }
}
</style>

