<template>
    <div class="cps-main-page">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>订单查询</el-breadcrumb-item>
        <el-breadcrumb-item>订单详情</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider></el-divider>
        <div class="main-border">

          <el-form ref="form" :model="orderData"  label-width="100px">

            <div class="line-data">
                <el-form-item label="订单id" prop="">
                  <el-input v-model="orderData.order_id" readonly size="small"></el-input>
                </el-form-item>

                <el-form-item label="cp订单id" prop="">
                  <el-input v-model="orderData.cp_order_id" readonly size="small"></el-input>
                </el-form-item>
            </div>

            <div class="line-data">
              <el-form-item label="玩家id" prop="">
                <el-input v-model="orderData.user_id" readonly size="small"></el-input>
              </el-form-item>

              <el-form-item label="玩家账号" prop="">
                <el-input v-model="orderData.user_name" readonly size="small"></el-input>
              </el-form-item>
            </div>
            <div class="line-data">
              <el-form-item label="玩家昵称" prop="">
                <el-input v-model="orderData.nick_name" readonly size="small"></el-input>
              </el-form-item>

              <el-form-item label="游戏名称" prop="">
                <el-input v-model="orderData.game_name" readonly size="small"></el-input>
              </el-form-item>
            </div>
            <div class="line-data">
              <el-form-item label="下单角色id" prop="">
                <el-input v-model="orderData.order_role_code" readonly size="small"></el-input>
              </el-form-item>

              <el-form-item label="下单角色名" prop="">
                <el-input v-model="orderData.order_role_name" readonly size="small"></el-input>
              </el-form-item>
            </div>
            <div class="line-data">
              <el-form-item label="下单区服" prop="">
                <el-input v-model="orderData.order_service_id" readonly size="small"></el-input>
              </el-form-item>

              <el-form-item label="下单时间" prop="">
                <el-input v-model="orderData.order_time" readonly size="small"></el-input>
              </el-form-item>
            </div>
            <div class="line-data">
              <el-form-item label="订单金额" prop="">
                <el-input v-model="orderData.order_amount" readonly size="small"></el-input>
              </el-form-item>

              <el-form-item label="优惠金额" prop="">
                <el-input v-model="orderData.discount_amount" readonly size="small"></el-input>
              </el-form-item>
            </div>
            <div class="line-data">
              <el-form-item label="订单状态" prop="">
                <el-input v-model="orderData.order_status" readonly size="small"></el-input>
              </el-form-item>

              <el-form-item label="支付方式" prop="">
                <el-input v-model="orderData.pay_type" readonly size="small"></el-input>
              </el-form-item>
            </div>
            <div class="line-data">
              <el-form-item label="支付时间" prop="">
                <el-input v-model="orderData.pay_time" readonly size="small"></el-input>
              </el-form-item>
              <el-form-item label="通知时间" prop="">
                <el-input v-model="orderData.dispatch_time" readonly size="small"></el-input>
              </el-form-item>
            </div>
            <el-col :span="8">
              <el-button @click="back">返回</el-button>
            </el-col>
          </el-form>

        </div>
    </div>
</template>

<script>
  import {queryOrderDetail} from '@/api/request'

  export default {
    name:'orderDetail',
    data() {
      return {
        orderData: {
          order_id: '',
          cp_order_id: '',
          user_id: '',
          user_name: '',
          nick_name: '',
          game_name: '',
          order_status: '',
          order_time: '',
          channel_first: '',
          channel_second: '',
          dispatch_time: '',
          order_amount: '',
          discount_amount: '',
          order_role_code: '',
          order_role_name: '',
          order_service_id: '',
          pay_type: '',
          pay_status: '',
          pay_time: '',
        },
        orderId: 0,
        groupData: []
      }
    },
    mounted() {
      this.orderId = this.$route.params.pathMatch;
      queryOrderDetail(this.orderId).then(res => {
        this.orderData = res.data;
        this.orderData.order_status = this.formatOrderStatus(this.orderData.order_status);
        this.orderData.pay_type = this.formatPayType(this.orderData.pay_type);
      });
    },
    methods: {
      back() {
        this.$router.push({path: '/orderList'});
      },
      formatOrderStatus(v) {//（1：待支付 2：支付成功 3：通知成功）
        if (v === 1) return "待支付"
        if (v === 2) return "支付成功"
        if (v === 3) return "通知成功"
      },
      formatPayType(v) {//（1:平台币 2：支付宝 3：微信
        if (v === 1) return "平台币"
        if (v === 2) return "支付宝"
        if (v === 3) return "微信"
      },
    }
  }
</script>

<style scoped lang="scss">
  .main-border{
    width: 100%;
    font-size: 12px;
    padding: 0 10px;

    .line-data{
        display: flex;

      .el-form-item {
        margin-bottom: 3px !important;
      }

      .el-input {
        width: 300px !important;
      }

      /deep/.el-input__inner {
        border-radius: 2px !important;
        border: 1px solid #dcdcde !important;
      }

      /deep/ .el-form-item__label {
        font-weight: 600;
        font-size: 13px;
      }


    }

    .el-button {
      font-weight: 700;
      width: 100px;
      margin: 20px 100px;
    }

  }

</style>
