<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>提成管理</el-breadcrumb-item>
      <el-breadcrumb-item>我的收益记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>

    <div class="page-content">
    <el-row @keyup.enter.native="loadData(1)">
      <el-date-picker
              v-model="queryField.time"
              size="small"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
      </el-date-picker>

      <el-button type="primary" icon="el-icon-search" @click="loadData(1)" size="small">查询</el-button>
    </el-row>
    <el-table class="cps-table-data"
            :data="tableData" size="small"
            border :height="height"
            style="width: 100%">
      <el-table-column
              prop="log_id"
              label="ID"
              width="80">
      </el-table-column>
      <el-table-column
              prop="orderId"
              label="订单ID"
              width="180">
      </el-table-column>
      <el-table-column
              prop="shareAmount"
              label="收益"
              width="180">
      </el-table-column>
      <el-table-column
              label="收益时间"
              prop="addTime"
              width="200">
      </el-table-column>

    </el-table>
    <el-pagination class="cps-table-data"
            @current-change="loadData"
            background
            :page-size="15"
            :current-page="currentIndex"
            layout="total, prev, pager, next"
            :total="total">
    </el-pagination>
    </div>
  </div>
</template>
<script>
  import { queryMyShareList } from '@/api/request';
  export default {
    name: 'myShare',
    data() {
      return {
        tableData: [],
        queryField: {
          time: [],
          start_time: '',
          end_time: ''
        },
        total: 0,
        currentIndex:1,
        height: window.innerHeight - 210,
      }
    },
    mounted() {
      this.loadData(1);
    },
    methods: {
      loadData(index) {
        if (this.queryField.time != null && this.queryField.time.length > 0) {
          this.queryField.start_time = this.queryField.time[0] + " 00:00:00";
          this.queryField.end_time = this.queryField.time[1] + " 23:59:59";
        }
        else {
          this.queryField.start_time = "";
          this.queryField.end_time = "";
        }
        let params = {
          pageNo: index,
          pageSize: 15,
          params : this.queryField,
        }
        queryMyShareList(params).then(res => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.currentIndex=index
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ .is-active {
    background-color: unset !important;
  }
  .cps-table-data {
    /deep/ td {
      padding: 7.5px 0 !important;
    }
  }
</style>
