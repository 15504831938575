<template>
  <div class="mobile-main">

    <van-nav-bar
            title="给玩家充值"
            left-text="返回"
            left-arrow
            @click-left="back"
    />
    <div class="header-info">
      <p>充值信息</p>
    </div>

    <div class="form-panel">

      <van-form ref="form" validate-first @submit="onSubmit">
        <van-field
                v-model="form.user_id"
                required
                label="玩家ID"
                placeholder="请输入玩家ID"
                :rules="[{ required: true, message: '' },
                { pattern: /^\d+$/, message: '请填写正确的玩家ID' },
                { validator : validateId, message: '玩家ID不存在' }]"
        />
        <van-field
                v-model="form.user_name"
                required
                label="玩家账号"
                placeholder="请输入玩家账号"
                :rules="[{ required: true, message: '' },
                { validator : validateName, message: '玩家账号不存在' }]"
        />
        <van-field
                v-model="game_name"
                required
                right-icon="arrow"
                label="充值游戏"
                placeholder="请选择充值游戏"
                :rules="[{ required: true, message: ''}]"
                readonly
                @click="showPicker"
        />
        <van-popup v-model="show" position="bottom" >
          <van-picker
                  title="请选择充值游戏"
                  show-toolbar
                  :columns="gameNameList"
                  @confirm="onConfirm"
                  @cancel="show = false"
          />
        </van-popup>
        <van-field
                v-model="form.amount"
                type="number"
                required
                label="充值金额"
                placeholder="请输入充值金额"
                :rules="[{ required: true, message: '' },
                { pattern: /^-?\d+([.]\d{1,2})?$/, message: '充值金额格式错误' }]"
        />
        <van-field
                v-model="form.pay_pwd"
                type="password"
                required
                label="支付密码"
                placeholder="请输入支付密码"
                :rules="[{ required: true, message: '' }]"
        />

        <div style="margin: 16px;">
          <van-button block type="info" size="small" loading-text="充值中" :loading="submitting" native-type="submit">确认充值</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
  import { findUserById,findUserByUserName,userRecharge } from '@/api/request'
  import crypto from '@/utils/crypto.js';
  import {Dialog, Toast} from "vant";

  export default {
    name:'rechargetohz',
    data() {
      return {
        form: {
          user_id: '',
          user_name: '',
          game_id: '',
          amount: '',
          pay_pwd: '',
        },
        game_name: '',
        submitting: false,
        gameList: [],
        gameNameList: [],
        show: false,
      }
    },
    methods: {
      back() {
        this.$router.push({path: "/mobile/home"});
      },
      showPicker() {
        if (this.gameList.length === 0) {
          Toast('请先完善帐号信息');
          return;
        }
        this.show = true;
      },
      onConfirm(value, index) {
        this.game_name = value;
        this.form.game_id = this.gameList[index].game_id;
        this.show = false;
      },
      reset(){
        this.form.user_id = '';
        this.form.user_name = '';
        this.form.game_id = '';
        this.form.amount = '';
        this.form.pay_pwd = '';
        this.game_name = '';
      },
      validateId(value){
        return new Promise((resolve) => {
          findUserById(value).then(res => {
            if (!res.data.success) {
              this.form.user_name = '';
              this.gameList = [];
              this.gameNameList = [];
              this.form.game_id = '';
              resolve(false);
            }
            else {
              let userName = res.data.data.user_name;
              if (userName !== this.form.user_name) {
                this.form.user_name = userName;
                this.gameList = res.data.data.gameList;
                this.gameNameList = [];
                let me = this;
                this.gameList.forEach(function(e, i){
                  me.gameNameList.push(e.game_name);
                });
              }
              resolve(true);
            }
          });
        });
      },
      validateName(value){
        return new Promise((resolve) => {
          findUserByUserName(value).then(res => {
            if (!res.data.success) {
              this.form.user_id = '';
              this.gameList = [];
              this.form.game_id = '';
              resolve(false);
            } else {
              let userId = res.data.data.user_id;
              if (userId !== this.form.user_id) {
                this.form.user_id = userId;
                this.gameList = res.data.data.gameList;
              }
              resolve(true);
            }
          });
        });
      },
      onSubmit() {
        this.submitting = true;
        let params = {
          user_id: this.form.user_id,
          game_id: this.form.game_id,
          recharge_amount: this.form.amount,
          pay_pwd: crypto.encrypt(this.form.pay_pwd),
        }
        userRecharge(params).then(res => {
          if (res.data.success){
            Toast.success('充值成功！');
            this.reset();
          }
          else {
            Toast.fail(res.data.error.message);
          }
          this.submitting = false;
        });
      },

    }
  }
</script>

<style scoped lang="scss">
  .mobile-main{
    /deep/.van-field__label span{
      font-size: 13px;
    }

    height: 100vh;
    background-color: rgba(184, 191, 188, 0.09);
    .van-nav-bar{
      background-color: #37414d;
      /deep/.van-icon,/deep/.van-nav-bar__text,/deep/.van-nav-bar__title{
        color: white;
        font-size: 14px;
      }
    }
    .header-info{
      padding-left: 5%;
      font-weight: 600;
    }
    .form-panel{
      width: 90%;
      margin: 0 auto;
      background-color: white;
      text-align: center;
      padding-bottom: 15px;
      border-radius: 5px;
    }
  }
</style>
