<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>个人信息修改</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-border">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="form.nickname" size="small"></el-input>
        </el-form-item>
<!--        <el-form-item label="头像">-->
<!--          <el-input v-model="form.name" size="small"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="渠道URL">-->
<!--          <el-input v-model="form.name" size="small"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="免密额度">-->
<!--          <el-input v-model="form.name" size="small"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="支付宝账号" prop="zfbAccount">
          <el-input v-model="form.zfbAccount" size="small" :disabled="readonly"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
  import { updateZfb } from '@/api/request'

  export default {
    name: 'modifyUserInfo',
    data() {
      return {
        readonly:false,
        form: {
          nickname: '',
          zfbAccount: ''
        },
        rules: {
          nickname: [
            { required: true, message: '请输入昵称'},
            { pattern: /^.{1,30}$/, trigger: 'blur', message: '昵称格式错误(1-30位)'},
          ],
          zfbAccount: [
            { required: true, message: '请输入支付宝账号'},
            { pattern: /^.{5,50}$/, trigger: 'blur', message: '支付宝账号格式错误(5-50位)'},
          ],
        }
      }
    },
    mounted() {
      let user = JSON.parse(localStorage.getItem("userInfo"));
      this.form.nickname = user.nickName;
      this.form.zfbAccount = user.zfbAccount;
      if(this.form.zfbAccount!=''){
        this.readonly =true;
      }
    },
    methods: {
      onSubmit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            let params = {
              nick_name: this.form.nickname,
              zfb_account: this.form.zfbAccount,
            }
            updateZfb(params).then(res => {
              if (res.data.success){
                let user = JSON.parse(localStorage.getItem("userInfo"));
                user.nickName = this.form.nickname;
                user.zfbAccount = this.form.zfbAccount;
                localStorage.setItem("userInfo", JSON.stringify(user));
                this.$message.success('个人信息修改成功');
                window.location.reload();
              }
              else {
                this.$message.error(res.data.error.message);
              }
            });
          }
        });
      }
    }
  }
</script>

<style scoped lang="scss">
  .el-input {
    width: 250px !important;
    min-width: 200px;
  }
  /deep/.el-input__inner {
    border-radius: 2px !important;
    border: 1px solid #aaaaac !important;
  }
  /deep/.el-form-item__label{
    font-weight: 600;
    font-size: 13px;
  }
  .main-button{
    font-weight: 700;
    width: 120px;
  }
  .el-button {
    font-weight: 700;
    width: 100px;
  }
</style>
