<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
      <el-breadcrumb-item>游戏推广编辑</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-border">
      <el-form ref="form" :model="form" label-width="120px">
        <div class="form-border">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="推广ID">
                <el-input v-model="form.extendId" size="small" disabled></el-input>
              </el-form-item>
            </el-col>

            <el-form-item label="游戏ID">
              <el-col :span="8">
                <el-input v-model="form.gameId" size="small" disabled></el-input>
              </el-col>
            </el-form-item>

          </el-row>
        </div>

        <el-row :gutter="20">
          <div class="form-border">
            <el-col :span="8">
              <el-form-item label="游戏名字">
                <el-input v-model="form.gameName" size="small" disabled></el-input>
              </el-form-item>
            </el-col>
          </div>
          <el-form-item label="游戏标签">
            <el-col :span="2">
              <el-input v-model="tagList[0]" size="small"></el-input>
            </el-col>
            <el-col :span="2">
              <el-input v-model="tagList[1]" size="small"></el-input>
            </el-col>
            <el-col :span="2">
              <el-input v-model="tagList[2]" size="small"></el-input>
            </el-col>
          </el-form-item>
        </el-row>
        <div class="form-border">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="是否在广告位">
                <el-radio-group v-model="form.indexShow">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="详细页展示类型">
                <el-radio-group v-model="form.detailViewType">
                  <el-radio :label="1">图片</el-radio>
                  <el-radio :label="2">视频</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="视频图片">
                <el-input v-model="form.url" size="small" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="展示视图">
                <el-input v-model="form.viewImgUrl" size="small" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="14">
              <el-form-item label="游戏描述">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 4}"
                    placeholder="请输入内容"
                    v-model="form.gameDesc">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="16" align="center">
              <el-form-item>
                <el-button type="primary" @click="onSubmit">提交</el-button>
                <el-button @click="goBack">返回</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {queryGameExtend, updateGameExtend} from "@/api/request";

export default {
  name: 'ExtendEdit.vue',
  data() {
    return {
      form: {
        extendId: '',
        gameId: '',
        gameName: '',
        indexShow: '',
        detailViewType: '',
        url: '',
        viewImgUrl: '',
        tag: '',
        gameDesc: '',
      },
      tagList: '',
    }
  },
  mounted() {
    this.extendId = this.$route.params.pathMatch;
    this.loadData();
  },
  methods: {
    loadData() {
      let params = {
        extendId: this.extendId,
      }
      queryGameExtend(params).then(res => {
            this.form = res.data.data;
            this.tagList = this.form.tag.split(",");
          }
      )
    },

    onSubmit() {
      this.JoinTagList();
      let params = this.form;
      updateGameExtend(params).then(res => {
        if (res.data.success) {
          this.$message.success('游戏修改成功');
          this.$router.push({path: "/game/extend/list"});
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    goBack() {
      this.$router.push({path: "/game/extend/list"});
    },

    JoinTagList() {
      this.form.tag = '';
      for (let i = 0; i < this.tagList.length; i++) {
        if (i === 0) {
          this.form.tag = this.tagList[i];
        } else if (this.tagList[i] != '') {
          if (this.form.tag != '') {
            this.form.tag = this.form.tag + ',' + this.tagList[i];
          } else {
            this.form.tag = this.tagList[i];
          }
        }
      }
    }

  }
}
</script>

<style scoped lang="scss">
.main-border {
  width: 100%;
  padding: 0 12px;

}

.form-border {
  .el-input {
    width: 380px !important;
  }
}

.el-form-item {
}


.el-select {
  width: 300px;
}

/deep/ .el-input__inner {
  border-radius: 2px !important;
  border: 1px solid #aaaaac !important;
}

.el-button {
  font-weight: 700;
  width: 100px;
}

/deep/ .el-form-item__label {
  font-weight: 600;
  font-size: 13px;
}

</style>
