<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
      <el-breadcrumb-item>游戏新增</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-border">
      <el-form ref="form" :model="form" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="游戏名称">
              <el-input v-model="form.game_name" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="游戏简称">
              <el-input v-model="form.game_short_name" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="游戏厂商">
              <el-input v-model="form.game_of" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="游戏类型">
              <el-select v-model="form.game_type" placeholder="请选择游戏类型" size="small">
                <el-option label="H5" value="1"></el-option>
                <el-option label="手游" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="支付类型">
              <el-select v-model="form.game_pay_type" placeholder="请选择支付类型" size="small">
                <el-option label="H5" value="1"></el-option>
                <el-option label="APP" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="游戏屏幕">
              <el-select v-model="form.game_screen" placeholder="请选择游戏屏幕" size="small">
                <el-option label="竖屏" value="1"></el-option>
                <el-option label="横屏" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="游戏适配系统">
              <el-select v-model="form.game_system" placeholder="请选择游戏适配系统" size="small">
                <el-option label="安卓" value="1"></el-option>
                <el-option label="苹果" value="2"></el-option>
                <el-option label="全部" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="游戏状态">
              <el-select v-model="form.game_status" placeholder="请选择游戏状态" size="small">
                <el-option label="调试" value="1"></el-option>
                <el-option label="上线" value="2"></el-option>
                <el-option label="下线" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="支付宝支付方式">
              <el-select v-model="form.ali_channel" placeholder="请选择支付方式" size="small">
                <el-option label="官方" value="1"></el-option>
                <el-option label="杉德" value="2"></el-option>
                <el-option label="快接" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="微信支付方式">
              <el-select v-model="form.wx_channel" placeholder="请选择支付方式" size="small">
                <el-option label="官方" value="1"></el-option>
                <el-option label="杉德" value="2"></el-option>
                <el-option label="快接" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="游戏等级">
              <el-select v-model="form.game_level" placeholder="请选择游戏等级" size="small">
                <el-option label="S级" value="1"></el-option>
                <el-option label="A级" value="2"></el-option>
                <el-option label="B级" value="3"></el-option>
                <el-option label="C级" value="4"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="游戏类别">
              <el-radio v-model="form.game_topic" label="1">卡牌</el-radio>
              <el-radio v-model="form.game_topic" label="2">传奇</el-radio>
              <el-radio v-model="form.game_topic" label="3">武侠</el-radio>
              <el-radio v-model="form.game_topic" label="4">角色</el-radio>
              <el-radio v-model="form.game_topic" label="5">养成</el-radio>
              <el-radio v-model="form.game_topic" label="6">三国</el-radio>
              <el-radio v-model="form.game_topic" label="7">奇迹</el-radio>
              <el-radio v-model="form.game_topic" label="8">BT</el-radio>
              <el-radio v-model="form.game_topic" label="9">休闲</el-radio>
              <el-radio v-model="form.game_topic" label="10">放置</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="16" align="center">
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
              <el-button @click="goBack">返回</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

    </div>

  </div>
</template>

<script>
import {addGame} from "@/api/request";

export default {
  name: 'gameAdd',
  data() {
    return {
      form: {
        game_name: '',
        game_short_name: '',
        game_of: '',
        game_type: '',
        game_topic: '',
        game_pay_type: '',
        game_status: '',
        game_system: '',
        game_level: '',
        ali_channel: '',
        wx_channel: '',
      }
    }
  },
  methods: {
    onSubmit() {
      let params = {
        game_name: this.form.game_name,
        game_short_name: this.form.game_short_name,
        game_of: this.form.game_of,
        game_type: this.form.game_type,
        game_topic: this.form.game_topic,
        game_pay_type: this.form.game_pay_type,
        game_status: this.form.game_status,
        game_level: this.form.game_level,
        ali_channel: this.form.ali_channel,
        wx_channel: this.form.wx_channel,
        game_system: this.form.game_system,
      }
      addGame(params).then(res => {
        if (res.data.success) {
          this.$message.success('游戏新增成功');
          this.$router.push({path: "/gameList"});
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    goBack() {
      this.$router.push({path: "/gameList"});
    },
  }
}
</script>

<style scoped lang="scss">
.main-border {
  width: 100%;
  padding: 0 12px;
}

.el-form-item {
}

.el-input {
  width: 300px !important;
}

.el-select {
  width: 300px;
}

/deep/ .el-input__inner {
  border-radius: 2px !important;
  border: 1px solid #aaaaac !important;
}

.el-button {
  font-weight: 700;
  width: 100px;
}

/deep/ .el-form-item__label {
  font-weight: 600;
  font-size: 13px;
}

</style>
