<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
      <el-breadcrumb-item>游戏编辑</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="first">
      <div class="main-border">
        <el-form ref="form" :model="form" label-width="120px" >
          <el-row :gutter="30">
            <el-col :span="8">
              <el-form-item label="游戏id">
                <el-input v-model="form.game_id" size="small" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏code">
                <el-input v-model="form.game_code" size="small" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <div class="input-null">
                <el-form-item label="游戏标签" >
                  <el-col :span="5">
                    <el-input v-model="tagList[0]" size="small"></el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-input v-model="tagList[1]" size="small"></el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-input v-model="tagList[2]" size="small"></el-input>
                  </el-col>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="8">
              <el-form-item label="游戏名称">
                <el-input v-model="form.game_name" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏简称">
                <el-input v-model="form.game_short_name" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏描述">
                <el-input
                    style="width: 300px"
                    type="textarea"
                    :autosize="{ minRows: 1, maxRows: 1}"
                    placeholder="请输入内容"
                    v-model="form.game_desc">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="游戏厂商">
                <el-input v-model="form.game_of" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏类型">
                <el-select v-model="form.game_type" placeholder="请选择游戏类型" size="small">
                  <el-option label="H5" value="1"></el-option>
                  <el-option label="手游" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏福利">
                <el-input
                    style="width: 300px"
                    type="textarea"
                    :autosize="{ minRows: 1, maxRows: 1}"
                    placeholder="请输入内容"
                    v-model="form.game_weal">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="支付类型">
                <el-select v-model="form.game_pay_type" placeholder="请选择支付类型" size="small">
                  <el-option label="H5" value="1"></el-option>
                  <el-option label="APP" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏屏幕">
                <el-select v-model="form.game_screen" placeholder="请选择游戏屏幕" size="small">
                  <el-option label="竖屏" value="1"></el-option>
                  <el-option label="横屏" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="游戏适配系统">
                <el-select v-model="form.game_system" placeholder="请选择游戏适配系统" size="small">
                  <el-option label="安卓" value="1"></el-option>
                  <el-option label="苹果" value="2"></el-option>
                  <el-option label="全部" value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏状态">
                <el-select v-model="form.game_status" placeholder="请选择游戏状态" size="small">
                  <el-option label="调试" value="1"></el-option>
                  <el-option label="上线" value="2"></el-option>
                  <el-option label="下线" value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="支付宝支付方式">
                <el-select v-model="form.ali_channel" placeholder="请选择支付方式" size="small">
                  <el-option label="官方" value="1"></el-option>
                  <el-option label="杉德" value="2"></el-option>
                  <el-option label="快接" value="3"></el-option>
                  <el-option label="掌宜付" value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="微信支付方式">
                <el-select v-model="form.wx_channel" placeholder="请选择支付方式" size="small">
                  <el-option label="官方" value="1"></el-option>
                  <el-option label="杉德" value="2"></el-option>
                  <el-option label="快接" value="3"></el-option>
                  <el-option label="掌宜付" value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="游戏等级">
                <el-select v-model="form.game_level" placeholder="请选择游戏等级" size="small">
                  <el-option label="S级" value="1"></el-option>
                  <el-option label="A级" value="2"></el-option>
                  <el-option label="B级" value="3"></el-option>
                  <el-option label="C级" value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏类别">
                <el-radio v-model="form.game_topic" label="1">卡牌</el-radio>
                <el-radio v-model="form.game_topic" label="2">传奇</el-radio>
                <el-radio v-model="form.game_topic" label="3">武侠</el-radio>
                <el-radio v-model="form.game_topic" label="4">角色</el-radio>
                <el-radio v-model="form.game_topic" label="5">养成</el-radio>
                <el-radio v-model="form.game_topic" label="6">三国</el-radio>
                <el-radio v-model="form.game_topic" label="7">奇迹</el-radio>
                <el-radio v-model="form.game_topic" label="8">BT</el-radio>
                <el-radio v-model="form.game_topic" label="9">休闲</el-radio>
                <el-radio v-model="form.game_topic" label="10">放置</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="游戏通用key">
                <el-input v-model="form.game_login_key" size="small" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏支付key">
                <el-input v-model="form.game_pay_key" size="small" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="游戏登陆地址">
                <el-input v-model="form.game_login_url" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏支付地址">
                <el-input v-model="form.game_pay_url" size="small"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="是否开启自助">
                <el-select v-model="form.auto_flag" placeholder="自助开关" size="small">
                  <el-option label="禁止" value="1"></el-option>
                  <el-option label="开启" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="接入公司">
                <el-select v-model="form.check_type" placeholder="接入公司" size="small">
                  <el-option label="成都玉瑾" value="1"></el-option>
                  <el-option label="长沙七里" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="苹果签名方式">
                <el-select v-model="form.ios_sign_type" placeholder="苹果签名方式" size="small">
                  <el-option label="超级签" value="1"></el-option>
                  <el-option label="企业签" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否隐藏">
                <el-select v-model="form.show_flag" placeholder="是否隐藏" size="small">
                  <el-option label="否" value="1"></el-option>
                  <el-option label="是" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="排序值">
                <el-input v-model="form.sort_num" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="推荐类型" size="small">
                  <el-select v-model="form.index_type">
                    <el-option label="无推荐" :value="0"></el-option>
                    <el-option label="热门游戏" :value="1"></el-option>
                    <el-option label="今日首发" :value="2"></el-option>
                  </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="16" align="center">
              <el-form-item>
                <el-button type="primary" @click="onSubmit">提交</el-button>
                <el-button @click="goBack">返回</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="second">

    </div>
  </div>
</template>

<script>
import {editGame, getGame} from "@/api/request";

export default {
  name: 'gameAdd',
  data() {

    return {
      tagList: [],
      form: {
        game_id: '',
        game_name: '',
        game_of: '',
        game_type: '',
        game_topic: '',
        game_pay_type: '',
        game_status: '',
        game_login_url: '',
        game_pay_url: '',
        game_login_key: '',
        game_pay_key: '',
        game_screen: '',
        game_system: '',
        game_level: '',
        ali_channel: '',
        wx_channel: '',
        ios_sign_type: '',
        show_flag: '',
        sort_num: '',
        auto_flag: '',
        check_type: '',
        game_code: '',
        tag: '',
        game_desc: '',
        index_type: '',
        game_weal: '',
      },
    }
  },
  mounted() {
    this.gameId = this.$route.params.pathMatch;
    getGame(this.gameId).then(res => {
      this.form = res.data.data;
      if (this.form.tag != null) {
        this.tagList = this.form.tag.split(",");
      }
      this.form.game_status = res.data.data.game_status.toString();
      this.form.game_type = res.data.data.game_type.toString();
      this.form.game_topic = res.data.data.game_topic.toString();
      this.form.game_pay_type = res.data.data.game_pay_type.toString();
      this.form.game_screen = res.data.data.game_screen.toString();
      this.form.game_system = res.data.data.game_system.toString();
      this.form.game_level = res.data.data.game_level.toString();
      this.form.ali_channel = res.data.data.ali_channel.toString();
      this.form.wx_channel = res.data.data.wx_channel.toString();
      this.form.auto_flag = res.data.data.auto_flag.toString();
      this.form.show_flag = res.data.data.show_flag.toString();
      this.form.ios_sign_type = res.data.data.ios_sign_type.toString();
      this.form.check_type = res.data.data.check_type.toString();
      // this.orderData.order_status = this.formatOrderStatus(this.orderData.order_status);
      // this.orderData.pay_type = this.formatPayType(this.orderData.pay_type);
    });
  },
  methods: {
    onSubmit() {
      this.JoinTagList();
      let params = this.form;
      console.log(params);
      editGame(params).then(res => {
        if (res.data.success) {
          this.$message.success('游戏修改成功');
          this.$router.push({path: "/gameList"});
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    goBack() {
      this.$router.push({path: "/gameList"});
    },
    JoinTagList() {
      this.form.tag = '';
      for (let i = 0; i < this.tagList.length; i++) {
        if (i === 0) {
          this.form.tag = this.tagList[i];
        } else if (this.tagList[i] != '') {
          if (this.form.tag != '') {
            this.form.tag = this.form.tag + ',' + this.tagList[i];
          } else {
            this.form.tag = this.tagList[i];
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main-border {
  width: 100%;
  padding: 0 12px;
}

.el-form-item {
}

.el-input {
  width: 300px !important;
}

.input-null {
  .el-input {
    width: 80px !important;
  }
}

.el-select {
  width: 300px;
}

/deep/ .el-input__inner {
  border-radius: 2px !important;
  border: 1px solid #aaaaac !important;
}

.el-button {
  font-weight: 700;
  width: 100px;
}

/deep/ .el-form-item__label {
  font-weight: 600;
  font-size: 13px;
}


</style>
