<template>
  <div class="cps-main-page">
    <el-breadcrumb separator=">">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>数据统计</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="page-content">
      <el-row :gutter="30">
        <el-col :lg="6" :sm="12" v-for="(grid,i) in grids" :key="grid.name">
          <div
              class="dashboard-statistic"
              :class="grid.backgroundColor"
              @click="$emit('onChangeType', grid.type)"
          >
            <div class="grid-left">
              <el-image :src="grid.icon" style="width: 50px; height: 50px"></el-image>
            </div>

            <div class="grid-right">
              <p class="grid-name">{{ grid.name }}</p>
              <span v-if="i === 1 || i === 2 || i === 5 || i === 6 ">￥</span>
              <countTo
                  class="grid-number"
                  :startVal="0"
                  :endVal="grid.number"
                  :duration="3000"
              ></countTo>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="page-content">
        <el-row>
          <el-select
              v-model="game"
              filterable
              remote
              reserve-keyword
              placeholder="游戏名称"
              @change="handleSelect"
              :remote-method="loadGame">
            <el-option
                v-for="item in restaurants1"
                :key="item.key"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-autocomplete
              class="inline-input"
              value-key="nickName"
              v-model="channel"
              :fetch-suggestions="querySearch2"
              placeholder="渠道"
              @select="handleSelect2"
          ></el-autocomplete>
          <el-date-picker
              size="small"
              v-model="queryField.start_time"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="开始日期">
          </el-date-picker>
          <el-date-picker
              size="small"
              v-model="queryField.end_time"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="结束日期">
          </el-date-picker>
          <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
          <el-button size="small" type="success" @click="exportExcel" :loading="loading" loading-text="下载中" >导出</el-button>
        </el-row>


        <el-table class="cps-table-data"
                  :data="tableData" size="small"
                  border
                  :height="height"
                  style="width: 100%">
          <el-table-column
              prop="gameName"
              label="游戏"
              width="120">
          </el-table-column>
          <el-table-column
              prop="adminName"
              label="渠道"
              width="140">
          </el-table-column>
          <el-table-column
              prop="nickName"
              label="昵称"
              width="120">
          </el-table-column>
          <el-table-column
              prop="registerCount"
              label="新增用户"
              width="80">
          </el-table-column>
          <el-table-column
              prop="loginCount"
              label="活跃用户"
              width="80">
          </el-table-column>
          <el-table-column
              prop="rechargeCount"
              label="付费用户"
              width="230">
          </el-table-column>
          <el-table-column
              prop="newRechargeCount"
              label="新增付费用户"
              width="120">
          </el-table-column>
          <el-table-column
              prop="rechargeAmount"
              label="付费金额"
              width="120">
          </el-table-column>
          <el-table-column
              prop="newRechargeAmount"
              label="新增付费金额"
              width="120">
          </el-table-column>
          <el-table-column
              prop="rechargerPercent"
              label="总付费率"
              width="120">
          </el-table-column>
          <el-table-column
              prop="newRechargerPercent"
              label="新增付费率"
              width="160">
          </el-table-column>
          <el-table-column
              prop="arpu"
              label="ARPU"
              width="160">
          </el-table-column>
          <el-table-column
              prop="newARPU"
              label="新增ARPU"
              width="160">
          </el-table-column>
          <el-table-column
              prop="arppu"
              label="ARPPU"
              width="160">
          </el-table-column>
          <el-table-column
              prop="newARPPU"
              label="新增ARPPU"
              width="160">
          </el-table-column>
        </el-table>
        <el-pagination class="cps-table-data"
                       @current-change="loadData"
                       background
                       :page-size="15"
                       :current-page="currentIndex"
                       layout="total, prev, pager, next"
                       :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>

import {
  adminIndexCountForSuper,
  gameChannelDropDown,
  channelStatistics,
  loadGame,
  exportStatistics
} from '@/api/request'

import countTo from 'vue-count-to'
import userCountImg from '@/assets/userCount.png'
import zlsImg from '@/assets/zls.png'
import todayRegisiterImg from '@/assets/todayRegisiter.png'
import todayActiveImg from '@/assets/todayActive.png'
import todayRechargeImg from '@/assets/todayRecharge.png'
import monthLsImg from '@/assets/monthLs.png'


export default {
  name: 'statistics',
  components: {countTo},
  data() {
    return {
      restaurants1: [],
      restaurants2: [],
      tableData: [],
      channel: '',
      game: '',
      currentIndex: 1,
      loading: false,
      queryField: {
        admin_id: '',
        game_id: '',
        start_time: '',
        end_time: '',

      },
      total: 0,
      height: window.innerHeight - 500,
      userCountImg,
      grids: [
        {
          type: '',
          icon: userCountImg,
          name: '总用户数',
          number: 0,
          backgroundColor: 'grid--red'
        },
        {
          type: '',
          icon: todayActiveImg,
          name: '今日活跃',
          number: 0,
          backgroundColor: 'grid--blue'
        },
        {
          type: '',
          icon: todayRegisiterImg,
          name: '昨日注册',
          number: 0,
          backgroundColor: 'grid--green'
        },
        {
          type: '',
          icon: monthLsImg,
          name: '本月充值',
          number: 0,
          backgroundColor: 'grid--yellow'
        },
        {
          type: '',
          icon: zlsImg,
          name: '总流水',
          number: 0,
          backgroundColor: 'grid--gr'
        },
        {
          type: '',
          icon: todayRechargeImg,
          name: '今日充值',
          number: 0,
          backgroundColor: 'grid--red_yell'
        },
        {
          type: '',
          icon: todayRegisiterImg,
          name: '今日注册',
          number: 0,
          backgroundColor: 'grid--zi'
        }
      ],
    }
  },
  created() {
    this.getGridData()
  },
  mounted() {
    this.loadData(1);
    this.loadAllChannel();
  },
  methods: {
    loadData(index) {
      if (this.queryField.start_time) {
        this.queryField.start_time += " 00:00:00";
      } else {
        this.queryField.start_time = "";
      }

      if (this.queryField.end_time) {
        this.queryField.end_time += " 23:59:59";
      } else {
        this.queryField.end_time = "";
      }
      let params = {
        pageNo: index,
        pageSize: 15,
        params: this.queryField,
      }
      channelStatistics(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentIndex = index;
      });
    },
    exportExcel(){
      if (this.queryField.start_time) {
        this.queryField.start_time += " 00:00:00";
      } else {
        this.queryField.start_time = "";
      }

      if (this.queryField.end_time) {
        this.queryField.end_time += " 23:59:59";
      } else {
        this.queryField.end_time = "";
      }
      let paramsExport = {
        pageNo: 1,
        pageSize: 1000,
        params: this.queryField,
      }
      //获取excel表内容
      exportStatistics(paramsExport).then(res => {
        //输出excel文件
        this.downloadGO(res.data)
      });
    },

    downloadGO (data) {
      if (!data) {
        return
      }
      let url = window.URL.createObjectURL(new Blob([data], {type: "application/vnd.ms-excel"}))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download',"数据导出.xls")
      document.body.appendChild(link)
      link.click()
      this.loading=false;
    },
    getGridData() {
      adminIndexCountForSuper().then(res => {
        if (res.data.success) {
          const data = [];
          data[0] = res.data.data.userTotalCount;
          data[1] = res.data.data.todayLoginCount;
          data[2] = res.data.data.preRegisterCount;
          data[3] = res.data.data.monthRecharge;
          data[4] = res.data.data.totalRecharge;
          data[5] = res.data.data.todayRecharge;
          data[6] = res.data.data.todayRegisterCount;
          this.grids.forEach((item, index) => {
            item.number = Object.values(data)[index]
          })
        } else {
          this.$alert(res.data.error.message, '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
        }
      });
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.nickName.indexOf(queryString.toLowerCase()) === 0)
      }
    },
    loadAllChannel() {
      let me = this
      gameChannelDropDown(this.game).then(res => {
        me.restaurants2 = []
        let item1 = {}
        item1.value = ''
        item1.nickName = '展开'
        me.restaurants2.push(item1)
        // let item2 = {}
        // item2.value = '-1'
        // item2.nickName = '总计'
        // me.restaurants2.push(item2)
        res.data.data.forEach(function (e) {
          if (!e.nick_name) {
            e.nick_name = e.admin_id
          }
          let item = {}
          item.value = e.admin_id
          item.nickName = e.nick_name
          me.restaurants2.push(item)
        })
      })
    },
    loadGame(query) {
      if (query !== '') {
        let me = this;
        loadGame(query).then(res => {
          me.restaurants1 = [];
          let item1 = {};
          item1.value = '';
          item1.key = '';
          item1.label = '全部';
          me.restaurants1.push(item1)
          res.data.data.forEach(function (e) {
            let item = {}
            item.key = e.game_id
            item.value = e.game_id
            item.label = e.game_name
            me.restaurants1.push(item)
          })
        })
      }
    },
    querySearch2(queryString, cb) {
      let restaurants = this.restaurants2
      console.log(this.restaurants2);
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    handleSelect2(item) {
      this.queryField.admin_id = item.value
    },
    handleSelect(item) {
      this.queryField.game_id = item
    },

  }
}
</script>

<style lang="scss" scoped>

.cps-main-page {
  padding-right: 10px;

  /deep/ .el-dialog {
    max-width: 600px;

    .input-label {

    }

    .input-data {
      margin-top: 10px;
    }
  }
}

.dashboard-statistic {
  display: flex;
  position: relative;
  height: 108px;
  color: #fff;
  box-shadow: 0 0 10px rgba(100, 100, 100, 0.5);
  cursor: pointer;
  margin-bottom: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.95s;
    transition: width 0.5s;
  }

  &:hover::before {
    width: 100%;
    box-sizing: border-box;
  }

  .grid-left {
    width: 100px;
    line-height: 108px;
    vertical-align: center;
    text-align: center;
    /*实现垂直居中*/
    display: flex;
    align-items: center;
    justify-content: center;

    .grid-icon {
      font-size: 48px;
      vertical-align: middle;
    }
  }

  .grid-right {
    padding-left: 16px;

    p {
      color: #fff !important;
    }

    .grid-name {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .grid-number {
      font-size: 30px;
    }
  }
}

.grid {
  &--green {
    background-color: #06d6a0;
  }

  &--yellow {
    background-color: #ffd166;
  }

  &--blue {
    background-color: #06aed5;
  }

  &--red {
    background-color: #ef476f;
  }

  &--blue {
    background-color: #6464f0;
  }

  &--red_yell {
    background-color: #df673f;
  }

  &--zi {
    background-color: #d861f0;
  }

  &--gr {
    background-color: #349f3f;
  }
}
</style>
