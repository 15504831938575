<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>提成管理</el-breadcrumb-item>
      <el-breadcrumb-item>会长提成配置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>

    <div class="page-content">
    <el-row @keyup.enter.native="loadData(1)">
      <el-input v-model="queryField.admin_id" size="small" placeholder="管理员ID"></el-input>
      <el-input v-model="queryField.admin_name" size="small" placeholder="管理员用户名"></el-input>
      <el-select popper-class="cps-select" v-model="queryField.admin_level" size="small" placeholder="会长等级">
        <el-option key="" label="会长等级" value=""/>
        <el-option key="1" label="1级会长" value="1"/>
        <el-option key="2" label="2级会长" value="2"/>
        <el-option key="3" label="3级会长" value="3"/>
        <el-option key="4" label="4级会长" value="4"/>
      </el-select>
      <el-input v-model="queryField.game_name" size="small" placeholder="游戏名称"></el-input>
      <el-select popper-class="cps-select" v-model="queryField.game_type" size="small" placeholder="游戏类型">
        <el-option key="" label="游戏类型" value=""/>
        <el-option key="1" label="H5游戏" value="1"/>
        <el-option key="3" label="手游" value="2"/>
      </el-select>

      <el-select v-model="queryField.game_topic" size="small" placeholder="游戏类别">
        <el-option key="" label="游戏类别" value=""/>
        <el-option key="1" label="传奇" value="1"/>
        <el-option key="2" label="玄幻" value="2"/>
        <el-option key="4" label="三国" value="4"/>
        <el-option key="3" label="经营模拟" value="3"/>
        <el-option key="5" label="卡牌" value="5"/>
        <el-option key="6" label="奇迹" value="6"/>
        <el-option key="8" label="武侠" value="8"/>
        <el-option key="7" label="角色" value="7"/>
      </el-select>

      <!--<el-select popper-class="cps-select" v-model="queryField.gameType" size="small" placeholder="游戏等级">
        <el-option key="1" label="1级游戏" value="1"/>
        <el-option key="2" label="2级游戏" value="2"/>
        <el-option key="3" label="3级游戏" value="3"/>
        <el-option key="4" label="4级游戏" value="4"/>
      </el-select>
      <el-select popper-class="cps-select" v-model="queryField.gameType" size="small" placeholder="推广状态">
        <el-option key="1" label="常规" value="1"/>
        <el-option key="2" label="严控" value="2"/>
        <el-option key="3" label="禁止" value="3"/>
      </el-select>-->
      <el-button type="primary" @click="loadData(1)" icon="el-icon-search" size="small">查询</el-button>
    </el-row>
    <el-table class="cps-table-data"
            :data="tableData" size="small"
            border :height="height"
            style="width: 100%">
      <el-table-column
              prop="admin_id"
              label="管理员ID"
              width="80">
      </el-table-column>
      <el-table-column
              prop="admin_name"
              label="管理员用户名"
              width="190">
      </el-table-column>
      <!--<el-table-column
              prop="admin_level"
              label="会长等级"
              :formatter="levelFormat"
              width="80">
      </el-table-column>-->
      <el-table-column
              prop="parent_name"
              label="一级渠道"
              width="140">
      </el-table-column>
      <el-table-column
              prop="game_name"
              label="游戏名"
              width="120">
      </el-table-column>
      <!--<el-table-column
              prop="game_level"
              label="游戏等级"
              :formatter="levelFormat"
              width="80">
      </el-table-column>
      <el-table-column
              prop="push_status"
              label="推广状态"
              :formatter="pushStatusFormat"
              width="100">
      </el-table-column>-->
      <el-table-column
              label="注册开关"
              width="140">
        <template slot-scope="scope">
          <el-select :disabled="!isAdmin" v-model="tableData[scope.$index].regist_status" size="small" placeholder="注册开关">
            <el-option key="1" label="开启" :value="1"/>
            <el-option key="2" label="关闭" :value="2"/>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
              label="充值开关"
              width="140">
        <template slot-scope="scope">
          <el-select :disabled="!isAdmin" v-model="tableData[scope.$index].recharge_status" size="small" placeholder="充值开关">
            <el-option key="1" label="开启" :value="1"/>
            <el-option key="2" label="关闭" :value="2"/>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
              label="首充金额"
              width="130">
        <template slot-scope="scope">
          <el-input :disabled="!isAdmin" class="cps-line-input" v-model="tableData[scope.$index].first_money" size="small" placeholder="首充金额"></el-input>
        </template>
      </el-table-column>
      <el-table-column
              label="首充提成"
              width="130">
        <template slot-scope="scope">
          <el-input class="cps-line-input" v-model="tableData[scope.$index].first_share" size="small" placeholder="首充提成"></el-input>
        </template>
      </el-table-column>
      <el-table-column
              label="续充提成"
              width="130">
        <template slot-scope="scope">
          <el-input  class="cps-line-input" v-model="tableData[scope.$index].continue_share" size="small" placeholder="续充提成"></el-input>
        </template>
      </el-table-column>
      <el-table-column
              label="操作">
          <template slot-scope="scope">
            <!--<el-button type="primary" @click="save(scope.row)" size="small" icon="el-icon-check" circle></el-button>-->
            <el-button @click="save(scope.row)" type="text" size="small">保存</el-button>
          </template>
      </el-table-column>
    </el-table>
    <el-pagination class="cps-table-data"
            @current-change="loadData"
            background
            :page-size="15"
            :current-page="currentIndex"
            layout="total, prev, pager, next"
            :total="total">
    </el-pagination>
  </div>
  </div>
</template>
<script>
  import { queryAdminGameConfigList,updateAdminShare } from '@/api/request'
  export default {
    name: 'adminPercentage',
    data() {
      return {
        tableData: [],
        queryField: {
          admin_name: '',
          admin_id: '',
          admin_level: '',
          game_name: '',
          game_type: '',
          game_topic: '',
        },
        total: 0,
        currentIndex: 1,
        height: window.innerHeight - 210,
        isAdmin: false,
      }
    },
    mounted() {
      let user = JSON.parse(localStorage.getItem("userInfo"));
      if (user.groupId === '1') {
        this.isAdmin = true;
      }
      this.loadData(1);
    },
    methods: {
      loadData(index) {
        let params = {
          pageNo: index,
          pageSize: 15,
          params : this.queryField,
        }
        queryAdminGameConfigList(params).then(res => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.currentIndex = index;
        });
      },
      save(row) {
        const firstReg = /^\d+$/
        if (!firstReg.test(row.first_money + "")) {
          this.$message.error('首冲金额格式不正确');
          return ;
        }

        const reg = /^[01]([.][0-9]{0,4})?$/;
        if (!reg.test(row.first_share + "")) {
          this.$message.error('首冲提成格式不正确');
          return ;
        }

        if (!reg.test(row.continue_share + "")) {
          this.$message.error('续冲提成格式不正确');
          return ;
        }

        if (row.first_share < 0 || row.first_share > 1
                || row.continue_share < 0 || row.continue_share > 1) {
          this.$message.error('提成比例应设置在0~1.0之间');
          return ;
        }

        let params = {
          admin_id: row.admin_id,
          game_id: row.game_id,
          first_share: row.first_share,
          continue_share: row.continue_share,
          first_money: row.first_money,
          regist_status: row.regist_status,
          recharge_status: row.recharge_status,
        };
        updateAdminShare(params).then(res => {
          if (res.data.success) {
            this.$message.success('会长提成配置修改成功');
          } else {
            this.$message.error(res.data.error.message);
          }
        });

      },
      gameTopicFormat(row, column, cellValue) {//游戏类别 （1：卡牌2：传奇3：武侠4：角色）
        let ret = ''
        if (cellValue===1) {
          ret = "卡牌"
        } else if (cellValue===2){
          ret = "传奇"
        } else if (cellValue===3){
          ret = "武侠"
        } else if (cellValue===4){
          ret = "角色"
        }
        return ret;
      },
      gameTypeFormat(row, column, cellValue) {//游戏类型（1：H5 2：手游）
        let ret = ''
        if (cellValue===1) {
          ret = "H5"
        } else {
          ret = "手游"
        }
        return ret;
      },
      registStatusFormat(row, column, cellValue) {//注册状态（1：开启 2：禁止）
        let ret = ''
        if (cellValue===1) {
          ret = "开启"
        } else {
          ret = "禁止"
        }
        return ret;
      },
      pushStatusFormat(row, column, cellValue) {//推广状态（1：常规 2：严控 3：禁止）
        let ret = ''
        if (cellValue===1) {
          ret = "常规"
        } else if (cellValue===2) {
          ret = "严控"
        } else {
          ret = "禁止"
        }
        return ret;
      },
      levelFormat(row, column, cellValue) {//级别
        let ret = ''
        if (cellValue===1) {
          ret = "一级"
        } else if (cellValue===2){
          ret = "二级"
        } else if (cellValue===3){
          ret = "三级"
        } else if (cellValue===4){
          ret = "四级"
        }
        return ret;
      },
    },
  }
</script>

<style lang="scss" scoped>
  /deep/.cps-line-input{
    width: 100px !important;
    .el-input__inner {
      border-radius: 2px !important;
      border: 1px solid #bfc1c1;
    }
  }
  /deep/.cell{
    .el-select{
      width: 100px !important;
      .el-input{
        .el-input__inner {
          border-radius: 2px !important;
          border: 1px solid #bfc1c1;
        }
      }
    }
  }
  /deep/.el-select-dropdown__item{
    font-size: 12px;
  }
  .cps-select{
    font-size: 12px;
  }
</style>
