<template>
  <div class="all-panel">
    <van-image class="background-img" :src="backGroundImg" height="100%" fit="fill"></van-image>

    <div class="tagBall">
      <a class="tag" v-for="item in userList">{{item}}</a>
    </div>

    <div class="button-panel">
      <!--<van-image class="background-img" :src="drawGo" height="100%"></van-image>-->

      <van-button  type="info" size="small"  color="rgb(230,29,18)" @click="begin">GO</van-button>
    </div>

    <div class="popup-body" v-if="showResult">
      <div class="popup-title">
        <div><span>开奖结果</span></div>
      </div>
      <div class="popup-center">
        <!--<van-image :src="luckyUserHeadImgUrl" width="30px" height="30px" radius="30px"></van-image>-->
        <span>{{luckyUser}}</span>
      </div>

      <!--<div class="popup-total">
        本次活动参与人数 {{userCount}}
      </div>-->
      <div class="popup-tip">
        <span>请中奖的幸运用户联系客服领取大奖哦~</span>
        <van-button  type="info" size="small"  color="rgb(230,29,18)" @click="begin">再抽一次</van-button>
      </div>


    </div>
  </div>

</template>
<style scoped lang="scss">
  html,body{
    width: 100%;
    height: 100%;
  }
  .all-panel{
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    max-width: 500px;
    overflow: hidden;
    //background: url("../../assets/draw_background.jpg") no-repeat;
  }
  .background-img{
    position: absolute;
    z-index: -1;
  }
  .header-panel{
    height: 30px;
    background-color: #a7bae9;
  }
  .button-panel{
    width: 100%;
    margin-top: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 4rem;
    button{
      font-weight: 600;
      font-size: 18px;
      width: 70px;
      margin-left: calc(50% - 25px);
    }
  }
  .tagBall {
    width: 500px;
    height: 400px;
    margin-left: -75px;
    position: relative;
    margin-top: 20px;
  }

  .tag {
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    color: #f2f2f2;
    text-decoration: none;
    font-size: 15px;
    font-family: "微软雅黑";
    font-weight: bold;
  }

  .tag:hover {
    border: 1px solid #666;
  }

  .popup-body {
    padding: 1rem 1rem;
    background-color: white;
    border-radius: 15px;
    position: absolute;
    width: 90%;
    bottom: 5%;
    left: 5%;
    box-sizing: border-box;
    .popup-title {
      font-size: .8rem;
      text-align: center;

      div {
        display: inline-block;
        padding: 3px 15px;
        border: dashed 3px #ff6192;
        border-radius: 5px;
        background-color: #feffea;
        color: #ff6192;
        font-weight: 600;
      }
    }

    .popup-center {
      margin-top: .8rem;
      font-size: .7rem;
      display: flex;
      /*实现垂直居中*/
      align-items: center;
      text-align: center;
      justify-content: center;
      vertical-align: middle;
      height: 30px;

      span {
        font-weight: 700;
      }

      :nth-last-child(2) {
        color: #989898;
      }
    }

    .popup-barrage {
      margin-top: .5rem;
      padding: 5px 10px;
      font-size: .7rem;
      border: solid 1px #c9c5c5;
      border-radius: 25px;
      background-color: rgba(201, 199, 199, 0.2);
      opacity: .8;
    }

    .popup-total, .popup-tip {
      display: flex;
      margin-top: .5rem;
      font-size: .7rem;
      justify-content: center;
    }

    .popup-total {
      color: #a0552b;
    }

    .popup-tip {
      color: #e55825;
      flex-direction: column;
      align-items: center;
      button{
        margin-top: 20px;
      }
    }
  }
</style>
<script>
  import { getJoinDrawUserList, getLuckyUser } from '@/api/request'
  import backGroundImg from "@/assets/draw_1.jpg";
  import {Dialog, Toast} from "vant";
  export default {
    name: 'Select',
    data () {
      return {
        backGroundImg,
        userList: [],
        showResult: false,
        userCount: '99+',
        tagEle : null,
        paper : null,
        RADIUS : null,
        fallLength : null,
        tags : null,
        angleX : null,
        angleY : null,
        CX : null,
        CY : null,
        EX : null,
        EY : null,
        isBeginning: false,
        luckyUser: '未开奖',
        drawInterval: null,
      }
    },
    mounted () {
      this.getUserList();

    },
    methods: {
      begin() {
        if (this.isBeginning) {
          return;
        }
        this.showResult = false;
        this.isBeginning = true;
        this.animate();
        let me = this;
        setTimeout(function () {
          clearInterval(me.drawInterval);
          me.showResult = true;
          me.isBeginning = false;
        }, 3000);
        getLuckyUser().then(res => {
          if (res.data.success) {
            console.log(res.data);
            this.luckyUser = res.data.data;
          } else {
            Toast(res.data.error.message);
          }
        })
      },
      getUserList() {
        getJoinDrawUserList().then(res => {
          if (res.data.success) {
            console.log(res.data);
            this.userList = res.data.data;
            this.$nextTick(function () {
              this.initPage();
              this.innit();
            });

          } else {
            Toast(res.data.error.message);
          }
        });
      },
      initPage() {
        this.tagEle = 'querySelectorAll' in document ? document.querySelectorAll('.tag') : this.getClass('tag');
        this.paper = 'querySelectorAll' in document ? document.querySelector('.tagBall') : this.getClass('tagBall')[0];
        this.RADIUS = 180;
        this.fallLength = 500;
        this.tags = [];
        this.angleX = Math.PI / 50;
        this.angleY = Math.PI / 50;
        this.CX = this.paper.offsetWidth / 2;
        this.CY = this.paper.offsetHeight / 2;
        this.EX = this.paper.offsetLeft + document.body.scrollLeft + document.documentElement.scrollLeft;
        this.EY = this.paper.offsetTop + document.body.scrollTop + document.documentElement.scrollTop;

        if ('addEventListener' in window) {
          this.paper.addEventListener('mousemove', function (event) {
            let x = event.clientX - this.EX - this.CX
            let y = event.clientY - this.EY - this.CY
// angleY = -x* (Math.sqrt(Math.pow(x , 2) + Math.pow(y , 2)) > RADIUS/4 ? 0.0002 : 0.0001);
// angleX = -y* (Math.sqrt(Math.pow(x , 2) + Math.pow(y , 2)) > RADIUS/4 ? 0.0002 : 0.0001);
            this.angleY = x * 0.0001
            this.angleX = y * 0.0001
          })
        } else {
          this.paper.attachEvent('onmousemove', function (event) {
            let x = event.clientX - this.EX - this.CX
            let y = event.clientY - this.EY - this.CY
            this.angleY = x * 0.0001
            this.angleX = y * 0.0001
          })
        }
      },
      getClass (className) {
        let ele = document.getElementsByTagName('*')
        let classEle = []
        for (let i = 0; i < ele.length; i++) {
          let cn = ele[i].className
          if (cn === className) {
            classEle.push(ele[i])
          }
        }
        return classEle
      },

      innit () {
        for (let i = 0; i < this.tagEle.length; i++) {
          let k = (2 * (i + 1) - 1) / this.tagEle.length - 1
          let a = Math.acos(k)
          let b = a * Math.sqrt(this.tagEle.length * Math.PI)
// let a = Math.random()*2*Math.PI;
// let b = Math.random()*2*Math.PI;
          let x = this.RADIUS * Math.sin(a) * Math.cos(b)
          let y = this.RADIUS * Math.sin(a) * Math.sin(b)
          let z = this.RADIUS * Math.cos(a)
          let t = this.buildTag(this.tagEle[i], x, y, z);
          this.tagEle[i].style.color = 'rgb(' + parseInt(Math.random() * 255) + ',' + parseInt(Math.random() * 255) + ',' + parseInt(Math.random() * 255) + ')'
          this.tags.push(t)
          t.move()
        }
      },

      animate () {
        let me = this;
        this.drawInterval = setInterval(function () {
          me.rotateX()
          me.rotateY()
          me.tags.forEach(e => {
            e.move()
          })
        }, 17)
      },

      rotateX () {
        let cos = Math.cos(this.angleX)
        let sin = Math.sin(this.angleX)
        this.tags.forEach(e => {
          let y1 = e.y * cos - e.z * sin
          let z1 = e.z * cos + e.y * sin
          e.y = y1
          e.z = z1
        })

      },

      rotateY () {
        let cos = Math.cos(this.angleY)
        let sin = Math.sin(this.angleY)
        this.tags.forEach(e => {
          let x1 = e.x * cos - e.z * sin
          let z1 = e.z * cos + e.x * sin
          e.x = x1
          e.z = z1
        })
      },
      buildTag (ele, x, y, z) {
        let me = this;
        return {
          ele: ele,
          x: x,
          y: y,
          z: z,
          move: function () {
            let scale = me.fallLength / (me.fallLength - this.z)
            let alpha = (this.z + me.RADIUS) / (2 * me.RADIUS)
            this.ele.style.fontSize = 10 * scale + 'px'
            this.ele.style.opacity = alpha + 0.5
            this.ele.style.filter = 'alpha(opacity = ' + (alpha + 0.5) * 100 + ')'
            this.ele.style.zIndex = parseInt(scale * 100)
            this.ele.style.left = this.x + me.CX - this.ele.offsetWidth / 5 + 'px'
            this.ele.style.top = this.y + me.CY - this.ele.offsetHeight / 5 + 'px'
          },
        }
      }
    }
  }
</script>

