<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
      <el-breadcrumb-item>用户新增</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-border">
      <el-form ref="userAddForm" :model="userAddForm" :rules="userAddRules" label-width="100px">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="用户名" prop="userName">
              <el-input v-model="userAddForm.userName" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="2" >
            <el-form-item label="密码" prop="password">
              <el-input v-model="userAddForm.password" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model="userAddForm.phone" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="2" >
            <el-form-item label="昵称" prop="nickName">
              <el-input v-model="userAddForm.nickName" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="小号数量" prop="userCount">
              <el-input v-model="userAddForm.userCount" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="2">
            <el-form-item label="小号展示" >
              <div> 账号为主账号+_+序号 test_1 密码和主密码一致</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 80px">
          <el-col :span="8" :offset="4">
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
              <el-button @click="goBack">返回</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

  </div>
</template>

<script>
import {addMyUser} from '@/api/request'
export default {
  name: 'userAdd',
  data() {
    let validateUserCount = (rule, value, callback) => {
      if(value>10){
        callback(new Error('不能超过10个小号'));
      }else {
        callback();
      }

    };
    return {
      userAddForm:{
        userName: '',
        password: '',
        nickName: '',
        phone: '',
        userCount:0
      },
      userAddRules: {
        userName: [
          { required: true, message: '请输入用户名'},
          { pattern: /^\w{3,20}$/, trigger: 'blur', message: '管理员账号格式错误(3-20位英文、数字、下划线)'},
        ],
        password: [
          { required: true, message: '请输入密码'},
          { pattern: /^\w{6,20}$/, trigger: 'blur', message: '密码格式错误(6-20位英文、数字、下划线)'},
        ],
        nickName: [
          { required: true, message: '请输入昵称'},
          { pattern: /^.{1,10}$/, trigger: 'blur', message: '昵称格式错误(1-10位)'},

        ],
        userCount: [
          { required: true, message: '请输入小号个数'},
          { pattern: /[0-9]+/, trigger: 'blur', message: '请输入1至10的数字'},
          { validator: validateUserCount, trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push({path: "/userList"});
    },
    onSubmit() {
      this.$refs['userAddForm'].validate((valid) => {
        if (valid) {
          let params = {
            userName: this.userAddForm.userName,
            userPwd: this.userAddForm.password,
            phone: this.userAddForm.phone,
            userCount: this.userAddForm.userCount,
            nickName: this.userAddForm.nickName,
          }
          addMyUser(params).then(res => {
            if (res.data.success){
              this.$message.success('用户新增成功');
              this.$router.push({path: "/userList"});
            }
            else {
              this.$message.error(res.data.error.message);
            }
          });
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
.main-border {
  width: 100%;
  font-size: 12px;
}

.el-form-item {
  margin-bottom: 0 !important;
}

.el-input {
  width: 201px !important;
}

/deep/ .el-input__inner {
  border-radius: 2px !important;
  border: 1px solid #aaaaac !important;
}

.el-button {
  font-weight: 700;
  width: 100px;
}

/deep/ .el-form-item__label {
  font-weight: 600;
  font-size: 13px;
}
</style>
