<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>充值</el-breadcrumb-item>
      <el-breadcrumb-item>发币订单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>

    <div class="page-content">
    <el-row @keyup.enter.native="loadData(1)">
      <el-input v-model="queryField.send_id" size="small" placeholder="发币人id"></el-input>
<!--      <el-input v-if="show" v-model="queryField.send_name" size="small" placeholder="发币人帐号"></el-input>-->
      <el-input v-model="queryField.receive_id" size="small" placeholder="收币人id"></el-input>
<!--      <el-input v-if="show" v-model="queryField.receive_name" size="small" placeholder="收币人帐号"></el-input>-->
      <el-input
          v-model="queryField.game_name"
          size="small"
          placeholder="游戏名称"
      ></el-input>
      <el-select v-model="queryField.change_type">
        <el-option value="" label="变动类型"></el-option>
        <el-option value="1" label="给下级发币"></el-option>
        <el-option value="2" label="给玩家发币"></el-option>
        <el-option value="3" label="自助充值"></el-option>
      </el-select>
      <el-date-picker
              v-model="queryField.time"
              size="small"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
      </el-date-picker>

      <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
      <el-button v-if="this.groupId === '1'" size="small" type="success" style="height: 32px;"  @click="exportExcel" :loading="loading" loading-text="下载中">导出</el-button>
    </el-row>
      <el-row style="background-color: #f7f5f5; margin-top: 15px;padding-left: 10px;">
        <el-col :lg="4"  key="订单总金额">
          <div class="dashboard-statistic">
            <div class="grid-right">
              <p class="grid-name">订单总金额(￥):{{ orderMoney }}</p>
            </div>
          </div>
        </el-col>
        <el-col :lg="4"  key="支付总金额">
          <div class="dashboard-statistic">
            <div class="grid-right">
              <p class="grid-name">支付总金额(￥):{{ payMoney }}</p>
            </div>
          </div>
        </el-col>
        <el-col :lg="4"  key="实收总金额">
          <div class="dashboard-statistic">
            <div class="grid-right">
              <p class="grid-name">实收总金额(￥):{{ receiveMoney}}</p>
            </div>
          </div>
        </el-col>
      </el-row>
    <el-table class="cps-table-data"
            :data="tableData" size="small"
            border
            :height="height"
            style="width: 100%">
      <el-table-column
          prop="order_id"
          label="订单id"
          width="100">
      </el-table-column>
      <el-table-column
              prop="send_id"
              label="发币人id"
              width="100">
      </el-table-column>
      <el-table-column
              prop="send_name"
              label="发币人帐号"
              width="100">
      </el-table-column>
      <el-table-column
          prop="receive_id"
          label="收币人id"
          width="100">
      </el-table-column>
      <el-table-column
          prop="receive_name"
          label="收币人帐号"
          width="120">
      </el-table-column>
      <el-table-column
          prop="game_name"
          label="游戏名称"
          width="100">
      </el-table-column>
      <el-table-column
              prop="change_type"
              label="变动类型"
              :formatter="formatChangeType"
              width="120">
      </el-table-column>
      <el-table-column
          prop="order_money"
          label="订单金额"
          width="120">
      </el-table-column>
      <el-table-column
          prop="pay_money"
          label="支付金额"
          width="80">
      </el-table-column>
      <el-table-column
          prop="receive_money"
          label="实收金额"
          width="80">
      </el-table-column>
      <el-table-column
          prop="pay_nick_name"
          label="支付昵称"
          width="120">
      </el-table-column>
      <el-table-column
          prop="remark"
          label="备注"
          width="160">
      </el-table-column>
      <el-table-column
          prop="order_status"
          label="订单状态"
          :formatter="formatOrderStatus"
          width="120">
      </el-table-column>
      <el-table-column
              prop="add_time"
              label="充值时间"
              width="160">
      </el-table-column>
    </el-table>
    <el-pagination  class="fixed-pagination"
            @current-change="loadData"
            background
            :page-size="15"
            :current-page="currentIndex"
            layout="total, prev, pager, next"
            :total="total">
    </el-pagination>
    </div>
  </div>
</template>
<script>
import {adminOrderAmount, adminOrderList, exportCoinOrder} from '@/api/request'

  export default {
    name: 'coinLogList',
    data() {
      return {
        queryField: {
          send_id: '',
          // send_name: '',
          receive_id: '',
          // receive_name: '',
          time: [],
          change_type: '',
          start_time: '',
          end_time: '',
          game_name: '',
        },
        tableData: [],
        total: 0,
        loading:false,
        groupId: '',
        currentIndex:1,
        height: window.innerHeight - 210,
        payMoney: '',
        receiveMoney: '',
        orderMoney: '',
      };
    },
    mounted() {
      this.loadData(1);
      this.loadAmount();
      let user = JSON.parse(localStorage.getItem("userInfo"));
      this.groupId = user.groupId;
    },
    methods: {
      loadData(index) {
        if (this.queryField.time != null && this.queryField.time.length > 0) {
          this.queryField.start_time = this.queryField.time[0] + " 00:00:00";
          this.queryField.end_time = this.queryField.time[1] + " 23:59:59";
        }
        else {
          this.queryField.start_time = "";
          this.queryField.end_time = "";
        }
        let params = {
          pageNo: index,
          pageSize: 15,
          params : this.queryField,
        }
        adminOrderList(params).then(res => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.currentIndex=index;
        });
        this.loadAmount();
      },
      loadAmount(){
        if (this.queryField.time != null && this.queryField.time.length > 0) {
          this.queryField.start_time = this.queryField.time[0] + " 00:00:00";
          this.queryField.end_time = this.queryField.time[1] + " 23:59:59";
        }
        else {
          this.queryField.start_time = "";
          this.queryField.end_time = "";
        }
        adminOrderAmount(this.queryField).then(res => {
          let _data = res.data.data;
          this.payMoney = _data.payMoney;
          this.receiveMoney = _data.receiveMoney;
          this.orderMoney = _data.orderMoney;
        })
      },
      exportExcel(){
        if(this.queryField.start_time){
          this.queryField.start_time += ' 00:00:00';
        }else {
          this.queryField.start_time = '';
        }

        if(this.queryField.end_time){
          this.queryField.end_time += ' 23:59:59';
        }else{
          this.queryField.end_time = '';
        }
        this.loading = true;
        exportCoinOrder(this.queryField).then( res => {
          this.downloadTabulation(res.data)
        })
      },
      downloadTabulation(data){
        if(!data){
          return
        }
        let url = window.URL.createObjectURL(new Blob([data], {type: "application/vnd.ms-excel"}));
        let link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download',"发币订单导出.xls");
        document.body.appendChild(link);
        link.click();
        //释放临时对象url
        window.URL.revokeObjectURL(url);
        this.loading=false;
      },
      formatChangeType(row, column, cellValue) {
        let ret = ''
        if (cellValue===1) {
          ret = "给下级发币"
        } else if (cellValue===2){
          ret = "给玩家发币"
        } else if (cellValue===3) {
          ret = "自助充值"
        }
        return ret;
      },
      formatOrderStatus(row, column, cellValue) {
        let ret = ''
        if (cellValue===1) {
          ret = "待支付"
        } else if (cellValue===2){
          ret = "已支付"
        } else if (cellValue===3) {
          ret = "超时"
        }
        return ret;
      }
    },
  }
</script>
<style lang="scss" scoped>
  /deep/ .is-active {
    background-color: unset !important;
  }
</style>
