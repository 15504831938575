<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
      <el-breadcrumb-item>游戏攻略列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="page-content">
      <el-row @keyup.enter.native="loadData(1)">
        <el-input size="small" placeholder="攻略id" v-model="queryField.contentId"/>
        <el-select
            size="small"
            ref="selectGame"
            v-model="game"
            filterable
            remote
            reserve-keyword
            placeholder="游戏名称"
            @change="handleSelect"
            :remote-method="loadGame"
            @visible-change="cancelReadOnly"
        >
          <el-option
              v-for="item in restaurants"
              :key="item.key"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-select size="small" v-model="queryField.status">
          <el-option label="攻略状态" value=""></el-option>
          <el-option label="正常" value="1"></el-option>
          <el-option label="下架" value="2"></el-option>
        </el-select>
        <el-button size="small" type="primary" @click="loadData(1)">查询</el-button>
        <el-button size="small" @click="reset">重置</el-button>
        <el-button size="small" type="primary" @click="toAdd">添加攻略</el-button>
      </el-row>

      <el-table class="cps-table-data"
                :data="tableData" size="small"
                border
                :height="height"
                style="width: 100%">
        <el-table-column
            prop="content_id"
            label="攻略ID"
            width="60">
        </el-table-column>
        <el-table-column
            prop="game_id"
            label="游戏ID"
            width="60">
        </el-table-column>
        <el-table-column
            prop="game_name"
            label="游戏名称"
            width="140">
        </el-table-column>
        <el-table-column
            label="攻略名称"
            width="150">
          <template v-slot="scope">
            <el-popover
                placement="bottom"
                width="200"
                :content="scope.row.content_name"
                trigger="click">
              <el-button type="text" slot="reference" style="width: 120px;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                {{ scope.row.content_name }}
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            label="攻略描述"
            width="150">
          <template v-slot="scope">
            <el-popover
                placement="bottom"
                width="200"
                :content="scope.row.content_des"
                trigger="click">
              <el-button type="text" slot="reference" style="width: 120px;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                {{ scope.row.content_des }}
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态"
            :formatter="formatterStatus"
            width="80">
        </el-table-column>
        <el-table-column
            prop="add_time"
            label="添加时间"
            width="140">
        </el-table-column>
        <el-table-column
            label="操作">
          <template v-slot="scope">
            <el-button type="text" size="small" @click="toEdit(scope.row.content_id)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="cps-table-data"
                     @current-change="loadData"
                     background
                     :page-size="pageSize"
                     :current-page="currentIndex"
                     layout="total, prev, pager, next"
                     :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>

import { loadGame,queryContent } from "@/api/request";

export default {
  name: 'ContentList',
  data() {
    return {
      tableData: [],
      queryField: {
        contentId: '',
        gameId: '',
        status: '',
      },
      total: 0,
      height: window.innerHeight - 210,
      currentIndex: 1,
      pageSize: 15,
      game: '',
      commentData: [],
      commentTotal: 0,
      commentCurIndex: 1,
      contentId: '',
      restaurants: [],
    };
  },
  mounted() {
    this.loadData(1);
  },
  methods: {
    loadData(index) {
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField,
      }
      queryContent(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentIndex = index;
      })
    },
    reset(){
      this.queryField = {
        contentId: '',
        gameId: '',
        status: ''
      }
      this.game = '';
      this.loadData(1);
    },
    toAdd() {
      this.$router.replace("/game/content/add");
    },
    toEdit(contentId) {
      this.$router.replace(`/game/content/edit/${contentId}`);
    },
    loadGame(query){
      if (query !== '') {
        let me = this;
        loadGame(query).then(res => {
          me.restaurants = [];
          res.data.data.forEach(function (e) {
            let item = {}
            item.key = e.game_id
            item.value = e.game_id
            item.label = e.game_name
            me.restaurants.push(item)
          })
        })
      }
    },
    handleSelect(item){
      this.queryField.gameId = item;
    },
    cancelReadOnly(value){
      this.$nextTick(() => {
        if (!value) {
          const {selectGame} = this.$refs;
          const input = selectGame.$el.querySelector(".el-input__inner");
          input.removeAttribute("readonly");
          this.$refs.selectGame.blur();
        }
      });
    },
    formatContentType(row, column, cellValue) {
      let ret = ''
      if (cellValue === 1) {
        ret = "攻略"
      } else if(cellValue === 2){
        ret = "活动"
      }else if(cellValue === 3){
        ret = '公告'
      }else if(cellValue === 4){
        ret = '咨询'
      }else if(cellValue === 5){
        ret = '视频'
      }
      return ret;
    },
    formatterStatus(row, column, cellValue){
      let rep = '';
      if(cellValue === 1){
        rep = '正常';
      }else if(cellValue === 2){
        rep = '下架';
      }
      return rep;
    }
  },
  beforeRouteEnter(to,form,next){
    if(form.name === "ContentAdd" || form.name === "ContentEdit"){
      next(vm => {
        vm.loadData(1);
      });
    }else {
      next();
    }
  }
}
</script>
<style scoped>
/deep/ .el-dialog{
  border-radius: 10px;
}

.comment-table-data /deep/ .el-pagination{
  position: unset;
}
</style>