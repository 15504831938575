import Vue from 'vue'
import Router from 'vue-router'
import Menu from './views/menu/Menu.vue';
import Login from './views/login/login.vue';
import Welcome from './views/welcome/welcome.vue';
import Recharge from './views/recharge/recharge.vue';
import UserList from './views/usermanage/userlist.vue';
import AdminList from './views/setting/adminlist.vue';
import GameList from './views/game/gamelist.vue';
import GameAdd from './views/game/gameadd.vue';
import GameEdit from './views/game/gameedit.vue';
import GameRole from './views/game/gamerole.vue';
import RoleManage from './views/setting/rolemanage.vue';
import Active from './views/setting/active.vue';
import Day from './views/setting/day.vue';
import AuthManage from './views/setting/authmanage.vue';
import AdminCoin from './views/recharge/admincoin.vue';
import ChildrenCoin from './views/recharge/childrencoin.vue';
import UserCoin from './views/recharge/usercoin.vue';
import UserCoinLogList from './views/recharge/usercoinloglist.vue';
import AdminCoinLogList from './views/recharge/admincoinloglist.vue';
import adminOrder from './views/recharge/coinloglist.vue';
import OrderList from './views/order/orderlist.vue';
import OrderDetail from './views/order/orderdetail.vue';
import OrderCount from './views/order/ordercount.vue';
import GamePercentage from './views/percentage/gamepercentage.vue';
import AdminPercentage from './views/percentage/adminpercentage.vue';
import OrderPercentage from './views/percentage/orderpercentage.vue';
import MyShare from './views/percentage/myshare.vue';
import MyPercentage from './views/percentage/mypercentage.vue';
import ModifyPassword from './views/welcome/modifypassword.vue';
import ModifyPayWord from './views/welcome/modifypayword.vue';
import ModifyPhone from './views/welcome/modifyphone.vue';
import ModifyUserinfo from './views/welcome/modifyuserinfo.vue';
import AdminAdd from './views/setting/adminadd.vue';
import AdminEdit from './views/setting/adminedit.vue';
import AdminExamine from './views/setting/adminexamine.vue';
import UserAdd from './views/usermanage/useradd.vue';
import RoleAdd from './views/setting/roleadd.vue';
import UserChannel from './views/usermanage/childuserlist.vue';
import UserDetail from './views/usermanage/userdetial.vue';
import CashOut from './views/recharge/cashout.vue';
import MyCashOut from './views/percentage/mycashout.vue';
import Statistics from './views/setting/statistics.vue';
import MobileRechargeAdmin from './views/mobile/rechargeadmin.vue';
import MobileRechargeUser from './views/mobile/rechargeuser.vue';
import MobileLogin from './views/mobile/mobilelogin.vue';
import MobileHome from './views/mobile/mobilehome.vue';
import Select from './views/mobile/select.vue';
import GiftRecord from "./views/gift/giftrecord";
import GiftList from "./views/gift/giftlist";
import GiftAdd from "./views/gift/giftadd";
import GiftEdit from "./views/gift/giftedit";
import provideList from "./views/award/providelist";
import drawAddNum from "./views/draw/drawAddNum";
import ExtendList from "./views/game/extend/extendlist"
import ExtendAdd from "./views/game/extend/extendadd"
import ExtendEdit from "./views/game/extend/extendedit"
import GuideList from "./views/game/guide/guidelist"
import CodeList from "./views/gift/code/codelist"
import CodeAdd from "./views/gift/code/codeadd"
import RedAdd from "./views/gift/red/redadd"
import OnlineList from "./views/online/onlinelist"
import WeChatList from "./views/usermanage/wechatlist"
import SelfCount from './views/order/selfCount2.vue';
import TransactionOrder from "./views/transaction/transactionlist.vue"
import TransactionGoods from "./views/transaction/transactionGoods"
import Gameserverlist from "@/views/game/gameserver/gameserverlist.vue";
import Gameserveradd from "@/views/game/gameserver/gameserveradd.vue";
import Gameserverbatchadd from "@/views/game/gameserver/gameserverbatchadd.vue";
import Contentlist from "@/views/game/content/contentlist.vue";
import ContentEdit from "@/views/game/content/contentEdit.vue";
import ContentAdd from "@/views/game/content/contentAdd.vue";


Vue.use(Router)
export default new Router({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/mobile/login',
            name: 'MobileLogin',
            component: MobileLogin,
            meta: {
                title: '移动端登录'
            }
        },
        {
            path: '/mobile/home',
            name: 'MobileHome',
            component: MobileHome,
            meta: {
                title: '移动端首页'
            }
        },
        {
            path: '/mobile/rechargeAdmin',
            name: 'MobileRechargeAdmin',
            component: MobileRechargeAdmin,
            meta: {
                title: '移动端给会长充值'
            }
        },
        {
            path: '/mobile/rechargeUser',
            name: 'MobileRechargeUser',
            component: MobileRechargeUser,
            meta: {
                title: '移动端给玩家充值'
            }
        },
        {
            path: '/mobile/select',
            name: 'Select',
            component: Select,
            meta: {
                title: '福利抽奖'
            }
        },
        {
            path: '/selfCount',
            name: 'SelfCount',
            component: SelfCount,
            meta: {
                title: '累充查询'
            }
        },
        {
            path: '/',
            name: 'Menu',
            component: Menu,
            children: [//每个子路由对应了一个导航栏的选项，点击选项后跳转到相应路由，相应模块显示在插槽位置。
                {
                    path: '/home',
                    name: 'welcome',
                    component: Welcome,
                    meta: {
                        // 页面标题title
                        title: '数据统计'
                    }
                },
                {
                    path: '/userList',
                    name: 'userList',
                    component: UserList,
                    meta: {
                        // 页面标题title
                        title: '用户列表'
                    }
                },
                {
                    path: '/userChannel',
                    name: 'UserChannel',
                    component: UserChannel,
                    meta: {
                        // 页面标题title
                        title: '渠道用户'
                    }
                },
                {
                    path: '/userList/userAdd',
                    name: 'UserAdd',
                    component: UserAdd,
                    meta: {
                        // 页面标题title
                        title: '用户新增'
                    }
                },
                {
                    path: '/userList/userDetail/*',
                    name: 'UserDetail',
                    component: UserDetail,
                    meta: {
                        // 页面标题title
                        title: '用户详情'
                    }
                },
                {
                    path: '/userChannel/userDetail/*',
                    name: 'UserDetail',
                    component: UserDetail,
                    meta: {
                        // 页面标题title
                        title: '用户详情'
                    }
                },
                {
                    path: '/adminList',
                    name: 'AdminList',
                    component: AdminList,
                    meta: {
                        // 页面标题title
                        title: '管理员管理'
                    }
                },
                {
                    path: '/gameList',
                    name: 'GameList',
                    component: GameList,
                    meta: {
                        // 页面标题title
                        title: '游戏列表'
                    }
                },
                {
                    path: '/gameList/gameAdd',
                    name: 'GameAdd',
                    component: GameAdd,
                    meta: {
                        // 页面标题title
                        title: '游戏新增'
                    }
                },
                {
                    path: '/gameList/gameEdit/*',
                    name: 'GameEdit',
                    component: GameEdit,
                    meta: {
                        // 页面标题title
                        title: '游戏编辑'
                    }
                },
                {
                    path: '/gameRole',
                    name: 'GameRole',
                    component: GameRole,
                    meta: {
                        // 页面标题title
                        title: '游戏角色'
                    }
                },
                {
                    path: '/roleManage',
                    name: 'RoleManage',
                    component: RoleManage,
                    meta: {
                        // 页面标题title
                        title: '角色管理'
                    }
                },
                {
                    path: '/roleManage/authManage',
                    name: 'AuthManage',
                    component: AuthManage,
                    meta: {
                        // 页面标题title
                        title: '角色授权'
                    }
                },
                {
                    path: '/roleManage/roleAdd',
                    name: 'RoleAdd',
                    component: RoleAdd
                },
                {
                    path: '/active',
                    name: 'Active',
                    component: Active,
                    meta: {
                        // 页面标题title
                        title: '留存'
                    }
                },
                {
                    path: '/day',
                    name: 'Day',
                    component: Day,
                    meta: {
                        // 页面标题title
                        title: '留存'
                    }
                },
                {
                    path: '/adminCoin',
                    name: 'AdminCoin',
                    component: AdminCoin,
                    meta: {
                        // 页面标题title
                        title: '给会长充值'
                    }
                },
                {
                    path: '/statistics',
                    name: 'Statistics',
                    component: Statistics,
                    meta: {
                        // 页面标题title
                        title: '统计页面'
                    }
                },
                {
                    path: '/childrenCoin',
                    name: 'ChildrenCoin',
                    component: ChildrenCoin,
                    meta: {
                        // 页面标题title
                        title: '给下级充值'
                    }
                },
                {
                    path: '/userCoin',
                    name: 'UserCoin',
                    component: UserCoin,
                    meta: {
                        // 页面标题title
                        title: '给玩家充值'
                    }
                },
                {
                    path: '/adminCoinLogList',
                    name: 'AdminCoinLogList',
                    component: AdminCoinLogList,
                    meta: {
                        // 页面标题title
                        title: '会长充值记录'
                    }
                },
                {
                    path: '/adminOrder',
                    name: 'adminOrder',
                    component: adminOrder,
                    meta: {
                        // 页面标题title
                        title: '会长充值订单'
                    }
                },
                {
                    path: '/userCoinLogList',
                    name: 'UserCoinLogList',
                    component: UserCoinLogList,
                    meta: {
                        // 页面标题title
                        title: '玩家充值记录'
                    }
                },
                {
                    path: '/orderList',
                    name: 'OrderList',
                    component: OrderList,
                    meta: {
                        // 页面标题title
                        title: '订单查询'
                    }
                },
                {
                    path: '/orderList/orderDetail/*',
                    name: 'OrderDetail',
                    component: OrderDetail,
                    meta: {
                        // 页面标题title
                        title: '订单详情'
                    }
                },
                {
                    path: '/orderCount',
                    name: 'OrderCount',
                    component: OrderCount,
                    meta: {
                        // 页面标题title
                        title: '订单统计'
                    }
                },
                {
                    path: '/gamePercentage',
                    name: 'GamePercentage',
                    component: GamePercentage,
                    meta: {
                        // 页面标题title
                        title: '游戏提成配置'
                    }
                },
                {
                    path: '/adminPercentage',
                    name: 'AdminPercentage',
                    component: AdminPercentage,
                    meta: {
                        // 页面标题title
                        title: '会长提成配置'
                    }
                },
                {
                    path: '/orderPercentage',
                    name: 'OrderPercentage',
                    component: OrderPercentage,
                    meta: {
                        // 页面标题title
                        title: '提成查询'
                    }
                },
                {
                    path: '/myShare',
                    name: 'MyShare',
                    component: MyShare,
                    meta: {
                        // 页面标题title
                        title: '提成查询'
                    }
                },
                {
                    path: '/myPercentage',
                    name: 'MyPercentage',
                    component: MyPercentage,
                    meta: {
                        // 页面标题title
                        title: '我的提成配置'
                    }
                },
                {
                    path: '/modifyPassword',
                    name: 'ModifyPassword',
                    component: ModifyPassword,
                    meta: {
                        // 页面标题title
                        title: '修改登录密码'
                    }
                },
                {
                    path: '/modifyPayWord',
                    name: 'ModifyPayWord',
                    component: ModifyPayWord,
                    meta: {
                        // 页面标题title
                        title: '修改支付密码'
                    }
                },
                {
                    path: '/modifyPhone',
                    name: 'ModifyPhone',
                    component: ModifyPhone,
                    meta: {
                        // 页面标题title
                        title: '修改电话号码'
                    }
                },
                {
                    path: '/modifyUserinfo',
                    name: 'ModifyUserinfo',
                    component: ModifyUserinfo,
                    meta: {
                        // 页面标题title
                        title: '修改个人信息'
                    }
                },
                {
                    path: '/adminList/adminAdd',
                    name: 'AdminAdd',
                    component: AdminAdd,
                    meta: {
                        // 页面标题title
                        title: '新增管理员'
                    }
                },
                {
                    path: '/adminList/adminEdit/*',
                    name: 'AdminEdit',
                    component: AdminEdit,
                    meta: {
                        // 页面标题title
                        title: '编辑管理员'
                    }
                },
                {
                    path: '/adminList/adminExamine/*',
                    name: 'AdminExamine',
                    component: AdminExamine,
                    meta: {
                        // 页面标题title
                        title: '管理员审核'
                    }
                },
                {
                    path: '/cashOut',
                    name: 'CashOut',
                    component: CashOut,
                    meta: {
                        // 页面标题title
                        title: '会长提现记录'
                    }
                },
                {
                    path: '/myCashOut',
                    name: 'MyCashOut',
                    component: MyCashOut,
                    meta: {
                        // 页面标题title
                        title: '我的提现记录'
                    }
                },
                {
                    path: '/gift/record',
                    name: 'GiftRecord',
                    component: GiftRecord,
                    meta: {
                        // 页面标题title
                        title: '礼包领取记录'
                    }
                },
                {
                    path: '/gift/list',
                    name: 'GiftList',
                    component: GiftList,
                    meta: {
                        // 页面标题title
                        title: '填写礼包码'
                    }
                },
                {
                    path: '/gift/list',
                    name: 'GiftList',
                    component: GiftList,
                    meta: {
                        // 页面标题title
                        title: '礼包列表'
                    }
                },
                {
                    path: '/gift/add',
                    name: 'GiftAdd',
                    component: GiftAdd,
                    meta: {
                        // 页面标题title
                        title: '礼包管理'
                    }
                },
                {
                    path: '/gift/edit/*',
                    name: 'GiftEdit',
                    component: GiftEdit,
                    meta: {
                        // 页面标题title
                        title: '礼包编辑'
                    }
                },

                {
                    path: '/award/provide',
                    name: 'provideList',
                    component: provideList,
                    meta: {
                        // 页面标题title
                        title: '抽奖记录'
                    }
                },
                {
                    path: '/draw/num',
                    name: 'drawAddNum',
                    component: drawAddNum,
                    meta: {
                        title: '抽奖次数'
                    }
                },

                //GameExtend
                {
                    path: '/game/extend/list',
                    name: 'ExtendList',
                    component: ExtendList,
                    meta: {
                        title: '游戏推广管理'
                    }
                },

                {
                    path: '/game/extend/add',
                    name: 'ExtendAdd',
                    component: ExtendAdd,
                    meta: {
                        title: '游戏推广新增'
                    }
                },

                {
                    path: '/game/extend/edit/*',
                    name: 'ExtendEdit',
                    component: ExtendEdit,
                    meta: {
                        title: '游戏推广编辑'
                    }
                },

                //GameGuide
                {
                    path: '/game/content/list',
                    name: 'ContentList',
                    component: Contentlist,
                    meta: {
                        title: '游戏攻略列表'
                    }
                },
                {
                    path: '/game/content/edit/*',
                    name: 'ContentEdit',
                    component: ContentEdit,
                    meta: {
                        title: '游戏攻略编辑'
                    }
                },
                {
                    path: '/game/content/add',
                    name: 'ContentAdd',
                    component: ContentAdd,
                    meta: {
                        title: '游戏攻略添加'
                    }
                },
                {
                    path: '/gift/code/list/*',
                    name: 'CodeList',
                    component: CodeList,
                    meta: {
                        title: '礼包码列表'
                    }
                },
                {
                    path: '/gift/code/add/*',
                    name: 'CodeAdd',
                    component: CodeAdd,
                    meta: {
                        title: '礼包码添加'
                    }
                },
                {
                    path: '/red/add',
                    name: 'RedAdd',
                    component: RedAdd,
                    meta: {
                        title: '红包发放'
                    }
                },                {
                    path: '/online',
                    name: 'OnlineList',
                    component: OnlineList,
                    meta: {
                        title: '在线人数'
                    }
                },
                {
                    path: '/wechat',
                    name: 'WeChatList',
                    component: WeChatList,
                    meta: {
                        title: '在线人数'
                    }
                },
                {
                    path: '/transactionOrder',
                    name: 'TransactionOrder',
                    component: TransactionOrder,
                    meta: {
                        title: '交易订单'
                    }
                },
                {
                    path: '/transactionGoods',
                    name: 'TransactionGoods',
                    component: TransactionGoods,
                    meta: {
                        title: '商品列表'
                    }
                },
                {
                    path: '/game/server/list',
                    name: 'GameServer',
                    component: Gameserverlist,
                    meta: {
                        title: '开服列表'
                    }
                },
                {
                    path: '/game/server/add',
                    name: 'GameServerAdd',
                    component: Gameserveradd,
                    meta: {
                        title: '添加开服'
                    }
                },
                {
                    path: '/game/server/batchAdd',
                    name: 'GameServerBatchAdd',
                    component: Gameserverbatchadd,
                    meta: {
                        title: '批量添加开服'
                    }
                },
            ]
        }
    ]
})
