<template>
  <div class="mobile-main">
    <span class="main-text">会游CPS移动端</span>
    <div class="form-panel">
      <van-form validate-first @submit="onSubmit">
        <van-image :src="logo" width="5.5rem" height="5.5rem"/>
        <van-field
                v-model="form.account"
                name="pattern"
                label="用户名"
                placeholder="请输入用户名"
                :rules="[{ required: true, message: '' }]"
        />
        <van-field
                v-model="form.password"
                name="validator"
                label="密码"
                placeholder="请输入密码"
                type="password"
                :rules="[{ required: true, message: '' }]"
        />

        <van-field
                v-model="form.phone"
                name="validator"
                label="手机号码"
                v-if="dialogFormVisible"
                disabled
        />
        <van-field
                v-model="form.code"
                center
                clearable
                v-if="dialogFormVisible"
                label="验证码"
                placeholder="请输入验证码"
                :rules="[{ required: true, message: '请填写验证码' },
                { pattern: /^\d{6}$/, message: '验证码格式错误' }]">
          <template #button>
            <van-button @click="sendLoginMsg" :disabled="disabledSend" size="small" type="primary">{{buttonName}}</van-button>
          </template>
        </van-field>

        <div style="margin: 16px;">
          <van-button round block type="info" size="small" native-type="submit">登录</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
  import {doLogin,sendMsg} from '@/api/request'
  import crypto from '@/utils/crypto.js';
  import logo from "@/assets/logo.png";
  import {Dialog, Toast} from "vant";


  export default {
    name:'mobileLogin',
    data() {
      return {
        logo,
        form: {
          password: '',
          account: '',
        },
        dialogFormVisible: false,
        buttonName: "发送验证码",
        disabledSend: false,
      }
    },
    methods: {
      onSubmit() {
        this.loginLoading = true;
        let params = {
          loginName: this.form.account,
          password: crypto.encrypt(this.form.password),
          checkCode: this.form.checkCode
        }
        doLogin(params).then(res => {
          this.loginLoading = false;
          if (res.data.success) {
            //需要二次验证
            if (res.data.data.needCheck) {
              this.form.phone = res.data.data.phone;
              this.dialogFormVisible = true;
            } else {
              localStorage.setItem('token', res.data.data.token);
              localStorage.setItem('userInfo', JSON.stringify(res.data.data));
              this.dialogFormVisible = false;
              this.$router.push({path: "/mobile/home"});
            }
          } else {
            Toast.fail(res.data.error.message);
          }
        });
      },
      sendLoginMsg() {
        let me = this;
        this.disabledSend = true
        let interval = window.setInterval(function() {
          me.buttonName = '（' + me.time + '秒）后重新发送';
          --me.time;
          if(me.time < 0) {
            me.buttonName = "重新发送";
            me.time = 60;
            me.disabledSend = false;
            window.clearInterval(interval);
          }
        }, 1000);

        sendMsg(this.ruleForm.phone).then(res => {
          if (res.data.success) {
            this.$message({
              message: '验证码发送成功',
              type: 'success'
            });
          } else {
            this.$alert(res.data.error.message, '提示', {
              confirmButtonText: '确定',
              callback: action => {

              }
            });
          }
        });
      },
    }
  }
</script>

<style scoped lang="scss">
  .mobile-main{
    height: 100vh;
    background-color: rgba(184, 191, 188, 0.09);
    text-align: center;
    .main-text{
      display: inline-block;
      font-size: 20px;
      margin-top: 30px;
      font-weight: 600;
    }
    .form-panel{
      position: absolute;
      width: 90%;
      top: 15%;
      left: 5%;
      background-color: white;
      text-align: center;
      padding-bottom: 15px;
      border-radius: 5px;
      border: 1px solid #eeebeb;
    }
  }
</style>
