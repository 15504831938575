<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>渠道用户</el-breadcrumb-item>
      <el-breadcrumb-item>用户详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-page">
        <div class="table-div">
        <el-table class="cps-table-data"
            :data="gameData"
            border
            style="width: 100px">
          <el-table-column
              prop="game_name"
              label="游戏名称"
              width="180">
          </el-table-column>
          <el-table-column
              prop="balance"
              label="剩余会游币数量"
              width="180">
          </el-table-column>
          <el-table-column
              prop="bind_balance"
              label="剩余绑币数量"
              width="180">
          </el-table-column>
          <el-table-column
              prop="add_time"
              label="开始游戏时间">
          </el-table-column>
        </el-table>
        <div style="width: 30px"></div>
        <el-table class="cps-table-data"
            :data="loginData"
            border
            style="width: 90%">
          <el-table-column
              prop="login_time"
              label="登录时间"
              width="180">
          </el-table-column>
          <el-table-column
              prop="game_name"
              label="游戏名"
              width="180">
          </el-table-column>
          <el-table-column
              prop="login_ip"
              label="登录ip">
          </el-table-column>
        </el-table>
        </div>
        <el-button @click="back">返回</el-button>
  </div>
  </div>
</template>

<script>
import {getUserLoginLog, getUserWallet} from '@/api/request'

export default {
  name: 'userDetail',
  data() {
    return {
      activeNames: ['1'],
      gameData:[],
      loginData:[],
      userId:'',
      beforeUrl:''
    }
  },
  mounted() {
    this.userId = this.$route.params.pathMatch;
    getUserLoginLog(this.userId).then(res => {
      if (res.data.success) {
        this.loginData = res.data.data;
      } else {
        this.$message.error(res.data.error.message);
      }
    });
    getUserWallet(this.userId).then(res => {
      if (res.data.success) {
        this.gameData = res.data.data;
      } else {
        this.$message.error(res.data.error.message);
      }
    });
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    back(){
      this.$router.push({path: localStorage.getItem('beforeUrl')});
    },

  }
}
</script>

<style scoped lang="scss">
.main-page {
  padding: 0 10px;
  width: 100%;
  font-size: 12px;

  .table-div {
     padding-right: 20px;
    display: flex;
    .cps-table-data {
      font-size: 13px;
      margin-top: 10px;
      thead {
        color: #2b2929;
        tr{
          th{
            background-color: #f5f5f5;
          }
        }
      }

      /deep/td {
        padding: 10px 0 !important;
        font-weight: 500;
        .cell{
          button{
            padding: 0;
          }
        }
      }
    }
  }

    .el-button {
        font-weight: 700;
        width: 100px;
        margin: 20px 0;
    }
}




</style>
