<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>修改支付密码</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-border">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="原支付密码" prop="oldPassword">
          <el-input type="password" v-model="form.oldPassword" size="small" placeholder="如第一次设置则不填"></el-input>
        </el-form-item>
        <el-form-item label="新支付密码" prop="newPassword">
          <el-input type="password" v-model="form.newPassword" size="small" placeholder="请输入新支付密码"></el-input>
        </el-form-item>
        <el-form-item label="确认新支付密码" prop="confirmPassword">
          <el-input type="password" v-model="form.confirmPassword" size="small" placeholder="请再次输入支付密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
  import { changePayPassword } from '@/api/request'
  import crypto from '@/utils/crypto.js';

  export default {
    name: 'modifyPayPassword',
    data() {
      const equalsPassword= (rule, value, callback) => {
        if (value !== this.form.newPassword) {
          callback(new Error('两次支付密码输入不一致'));
        }
        else {
          callback();
        }
      };
      return {
        form: {
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        },
        rules: {
          oldPassword: [
            { pattern: /^.{6,20}$/, trigger: 'blur', message: '原支付密码格式错误(6-20位)'},
          ],
          newPassword: [
            { required: true, message: '请输入新支付密码'},
            { pattern: /^.{6,20}$/, trigger: 'blur', message: '新支付密码格式错误(6-20位)'},
          ],
          confirmPassword: [
            { required: true, message: '请输入确认新支付密码'},
            { pattern: /^.{6,20}$/, trigger: 'blur', message: '确认支付密码格式错误(6-20位)'},
            { validator: equalsPassword, trigger: 'blur' }
          ],
        }
      }
    },
    methods: {
      onSubmit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            let params = {
              oldPassword: crypto.encrypt(this.form.oldPassword),
              newPassword: crypto.encrypt(this.form.newPassword),
            }
            changePayPassword(params).then(res => {
              if (res.data.success){
                this.$message.success('支付密码修改成功');
              }
              else {
                this.$message.error(res.data.error.message);
              }
            });
          }
        });
      }
    }
  }
</script>

<style scoped lang="scss">
  .el-input {
    width: 250px !important;
    min-width: 200px;
  }
  /deep/.el-input__inner {
    border-radius: 2px !important;
    border: 1px solid #aaaaac !important;
  }
  /deep/.el-form-item__label{
    font-weight: 600;
    font-size: 13px;
  }
  .main-button{
    font-weight: 700;
    width: 120px;
  }
  .el-button {
    font-weight: 700;
    width: 100px;
  }
</style>
