<template>
    <div class="cps-main-page">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>角色管理</el-breadcrumb-item>
        <el-breadcrumb-item>新增角色</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider></el-divider>
        <div class="main-border">
          <el-form ref="form" :model="form" :rules="rules" label-width="120px">
            <el-form-item label="角色名" prop="roleName">
              <el-input v-model="form.roleName" size="small" placeholder="角色名"></el-input>
            </el-form-item>
            <el-form-item label="角色编号" prop="code">
              <el-input v-model="form.code" size="small" placeholder="角色编号"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="form.remark" size="small" placeholder="备注"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
              <el-button @click="back">返回</el-button>
            </el-form-item>
          </el-form>
        </div>
    </div>
</template>

<script>
  import { addRole } from '@/api/request'
  export default {
    name:'roleAdd',
    data() {
      return {
        form: {
          roleName: '',
          code: '',
          remark: '',
        },
        rules: {
          roleName: [
            { required: true, message: '请输入角色名'},
            { max: 50, trigger: 'blur', message: '角色名最大长度50' }
          ],
          code: [
            { required: true, message: '请输入角色编号'},
            { max: 50, trigger: 'blur', message: '角色编号最大长度50' }
          ],
          remark: [
            { required: true, message: '请输入备注'},
            { max: 128, trigger: 'blur', message: '备注最大长度128' }
          ],
        }
      }
    },
    methods: {
      onSubmit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            addRole(this.form).then(res => {
              if (res.data.success){
                this.$message.success('角色新增成功');
                this.$refs['form'].resetFields();
              }
              else {
                this.$message.error(res.data.error.message);
              }
            });
          } else {
            return false;
          }
        });
      },
      back() {
        this.$router.push({path: '/roleManage'});
      }
    }
  }
</script>

<style scoped>
  .el-input {
    width: 300px !important;
  }
  /deep/.el-input__inner {
    border-radius: 2px !important;
    border: 1px solid #aaaaac !important;
  }
  .el-button{
    font-weight: 700;
    width: 80px;
  }
  /deep/.el-form-item__label{
    font-weight: 600;
    font-size: 13px;
  }
</style>
