<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>充值</el-breadcrumb-item>
      <el-breadcrumb-item>给下级充值</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-border">
      <el-form ref="form" :model="form" :rules="rules"  label-width="100px" @keyup.enter.native="onSubmit()">
        <el-form-item label="会长账号" prop="admin_id">
          <el-select v-model="form.admin_id"  size="small">
            <el-option v-for="item in childList"
                    :value="item.admin_id" :label="item.nick_name || item.admin_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充值金额" prop="amount">
          <el-input v-model="form.amount" placeholder="请输入充值金额" size="small"></el-input>
        </el-form-item>
        <el-form-item label="支付密码" prop="pay_pwd">
          <el-input type="password" v-model="form.pay_pwd" placeholder="请输入支付密码" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="main-button" @click="onSubmit" :loading="submitting">确认充值</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
  import { findChildAdminList,childRecharge } from '@/api/request'
  import crypto from '@/utils/crypto.js';

  export default {
    name:'childrenCoin',
    data() {
      return {
        form: {
          admin_id: '',
          amount: '',
          pay_pwd: '',
        },
        rules: {
          amount: [
            { required: true, message: '请输入充值金额'},
            { pattern: /^\d+([.]\d{1,2})?$/, trigger: 'blur', message: '充值金额格式不正确'},
          ],
          pay_pwd: [
            { required: true, message: '请输入支付密码'},
          ],
          admin_id: [
            { required: true, message: '请选择下级会长'}
          ]
        },
        submitting: false,
        childList: [],
      }
    },
    mounted() {
      findChildAdminList().then(res => {
        if (res.data.success){
          this.childList = res.data.data;
        }
      });
    },
    methods: {
      onSubmit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.submitting = true;
            let params = {
              admin_id: this.form.admin_id,
              recharge_amount: this.form.amount,
              pay_pwd: crypto.encrypt(this.form.pay_pwd),
            }
            childRecharge(params).then(res => {
              if (res.data.success){
                this.$message.success('充值成功！');
                this.$refs['form'].resetFields();
              }
              else {
                this.$message.error(res.data.error.message);
              }
              this.submitting = false;
            });
          } else {
            return false;
          }
        });
      }
    }
  }
</script>

<style scoped lang="scss">
  /deep/.el-input {
    width: 250px !important;
    min-width: 200px;
  }
  /deep/.el-input__inner {
    border-radius: 2px !important;
    border: 1px solid #aaaaac !important;
  }
  /deep/.el-form-item__label{
    font-weight: 600;
    font-size: 13px;
  }
  .main-button{
    font-weight: 700;
    width: 120px;
  }
  .el-button {
    font-weight: 700;
    width: 180px;
  }
</style>
