import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import {
  Button,
  Icon,
  Grid,
  GridItem,
  Image as VanImage,
  Cell,
  CellGroup,
  Dialog,
  Toast,
  NavBar,
  Picker,
  Popup,
  Field,
  Form,
  Loading,
  Popover,
} from 'vant';
import 'vant/lib/index.css';

Vue.use(Button)
Vue.use(Icon)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(VanImage)
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(NavBar);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Form);
Vue.use(Loading);
Vue.use(Popover);

Vue.config.productionTip = false

Vue.use(ElementUI);

router.beforeEach(( to, from, next ) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.name === 'Select'|| to.name == 'SelfCount') {
    next();
    return ;
  }

  if (to.name !== 'login' && to.name !== 'MobileLogin') {
    let token = localStorage.getItem('token');
    if (!token) {
      if (to.name.indexOf('Mobile') !== -1) {
        window.location.href = '#/mobile/login'
      }
      else {
        window.location.href = '#/login'
      }
      window.location.reload()
    } else {
      localStorage.setItem('beforeUrl', from.fullPath);
      next();
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  // NProgress.done()
})

//  全局组件之间通信
Vue.prototype.$viewerBus = Vue.prototype.$viewerBus || new Vue()

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
