<template>
  <div class="mobile-main">

    <van-nav-bar
            title="给会长充值"
            left-text="返回"
            left-arrow
            @click-left="back"
    />
    <div class="header-info">
      <p>充值信息</p>
    </div>

    <div class="form-panel">

      <van-form ref="form" validate-first @submit="onSubmit">
        <van-field
                v-model="form.admin_id"
                required
                label="会长ID"
                placeholder="请输入会长ID"
                :rules="[{ required: true, message: '' },
                { pattern: /^\d+$/, message: '请填写正确的会长ID' },
                { validator : validateId, message: '会长ID不存在' }]"
        />
        <van-field
                v-model="form.admin_name"
                required
                label="会长账号"
                placeholder="请输入会长账号"
                :rules="[{ required: true, message: '' },
                { validator : validateName, message: '会长账号不存在' }]"
        />
        <van-field
                v-model="form.recharge_amount"
                type="number"
                required
                label="充值金额"
                placeholder="请输入充值金额"
                :rules="[{ required: true, message: '' },
                { pattern: /^-?\d+([.]\d{1,2})?$/, message: '充值金额格式错误' }]"
        />
        <van-field
                v-model="form.pay_pwd"
                type="password"
                required
                label="支付密码"
                placeholder="请输入支付密码"
                :rules="[{ required: true, message: '' }]"
        />
        <van-field
                v-model="form.code"
                v-if="form.recharge_amount >= 10000"
                center
                clearable
                label="验证码"
                required
                placeholder="请输入验证码"
                :rules="[{ required: true, message: '' },
                { pattern: /^\d{6}$/, message: '验证码格式错误' }]">
          <template #button>
            <van-button @click="sendCode" :disabled="disabledSend" size="small" type="info">{{buttonName}}</van-button>
          </template>
        </van-field>

        <div style="margin: 16px;">
          <van-button block type="info" size="small" loading-text="充值中" :loading="submitting" native-type="submit">确认充值</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
  import { findChildById,findChildByName,adminRecharge,sendRechargeCode } from '@/api/request'
  import crypto from '@/utils/crypto.js';
  import {Dialog, Toast} from "vant";

  export default {
    name:'rechargetohz',
    data() {
      return {
        form: {
          admin_id: '',
          admin_name: '',
          recharge_amount: '',
          pay_pwd: '',
          code: '',
        },
        submitting: false,
        disabledSend: false,
        buttonName: '获取验证码',
        time: 60,
      }
    },
    methods: {
      back() {
        this.$router.push({path: "/mobile/home"});
      },
      reset(){
        this.form.admin_id = '';
        this.form.admin_name = '';
        this.form.recharge_amount = '';
        this.form.pay_pwd = '';
        this.form.code = '';
      },
      validateId(value){
        return new Promise((resolve) => {
          findChildById(value).then(res => {
            if (!res.data.success) {
              this.form.admin_name = '';
              resolve(false);
            }
            else {
              let adminName = res.data.data;
              if (adminName !== this.form.admin_name) {
                this.form.admin_name = adminName;
              }
              resolve(true);
            }
          });
        });
      },
      validateName(value){
        return new Promise((resolve) => {
          findChildByName(value).then(res => {
            if (!res.data.success) {
              this.form.admin_id = '';
              resolve(false);
            }
            else {
              let adminId = res.data.data;
              if (adminId !== this.form.admin_id) {
                this.form.admin_id = adminId;
              }
              resolve(true);
            }
          });
        });
      },
      onSubmit() {
        this.submitting = true;
        let params = {
          admin_id: this.form.admin_id,
          recharge_amount: this.form.recharge_amount,
          pay_pwd: crypto.encrypt(this.form.pay_pwd),
          code: this.form.code,
        }
        adminRecharge(params).then(res => {
          if (res.data.success){
            Toast.success('充值成功！');
            this.reset();
          }
          else {
            Toast.fail(res.data.error.message);
          }
          this.submitting = false;
        });
      },
      sendCode() {
        let me = this;
        this.disabledSend = true
        sendRechargeCode().then(res => {
          if (res.data.success) {
            Toast.success('验证码发送成功');
            me.buttonName = me.time + '秒';
            let interval = window.setInterval(function() {
              --me.time;
              if(me.time < 0) {
                me.buttonName = "重新发送";
                me.time = 59;
                me.disabledSend = false;
                window.clearInterval(interval);
              }
            }, 1000);
          } else {
            this.$message.error(res.data.error.message);
            this.disabledSend = false
            me.buttonName = '重新发送';
          }
        })
      },

    }
  }
</script>

<style scoped lang="scss">
  .mobile-main{
    /deep/.van-field__label span{
      font-size: 13px;
    }

    height: 100vh;
    background-color: rgba(184, 191, 188, 0.09);
    .van-nav-bar{
      background-color: #37414d;
      /deep/.van-icon,/deep/.van-nav-bar__text,/deep/.van-nav-bar__title{
        color: white;
        font-size: 14px;
      }
    }
    .header-info{
      padding-left: 5%;
      font-weight: 600;
    }
    .form-panel{
      width: 90%;
      margin: 0 auto;
      background-color: white;
      text-align: center;
      padding-bottom: 15px;
      border-radius: 5px;
    }
  }
</style>
