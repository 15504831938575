<template>
  <div>
    <div class="main-border" v-show="showForm">
      <el-tabs v-model="activeName">
        <el-tab-pane label="累充查询" name="first">
          <el-form :model="loginForm" label-position="right" label-width="100px" status-icon :rules="loginRules"
                   ref="loginForm" :span="12" class="demo-ruleForm">
            <el-form-item label="登录账号" prop="account">
              <el-input type="text" v-model="loginForm.account" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="pwd">
              <el-input type="password" v-model="loginForm.pwd"></el-input>
            </el-form-item>
            <el-form-item label="开始时间" prop="startTime">
              <el-date-picker v-model="loginForm.startTime" type="date" value-format="yyyy-MM-dd 00:00:00"
                              placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="endTime">
              <el-date-picker v-model="loginForm.endTime" type="date" value-format="yyyy-MM-dd 23:59:59"
                              placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitLoginForm('loginForm')">查询</el-button>
              <el-button @click="resetForm('loginForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="bar-container" v-show="!showForm">
      <el-table class="cps-table-data"
                :data="tableData" size="small"
                border
                style="width: 100%">
        <el-table-column
            prop="gameName"
            label="游戏名字"
            width="100">
        </el-table-column>
        <el-table-column
            prop="serverId"
            label="区服"
            width="80">
        </el-table-column>
        <el-table-column
            prop="money"
            label="累充金额"
            width="80">
        </el-table-column>
      </el-table>

      <div class="qr-bottom">
        <el-button plain icon="el-icon-arrow-left" style="margin-top: 20px" @click="back">返回</el-button>
      </div>

    </div>
  </div>
</template>

<script>
import {getSelfCount2} from '@/api/request'


export default {
  name: 'userCount',
  data() {
    return {
      activeName: 'first',
      showForm: true,
      loginForm: {
        account: this.getCookie("userName"),
        pwd: this.getCookie("password"),
        startTime: '',
        endTime: ''
      },
      tableData:[],

      loginRules: {
        account: [
          //{ pattern: /^\w{3,20}$/, trigger: 'blur', message: '请输入正确的玩家账号' },
          {required: true, message: '请输入玩家账号'}
        ],
        pwd: [
          {required: true, message: '请输入密码'}
        ],
        startTime: [
          //{ pattern: /^\w{3,20}$/, trigger: 'blur', message: '请输入正确的玩家账号' },
          {required: true, message: '请选择开始日期'}
        ],
        endTime: [
          //{ pattern: /^\w{3,20}$/, trigger: 'blur', message: '请输入正确的玩家账号' },
          {required: true, message: '请选择结束日期'}
        ]

      }
    };
  },
  mounted() {
    let metaTag = document.getElementById('metaTag');
    metaTag.content = 'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no,viewport-fit=cover';
  },
  methods: {
    setCookie: function (cname, cvalue) {
      document.cookie = cname + "=" + cvalue + ";";
    },
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) != -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    submitLoginForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            userName: this.loginForm.account,
            password: this.loginForm.pwd,
            startTime: this.loginForm.startTime,
            endTime: this.loginForm.endTime
          }
          getSelfCount2(params).then(res => {
            if (res.data.success) {
              this.setCookie("userName", this.loginForm.account);
              this.setCookie("password", this.loginForm.pwd);
              this.showForm = false;
              this.tableData = res.data.data;
            } else {
              this.$alert(res.data.error.message, '提示', {
                confirmButtonText: '确定',
                callback: action => {

                },
                center: true,
                customClass: "customWidth"
              });

            }
          });

        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    back() {
      //this.showForm = true;
      window.location.reload();
    },
  }
}
</script>

<style scoped lang="scss">
.main-border {
  width: 100%;

  //border: 1px solid #eceaea;
  //padding: 20px 120px 20px 50px;
  //margin-left: 50px;
  //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.bar-container {
  width: 100%;
  //border: 1px solid #eceaea;
  //padding: 20px 120px 20px 50px;
  // margin-left: 50px;
  //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .qrcode {
    margin-top: 30px;
  }

  .qr-bottom {
    width: 100%;
    text-align: left;
    margin-top: 30px;

    span {
      font-size: 30px;
      color: #ff0000;
      margin-left: 20px;
    }
  }
}

.el-form {
  max-width: 600px;
}

.customWidth {
  width: 40%; // 设置弹出框宽度的数值
}
</style>
