<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>管理员管理</el-breadcrumb-item>
      <el-breadcrumb-item>编辑管理员</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-border">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">

        <el-col :span="8">
          <el-form-item label="管理员账号" prop="admin_name">
            <el-input v-model="form.admin_name" disabled size="small" placeholder="3-20位(英文、数字、下划线)"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8" :offset="2">
          <el-form-item label="会长级别" prop="admin_level">
            <el-select v-model="form.admin_level" value-key="level" placeholder="会长级别" size="small">
              <el-option key=1 label="一级" value="1"></el-option>
              <el-option key=2 label="二级" value="2"></el-option>
              <el-option key="3" label="三级" value="3"></el-option>
              <el-option key="4" label="四级" value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="昵称" prop="nick_name">
            <el-input v-model="form.nick_name" size="small" placeholder="昵称"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8" :offset="2">
          <el-form-item label="头像" prop="icon">
            <el-input v-model="form.icon" size="small" placeholder="头像"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="渠道URL" prop="admin_url">
            <el-input v-model="form.admin_url" size="small" placeholder="渠道URL"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8" :offset="2">
          <el-form-item label="手机号" prop="admin_phone">
            <el-input v-model="form.admin_phone" size="small" placeholder="手机号"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="免密额度" prop="not_check_amount">
            <el-input maxlength="13" v-model="form.not_check_amount" size="small" placeholder="免密额度"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" :offset="2">
          <el-form-item label="支付宝账号" prop="zfb_account">
            <el-input v-model="form.zfb_account" size="small" placeholder="支付宝账号"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="真实姓名" prop="zfb_account">
            <el-input v-model="form.admin_real_name" size="small" placeholder="真实姓名"></el-input>
          </el-form-item>

        </el-col>
        <el-col :span="8" :offset="2">
          <el-form-item label="审核状态" prop="examine_status">
            <el-select v-model="form.examine_status" placeholder="审核状态"  size="small">
              <el-option label="未审核" :value=0></el-option>
              <el-option label="审核通过" :value=1></el-option>
              <el-option label="审核失败" :value=2></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8" >
          <el-form-item label="角色" prop="group_id">
            <el-select v-model="form.group_id" placeholder="角色" size="small">
            <el-option v-for="item in groupData" :label="item.group_name" :value="item.group_id"></el-option>
          </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8" :offset="2">
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button @click="back">返回</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </div>
  </div>
</template>

<script>
import {updateAdmin, getGroupList, adminDetail} from '@/api/request'

export default {
  name: 'gameAdd',
  data() {
    return {
      form: {
        admin_id: '',
        admin_name: '',
        icon: '',
        nick_name: '',
        admin_url: '',
        admin_phone: '',
        not_check_amount: '',
        zfb_account: '',
        admin_level: '',
        group_id: '',
        admin_real_name: '',
        examine_status: '',
      },
      rules: {
        group_id: [
          {required: true, message: '请选择角色'}
        ],
        admin_phone: [
          {pattern: /^1\d{10}$/, trigger: 'blur', message: '手机号码格式错误'}
        ],
        admin_level: [
          {required: true, message: '请选择会长级别'}
        ],
        zfb_account: [
          {max: 50, trigger: 'blur', message: '支付宝账号最大50位'}
        ],
        nick_name: [
          {max: 50, trigger: 'blur', message: '昵称最大50位'}
        ],
        icon: [
          {max: 128, trigger: 'blur', message: '头像地址最大128位'}
        ],
        admin_url: [
          {max: 128, trigger: 'blur', message: '渠道url最大128位'}
        ],
        not_check_amount: [
          {pattern: /^\d+(\.\d{1,2})?$/, trigger: 'blur', message: '请输入正确的格式,最多两位小数'},
        ],
      },
      adminId: 0,
      groupData: []
    }
  },
  mounted() {
    this.adminId = this.$route.params.pathMatch;
    adminDetail(this.adminId).then(res => {
      if (res.data.success) {
        const data = res.data.data;
        this.form.admin_id = data.admin_id
        this.form.admin_name = data.admin_name
        this.form.nick_name = data.nick_name
        this.form.icon = data.icon
        this.form.admin_url = data.admin_url
        this.form.zfb_account = data.zfb_account
        this.form.admin_phone = data.admin_phone
        this.form.not_check_amount = data.not_check_amount
        this.form.admin_level = data.admin_level + "";
        this.form.group_id = data.group_id;
        this.form.admin_real_name = data.admin_real_name;
        this.form.examine_status = data.examine_status;
      } else {
        this.$message.error(res.data.error.message);
      }
    });

    let params = {
      pageNo: 1,
      pageSize: 999
    }
    getGroupList(params).then(res => {
      this.groupData = res.data.list;
    });
  },
  methods: {
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          updateAdmin(this.form).then(res => {
            if (res.data.success) {
              this.$message.success('管理员修改成功');
            } else {
              this.$message.error(res.data.error.message);
            }
            this.$router.push({path: '/adminList'});
          });
        } else {
          return false;
        }
      });
    },
    back() {
      this.$router.push({path: '/adminList'});
    }
  }
}
</script>

<style scoped>
.el-input {
  width: 80% !important;
  min-width: 200px;
}

/deep/ .el-input__inner {
  border-radius: 2px !important;
  border: 1px solid #aaaaac !important;
}

.el-button {
  font-weight: 700;
  width: 80px;
}

/deep/ .el-form-item__label {
  font-weight: 600;
  font-size: 13px;
}

</style>
