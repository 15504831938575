<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单统计</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-row @keyup.enter.native="draw()">
      <el-autocomplete
          v-if="isSupper"
          size="small"
          class="inline-input"
          v-model="first"
          :fetch-suggestions="querySearch"
          value-key="nickName"
          placeholder="一级渠道"
          @select="handleSelect1"
      ></el-autocomplete>&nbsp;&nbsp;&nbsp;
      <el-autocomplete
          v-if="isSupper"
          size="small"
          class="inline-input"
          v-model="second"
          :fetch-suggestions="querySearch2"
          value-key="nickName"
          placeholder="二级渠道"
          @select="handleSelect2"
      ></el-autocomplete>&nbsp;&nbsp;&nbsp;
      <el-select
          size="small"
          v-model="game"
          filterable
          remote
          reserve-keyword
          placeholder="游戏名称"
          @change="handleSelect"
          :remote-method="loadGame"
          :loading="loading">
        <el-option
            v-for="item in restaurants3"
            :key="item.key"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>&nbsp;&nbsp;&nbsp;
      <el-date-picker
          size="small"
          v-model="queryField.start_time"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="开始日期">
      </el-date-picker>&nbsp;&nbsp;&nbsp;
      <el-date-picker
          size="small"
          v-model="queryField.end_time"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="结束日期">
      </el-date-picker>&nbsp;&nbsp;
      <el-button type="primary" size="small" @click="draw()">查询</el-button>
    </el-row>
    <el-divider></el-divider>
    <div id="report" style="width: 1000px;height: 400px;"></div>
  </div>
</template>

<script>
import {channelDropDown, channelReport, loadGame, subChannelDropDown} from '@/api/request'
import echarts from "echarts";

export default {
  name: 'orderCount',
  data() {
    return {
      game: '',
      isSupper:false,
      restaurants1: [],
      restaurants2: [],
      restaurants3: [],
      first:'',
      second:'',
      queryField: {
        start_time: '',
        end_time: '',
        channel_first: '',
        channel_second: '',
        game_id:'',
      },
      charts: "",
      score: "100",
    };
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.isSupper = user.groupId==="1";
    this.draw();
    this.loadAll1();
    this.loadAll2('0');
  },
  methods: {
    draw() {
      let s = [
        ["日期", "总充值金额", "充值人数", "新增用户"]
      ];
      if (this.queryField.start_time) {
        this.queryField.start_time += " 00:00:00";
      } else {
        this.queryField.start_time = "";
      }

      if (this.queryField.end_time) {
        this.queryField.end_time += " 23:59:59";
      } else {
        this.queryField.end_time = "";
      }
      channelReport(this.queryField).then(res => {

        if (res.data.success) {
          let list = res.data.data;
          list.forEach(item => {
            let i = [];
            i.push(item.dateStr);
            i.push(item.orderAmount);
            i.push(item.payUserCount);
            i.push(item.registerUserCount);
            s.push(i)
          })
          this.charts = echarts.init(document.getElementById("report"));
          this.charts.setOption({
            legend: {},
            tooltip: {},
            dataset: {
              source: s   //连接数据
            },
            xAxis: {type: "category"},
            yAxis: {
              //这个地方如果需要在Y轴定义最大值就放开,如果需要根据数据自适应的话,就注释掉
              // type: "value",
              // max: this.score,
              // maxInterval: this.score * 0.2,
              // minInterval: 1,
              // splitNumber: 4

            },
            grid: {bottom: 30},
            series: [
              {
                type: "bar",    //表示这个是柱状图
                barCategoryGap: "40%",
                itemStyle: {
                  color: "#fcaf17",
                  normal: {
                    label: {
                      show: true,      //开启显示
                      position: 'top', //在上方显示
                      textStyle: {     //数值样式
                        color: 'black',
                        fontSize: 16
                      }
                    }
                  }
                },
                tooltip: {
                  formatter: params => {
                    //console.log(params)   打印这个params,按自己需要拼接字符串
                    return ` ${params.value[0]} <br/>
                         ${params.seriesName}:${params.value[1]}`;
                  }
                }
              },
              {
                type: "bar",
                barCategoryGap: "40%",
                itemStyle: {
                  color: "#81cebe",
                  normal: {
                    label: {
                      show: true,      //开启显示
                      position: 'top', //在上方显示
                      textStyle: {     //数值样式
                        color: 'black',
                        fontSize: 16
                      }
                    }
                  }
                },
                tooltip: {
                  formatter: params => {
                    return ` ${params.value[0]} <br/>
                         ${params.seriesName}:${params.value[2]}`;
                  }
                }
              },
              {
                type: "bar",
                barCategoryGap: "40%",
                itemStyle: {
                  color: "#d71345",
                  normal: {
                    label: {
                      show: true,      //开启显示
                      position: 'top', //在上方显示
                      textStyle: {     //数值样式
                        color: 'black',
                        fontSize: 16
                      }
                    }
                  }
                },
                tooltip: {
                  formatter: params => {
                    return ` ${params.value[0]} <br/>
                         ${params.seriesName}:${params.value[3]}`;
                  }
                }
              }
            ]
          });
        } else {
          this.$message.error(res.data.error.message);
        }
      });

    },
    handleSelect1(item) {
      this.queryField.channel_first = item.value
      if (item.value === '') {
        this.loadAll2('0')
      } else {
        this.loadAll2(item.value)
      }

    },
    handleSelect2(item) {
      this.queryField.channel_second = item.value
    },
    loadAll1() {
      let me = this
      channelDropDown().then(res => {
        me.restaurants1 = []
        let item1 = {}
        item1.value = ''
        item1.nickName = '全部'
        me.restaurants1.push(item1)
        res.data.data.forEach(function (e) {
          if (!e.nick_name) {
            e.nick_name = e.admin_id
          }
          let item = {}
          item.value = e.admin_id
          item.nickName = e.nick_name
          me.restaurants1.push(item)
        })
      })
    },
    loadAll2(parentId) {
      let me = this
      subChannelDropDown(parentId).then(res => {
        me.restaurants2 = []
        let item1 = {}
        item1.value = ''
        item1.nickName = '全部'
        me.restaurants2.push(item1)
        let item2 = {}
        item2.value = '-1'
        item2.nickName = '一级自有'
        me.restaurants2.push(item2)
        res.data.data.forEach(function (e) {
          if (!e.nick_name) {
            e.nick_name = e.admin_id
          }
          let item = {}
          item.value = e.admin_id
          item.nickName = e.nick_name
          me.restaurants2.push(item)
        })
      })
    },

    querySearch (queryString, cb) {
      let restaurants = this.restaurants1
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    querySearch2 (queryString, cb) {
      let restaurants = this.restaurants2
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.nickName.indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleSelect(item) {
      this.queryField.game_id = item
    },
    loadGame(query) {
      if (query !== '') {
        let me = this;
        loadGame(query).then(res => {
          me.restaurants3 = [];
          let item1 = {};
          item1.value = '';
          item1.key = '';
          item1.label = '全部';
          me.restaurants3.push(item1)
          res.data.data.forEach(function (e) {
            let item = {}
            item.key = e.game_id
            item.value = e.game_id
            item.label = e.game_name
            me.restaurants3.push(item)
          })
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
.main-border {
  width: 100%;
  font-size: 12px;
  padding: 0 10px;

  .line-data {
    display: flex;

    .el-form-item {
      margin-bottom: 3px !important;
    }

    .el-input {
      width: 300px !important;
    }

    /deep/ .el-input__inner {
      border-radius: 2px !important;
      border: 1px solid #dcdcde !important;
    }

    /deep/ .el-form-item__label {
      font-weight: 600;
      font-size: 13px;
    }


  }

  .el-button {
    font-weight: 700;
    width: 100px;
    margin: 20px 100px;
  }

}

</style>
