<template>
  <div class="mobile-main">
    <van-nav-bar
            title="会游网络CPS移动端"
            @click-left="logout"
    />
    <div class="baseInfo-panel">
      <van-image :src="logo" width="2.3rem" height="2.5rem"/>

      <div class="baseInfo-panel-left">
        欢迎您，<span class="name-text">{{admin_name}}</span>
        可用余额：<span class="balance-text">￥{{balance}}</span>
      </div>
    </div>

    <div class="header-info">
      <p>菜单</p>
    </div>

    <div class="menu-panel">
      <van-grid :column-num="3" square>
        <van-grid-item to="/mobile/rechargeAdmin">
          <van-image :src="rechargeB" width="2rem" height="2rem"/>
          <span>给会长充值</span>
        </van-grid-item>
        <van-grid-item to="/mobile/rechargeUser">
          <van-image :src="rechargeC" width="2rem" height="2rem"/>
          <span>给玩家充值</span>
        </van-grid-item>
      </van-grid>

    </div>

    <div class="logout-panel" @click="logout">
      退出登录
    </div>

  </div>
</template>
<script>
  import { logout, getWallet } from '@/api/request'
  import logo from "@/assets/logo.png";
  import rechargeB from "@/assets/recharge_b.png";
  import rechargeC from "@/assets/recharge_c.png";
  import {Dialog, Toast} from "vant";

  export default {
    name:'mobileHome',
    data() {
      return {
        logo,
        rechargeB,
        rechargeC,
        admin_name: '会长',
        balance: 0.00,
      }
    },
    mounted() {
      let user = JSON.parse(localStorage.getItem("userInfo"));
      this.admin_name = user.nickName ? user.nickName : user.adminName;
      this.refreshBalance();
    },
    methods: {
      logout() {
        Dialog.confirm({
          message: '确认退出登录吗？',
        })
        .then(() => {
          logout().then(res => {
            if (res.data.success) {
              localStorage.removeItem('token');
              localStorage.removeItem('userInfo');
              this.$router.replace("/mobile/login");
            } else {
              Toast.fail(res.data.error.message);
            }
          });
        });
      },
      refreshBalance(){
        getWallet().then(res => {
          if (res.data.success) {
            this.balance = res.data.data.balance;
          }
        });
      },

    }
  }
</script>

<style scoped lang="scss">
  .mobile-main{
    /deep/.van-field__label span{
      font-size: 13px;
    }

    height: 100vh;
    background-color: rgba(184, 191, 188, 0.09);
    .van-nav-bar{
      background-color: #37414d;
      /deep/.van-icon,/deep/.van-nav-bar__text,/deep/.van-nav-bar__title{
        color: white;
        font-size: 14px;
      }
    }
    .baseInfo-panel{
      width: 90%;
      margin: 20px auto 0;
      background-color: white;
      text-align: center;
      div{
        text-align: left;
        padding-left: 15px;
        padding-bottom: 15px;
        font-size: 13px;
        span{
          font-weight: 600;
        }
        .name-text{
          margin-right: 20px;
        }
        .balance-text{
          color: red;
        }
      }
    }
    .header-info{
      padding-left: 5%;
      font-weight: 600;
    }
    .menu-panel{
      width: 90%;
      margin: 0 auto;
      background-color: white;
      text-align: center;
      border-radius: 5px;
      .van-grid-item__content{
        span{
          font-size: 12px;
          margin-top: 8px;
        }
      }
    }

    .logout-panel{
      position: fixed;
      display: flex;
      bottom: 10%;
      width: 100%;
      height: 40px;
      background-color: white;
      align-items: center;
      justify-content: center;
      color: red;
      font-size: 14px;
    }
  }
</style>
