<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        ><i class="el-icon-s-home"></i>首页</el-breadcrumb-item
      >
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>渠道用户</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>

    <div class="page-content">
      <el-row @keyup.enter.native="loadData(1)">
        <el-input
          v-model="queryField.user_id"
          size="small"
          placeholder="用户ID"
        ></el-input>
        <el-input
          v-model="queryField.user_name"
          size="small"
          placeholder="用户名"
        ></el-input>
        <el-input
          v-model="queryField.nick_name"
          size="small"
          placeholder="昵称"
        ></el-input>
        <el-input
          v-model="queryField.phone"
          size="small"
          placeholder="手机号码"
        ></el-input>
        <el-input
          v-model="queryField.main_user_id"
          size="small"
          placeholder="主账号id"
        ></el-input>
        <el-autocomplete
          class="inline-input"
          v-model="first"
          :fetch-suggestions="querySearch"
          placeholder="一级渠道"
          @select="handleSelect1"
        ></el-autocomplete>
        <el-autocomplete
          class="inline-input"
          v-model="second"
          :fetch-suggestions="querySearch2"
          placeholder="二级渠道"
          @select="handleSelect2"
        ></el-autocomplete>
        <br /><br />
        <el-date-picker
          size="small"
          v-model="queryField.start_time"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="开始日期"
        >
        </el-date-picker>
        <el-date-picker
          size="small"
          v-model="queryField.end_time"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="结束日期"
        >
        </el-date-picker>
        <el-button
          type="primary"
          @click="loadData(1)"
          icon="el-icon-search"
          size="small"
          >查询</el-button
        >
        <el-button
            type="primary"
            @click="moveSission"
            size="small"
        >重置</el-button
        >
        <el-button
          size="small"
          type="success"
          @click="exportExcel"
          :loading="loading"
          loading-text="下载中"
          >导出</el-button
        >
      </el-row>
      <el-table
        class="cps-table-data"
        :data="tableData"
        size="small"
        border
        :height="height"
        style="width: 100%"
      >
        <el-table-column prop="user_id" label="用户ID" width="80">
        </el-table-column>
        <el-table-column prop="user_name" label="用户名" width="230">
        </el-table-column>
        <el-table-column prop="nick_name" label="昵称" width="200">
        </el-table-column>
        <el-table-column prop="phone" label="手机号码" width="120">
        </el-table-column>
        <!--                <el-table-column-->
        <!--                        prop="main_user_id"-->
        <!--                        label="主账号id"-->
        <!--                        width="120">-->
        <!--                </el-table-column>-->
        <el-table-column prop="admin_name_1" label="一级渠道" width="140">
        </el-table-column>
        <el-table-column prop="admin_name_2" label="二级渠道" width="140">
        </el-table-column>
        <el-table-column
          prop="child_status"
          :show-overflow-tooltip="true"
          :formatter="formatChildStatus"
          label="子账号状态"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="user_status"
          :show-overflow-tooltip="true"
          :formatter="formatUserStatus"
          label="用户状态"
          width="100"
        >
        </el-table-column>
        <el-table-column prop="add_time" label="新增时间" width="140">
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="430">
          <template slot-scope="scope">
            <el-button
              @click="detail(scope.row.user_id)"
              type="text"
              size="small"
              >详情</el-button
            >
            <el-button
                @click="resetPwd(scope.row.user_id)"
                type="text"
                size="small"
            >重置密码</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="unBindPhone(scope.row.user_id)"
              >设置手机号码</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="stopUser(scope.row.user_id)"
              v-if="scope.row.user_status === 1 "
              >禁用</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="startUser(scope.row.user_id)"
              v-else-if="scope.row.user_status === 2"
              >启用</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="autoUser(scope.row.user_id)"
              >开启自助</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="unAutoUser(scope.row.user_id)"
              >关闭自助</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="moveUser(scope.row.user_id)"
              >切换渠道</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="fixed-pagination"
        @current-change="loadData"
        background
        :page-size="15"
        :current-page="currentIndex"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  queryUserList,
  subChannelDropDown,
  channelDropDown,
  forbidUser,
  startUser,
  unBindPhone,
  upBindPhone,
  autoUser,
  unAutoUser,
  moveUser,
  exportUser,
  resetUserPwd
} from '@/api/request'

  export default {
    name: 'userChannel',
    data () {
      return {
        restaurants1: [],
        restaurants2: [],
        tableData: [],
        queryField: {
          user_id: '',
          user_name: '',
          nick_name: '',
          phone: '',
          channel_first: '',
          channel_second: '',
          start_time:'',
          end_time:''
        },
        first: '',
        second: '',
        total: 0,
        currentIndex:1,
        height: window.innerHeight - 250,
        loading:false
      }
    },
    mounted () {
      this.upuserChannel();
      this.loadData(1)
      this.loadAll1()
      this.loadAll2('0')
    },
    methods: {
      upuserChannel() {
        if (sessionStorage.getItem("userChannelStor") != null) {
          let params = JSON.parse(sessionStorage.getItem('userChannelStor'));
          let params2 = JSON.parse(sessionStorage.getItem('userChannelStor2'));
          this.queryField = params,
          this.second = params2.second,
          this.first = params2.first

        }
      },
      moveSission(){
        sessionStorage.removeItem("userChannelStor");
        sessionStorage.removeItem("userChannelStor2");
        this.$router.go(0)
      },
      loadData (index) {
        if (this.queryField.start_time) {
          this.queryField.start_time += " 00:00:00";
        } else {
          this.queryField.start_time = "";
        }

        if (this.queryField.end_time) {
          this.queryField.end_time += " 23:59:59";
        } else {
          this.queryField.end_time = "";
        }
        let params = {
          pageNo: index,
          pageSize: 15,
          params:this.queryField
        }
        let sission = {
          first:this.first,
          second:this.second,
        }
        sessionStorage.setItem('userChannelStor', JSON.stringify(params.params))
        sessionStorage.setItem('userChannelStor2', JSON.stringify(sission))
        queryUserList(params).then(res => {
          this.tableData = res.data.list
          this.total = res.data.total
          this.currentIndex=index
        })
      },

      resetPwd (userId) {
        this.$confirm('是否将玩家密码重置为123456?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          resetUserPwd(userId).then(res => {
            if (res.data.success) {
              this.$message({
                type: 'success',
                message: '重置成功!'
              })
            } else {
              this.$message.error(res.data.error.message)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消禁用'
          })
        })

      },

      detail (userId) {
        this.$router.push({ path: `/userChannel/userDetail/${userId}` })
      },
      stopUser (userId) {
        this.$confirm('此操作将禁止该玩家登录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          forbidUser(userId).then(res => {
            if (res.data.success) {
              this.$message({
                type: 'success',
                message: '禁用成功!'
              })
            } else {
              this.$message.error(res.data.error.message)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消禁用'
          })
        })

      },
      startUser (userId) {
        this.$confirm('此操作将允许该玩家登录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          startUser(userId).then(res => {
            if (res.data.success) {
              this.$message({
                type: 'success',
                message: '启用成功!'
              })
            } else {
              this.$message.error(res.data.error.message)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消启用'
          })
        })

      },
      autoUser (userId) {
        this.$confirm('此操作将允许该玩家自助充值, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          autoUser(userId).then(res => {
            if (res.data.success) {
              this.$message({
                type: 'success',
                message: '启用成功!'
              })
            } else {
              this.$message.error(res.data.error.message)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消启用'
          })
        })

      },
      unAutoUser (userId) {
        this.$confirm('此操作将不允许该玩家自助充值, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          unAutoUser(userId).then(res => {
            if (res.data.success) {
              this.$message({
                type: 'success',
                message: '关闭成功!'
              })
            } else {
              this.$message.error(res.data.error.message)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消关闭'
          })
        })

      },

      //手机号码更改解绑
      unBindPhone(userId) {
        this.$prompt('请输入换绑手机号码（为空则解绑）', '手机信息', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // inputPattern: /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/,
          // inputPattern: /s{0}/,
          // inputErrorMessage: '手机号码格式不正确'
        }).then(({ value }) => {
          //判断解绑还是更换号码
          if (value == null) {
              unBindPhone(userId).then(res => {
              if (res.data.success) {
                this.$message({
                  type: 'success',
                  message: '解绑成功!'
                })
              } else {
                this.$message.error(res.data.error.message)
              }
            })
          }else{
            let param={
            "user_id":userId,
            "phone":value
          }
          upBindPhone(param).then(res => {
            if (res.data.success) {
                this.$message({
                type: 'success',
                message: '手机号码已更换!'
                })
            }
          })
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消解绑'
          })
        })
      },

      moveUser(userId) {
        this.$prompt('请输入会长id', '切换渠道', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[0-9]*$/,
          inputErrorMessage: '会长id格式不正确'
        }).then(({ value }) => {
          let param={
            "user_id":userId,
            "admin_id":value
          }
          moveUser(param).then(res => {
            if (res.data.success) {
              this.$message({
                type: 'success',
                message: '迁移成功!'
              })
            } else {
              this.$message.error(res.data.error.message)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
      },

      goUserAddPage () {
        this.$router.push({ path: '/userList/userAdd' })
      },
      formatChildStatus (row, column, cellValue) {
        let ret = ''
        if (cellValue === 1) {
          ret = '是'
        } else {
          ret = '否'
        }
        return ret
      },
      formatUserStatus (row, column, cellValue) {
        let ret = ''
        if (cellValue === 1) {
          ret = '正常'
        } else {
          ret = '禁用'
        }
        return ret
      },
      querySearch (queryString, cb) {
        let restaurants = this.restaurants1
        let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
        // 调用 callback 返回建议列表的数据
        cb(results)
      },
      querySearch2 (queryString, cb) {
        let restaurants = this.restaurants2
        let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
        // 调用 callback 返回建议列表的数据
        cb(results)
      },
      createFilter (queryString) {
        return (restaurant) => {
          return (restaurant.value.indexOf(queryString.toLowerCase()) === 0)
        }
      },
      loadAll1 () {
        let me = this
        channelDropDown().then(res => {
          me.restaurants1 = []
          let item1 = {}
          item1.value = '全部'
          item1.admin_id = ''
          me.restaurants1.push(item1)
          res.data.data.forEach(function (e) {
            if (!e.nick_name) {
              e.nick_name = e.admin_id
            }
            let item = {}
            item.value = e.nick_name
            item.admin_id = e.admin_id
            me.restaurants1.push(item)
          })
        })
      },
      loadAll2 (parentId) {
        let me = this
        subChannelDropDown(parentId).then(res => {
          me.restaurants2 = []
          let item1 = {}
          item1.value = '全部'
          item1.admin_id = ''
          me.restaurants2.push(item1)
          let item2 = {}
          item2.value = '一级自有'
          item2.admin_id = '-1'
          me.restaurants2.push(item2)
          res.data.data.forEach(function (e) {
            if (!e.nick_name) {
              e.nick_name = e.admin_id
            }
            let item = {}
            item.value = e.nick_name
            item.admin_id = e.admin_id
            me.restaurants2.push(item)
          })
        })
      },
      handleSelect1 (item) {
        this.queryField.channel_first = item.admin_id
        if (item.admin_id === '') {
          this.loadAll2('0')
        } else {
          this.loadAll2(item.admin_id)
        }
      },
      handleSelect2 (item) {
        this.queryField.channel_second = item.admin_id
      },
      exportExcel(){
        if (this.queryField.start_time) {
          this.queryField.start_time += " 00:00:00";
        } else {
          this.queryField.start_time = "";
        }

        if (this.queryField.end_time) {
          this.queryField.end_time += " 23:59:59";
        } else {
          this.queryField.end_time = "";
        }
        this.loading=true;
        exportUser(this.queryField).then(res => {
          this.downloadGO(res.data)
        });
      },

      downloadGO (data) {
        if (!data) {
          return
        }
        let url = window.URL.createObjectURL(new Blob([data], {type: "application/vnd.ms-excel"}))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download',"用户导出.xls")
        document.body.appendChild(link)
        link.click()
        this.loading=false;
      },
    },
  }
</script>


