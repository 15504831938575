<template>
  <div class="cps-main-page" :style="styleVar">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加礼包码</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="first">
      <div class="main-border">
        <el-form ref="form" label-width="120px">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="礼包ID">
                <el-input v-model="addField.giftId" size="small"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="16">
              <el-form-item label="礼包码">
                <el-input type="textarea" v-model="Code" placeholder="礼包码以逗号的形式分开"
                          :autosize="{ minRows: 2, maxRows: 35}" size="small"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="16" align="center">
              <el-form-item>
                <el-button type="primary" @click="onSubmit">提交</el-button>
                <el-button @click="goBack">返回</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="second">
      <el-table class="cps-table-data"
                :data="tableData" size="small"
                border
                :height="height"
                style="width: 100%">
        <el-table-column
            prop="CodeId"
            label="编号"
            width="80">
        </el-table-column>
        <el-table-column
            prop="Code"
            label="礼包码"
            width="120">
        </el-table-column>
<!--        <el-table-column-->
<!--            prop="address"-->
<!--            label="操作">-->
<!--        </el-table-column>-->
      </el-table>
    </div>
  </div>
</template>

<script>

import {addGiftCode} from "@/api/request";

export default {
  name: 'GameAppEdit',
  data() {
    return {
      addField:{
        giftId: '',
        giftCode:'',
      },
      Code: '',
      tableData: [],
      height: window.innerHeight - 210,
      styleVar: {
        "--clientHeight": window.innerHeight - 140 + "px",
      },
    }
  },
  mounted() {
    this.addField.giftId = this.$route.params.pathMatch;
  },
  methods: {
    onSubmit() {
      let params = {
        giftId : this.addField.giftId,
        giftCode : this.addField.giftCode
      }
      addGiftCode(params).then(res => {
        if (res.data.success) {
          this.$message.success('新增成功');
          this.$router.push({path: "/gift/code/list/"});
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    goBack() {
      this.$router.push({path: "/gift/code/list/"});
    },
  },
  watch: {
    Code(newVal, oldVal) {
      this.tableData = [];
      this.addField.giftCode = [];
      let giftCodeList;
      let giftCode = [];
      let number = 1;
      giftCodeList = (newVal.replace(/[ \n]/g, "")).split(/[，,]/g);
      for (let i = 0; i < giftCodeList.length; i++) {
        let item = {CodeId: '', Code: ''}
        if (giftCodeList[i] != '') {
          item.CodeId = number++;
          item.Code = giftCodeList[i];
          giftCode.push(giftCodeList[i]);
          this.tableData.push(item);
        }
      }
      console.log(giftCode);
      this.addField.giftCode = giftCode;
    }
  }
}
</script>

<style scoped lang="scss">
.main-border {
  width: 100%;
  padding: 0 12px;
}

.el-form-item {
}

.el-input {
  width: 300px !important;
}

.el-select {
  width: 300px;
}

/deep/ .el-input__inner {
  border-radius: 2px !important;
  border: 1px solid #aaaaac !important;
}

.el-button {
  font-weight: 700;
  width: 100px;
}

/deep/ .el-form-item__label {
  font-weight: 600;
  font-size: 13px;
}

.first {
  width: 49.5%;
  float: left;
  height: var(--clientHeight);
}

.second {
  width: 49.5%;
  float: left;
  height: var(--clientHeight);
}

</style>
