<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
      <el-breadcrumb-item>开服新增</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-border">
      <el-form :rules="rules" ref="rulesForm" :model="form" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="游戏id" prop="gameId">
              <el-input v-model="form.gameId" size="small"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="区服名" prop="serverName">
              <el-input v-model="form.serverName" size="small"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="开服时间" prop="openTime">
              <el-date-picker
                  v-model="form.openTime"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="false">
            <el-form-item label="区服编号" prop="serverCode">
              <el-input v-model="form.serverCode" size="small"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="16" align="center">
            <el-form-item>
              <el-button type="primary" @click="onSubmit('rulesForm')">提交</el-button>
              <el-button @click="$router.replace('/game/server/list')">返回</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import {addOpenServer} from '@/api/request'

export default {
  name: "serverAdd",
  data(){
    var validateDate = (rule, value, callback) => {
      if(value === ''){
        callback(new Error('请输入日期'));
      }else {
        callback();
      }
    };
    return {
      form:{
        gameId: '',
        serverName: '',
        openTime: '',
        serverCode: ''
      },
      rules:{
        gameId:[
          { required: true, message: '游戏id不能为空', trigger: 'change' }
        ],
        serverName:[
          { required: true, message: '区服不能为空', trigger: 'change' }
        ],
        openTime:[
          { validator: validateDate, trigger: 'blur' }
        ]
      },
    }
  },
  mounted() {
  },
  methods:{
    onSubmit(fromName){
      this.$refs[fromName].validate((valid) => {
        if (valid) {
          let params = {
            gameId: this.form.gameId,
            serverName: this.form.serverName,
            openTime: this.form.openTime,
            serverCode: this.form.serverCode
          }
          addOpenServer(params).then(res => {
            if(res.data.success){
              this.$message.success(res.data.data)
              this.$router.replace('/game/server/list')
            }else{
              this.$message.error(res.data.error.message)
            }
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>