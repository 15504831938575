<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
      <el-breadcrumb-item>开服列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="page-content">
      <el-row @keyup.enter.native="loadData(1)">
        <el-input v-model="queryField.serverId" size="small" placeholder="开服ID" />
        <el-input v-model="queryField.gameId" size="small" placeholder="游戏ID" />
        <el-input v-model="queryField.serverName" size="small" placeholder="游戏区服名" />
        <el-date-picker
            v-model="queryField.time"
            size="small"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
        <el-button type="primary" icon="el-icon-search" size="small" @click="loadData(1)">查询</el-button>
        <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置</el-button>
        <el-button type="primary" icon="el-icon-plus" size="small" @click="$router.push('/game/server/add')">新增</el-button>
        <el-button type="primary" icon="el-icon-plus" size="small" @click="$router.push('/game/server/batchAdd')">批量添加</el-button>
        <el-button type="danger" icon="el-icon-delete" size="small" @click="isDelete = !isDelete">删除</el-button>
        <el-button type="danger" icon="el-icon-delete" size="small" @click="toDelete" v-show="isDelete">确认删除</el-button>
      </el-row>
      <el-table class="cps-table-data"
                :data="tableData"
                size="small"
                border
                v-loading="loading"
                :height="height"
                tooltip-effect="dark"
                @selection-change="handleSelectionChange"
                id="rebateSetTable">
        <el-table-column
            v-if="isDelete"
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column
            prop="server_id"
            width="80"
            label="开服id">
        </el-table-column>
        <el-table-column
            prop="game_id"
            width="80"
            label="游戏id">
        </el-table-column>
        <el-table-column
            prop="game_name"
            width="120"
            label="游戏名">
        </el-table-column>
        <el-table-column
            v-if="false"
            prop="server_code"
            width="200"
            label="区服编号">
        </el-table-column>
        <el-table-column
            prop="server_name"
            width="120"
            label="区服名">
        </el-table-column>
        <el-table-column
            prop="open_time"
            width="150"
            label="开服时间">
        </el-table-column>
        <!--                <el-table-column
                        label="操作"
                        width="120">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="discard(scope.row.server_id)">废弃</el-button>
                        </template>
                    </el-table-column>-->
      </el-table>
      <!--底部分页-->
      <el-pagination class="cps-table-data"
                     @current-change="loadData"
                     background
                     :page-size="pageSize"
                     :current-page="currentIndex"
                     layout="total, prev, pager, next"
                     :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>

import {delOpenServer, queryServerList} from '@/api/request';

export default {
  name: "serverList",
  data(){
    return {
      queryField: {
        serverId: '',
        gameId: '',
        serverName: '',
        time: [],
        start_time: '',
        end_time: '',
      },
      tableData:[],
      total: 0,
      pageSize: 20,
      height: window.innerHeight - 210,
      currentIndex: 1,
      loading: false,
      isDelete: false,
      multipleSelection: [],
    }
  },
  mounted() {
    this.loadData(1);
  },
  methods:{
    loadData(index){
      this.loading = true;
      if (this.queryField.time != null && this.queryField.time.length > 0) {
        this.queryField.start_time = this.queryField.time[0] + " 00:00:00";
        this.queryField.end_time = this.queryField.time[1] + " 23:59:59";
      } else {
        this.queryField.start_time = "";
        this.queryField.end_time = "";
      }

      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      queryServerList(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentIndex = index;
        this.loading = false;
      })
    },
    toDelete(){
      this.$confirm("确认要删除这些区服吗！(删除操作不可逆)",{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = [];
        this.multipleSelection.forEach(e => ids.push(e.server_id));
        delOpenServer(ids).then(res => {
          if(res.data.success){
            this.$message.success("删除成功");
            this.loadData(this.currentIndex);
          }else {
            this.$message.error(res.data.error.message);
          }
        })
      })
    },
    handleSelectionChange(value){
      this.multipleSelection = value;
    },
    reset(){
      this.queryField={
        serverId: '',
        gameId: '',
        serverName: '',
        time: [],
      }
      this.loadData(1);
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .is-active {
  background-color: unset !important;
}
</style>