<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>提成管理</el-breadcrumb-item>
      <el-breadcrumb-item>我的提现记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>

    <div class="page-content">
    <el-row @keyup.enter.native="loadData(1)">
      <el-select v-model="queryField.status" size="small" placeholder="提现状态">
        <el-option key="" label="提现状态" value=""/>
        <el-option key="1" label="未打款" value="1"/>
        <el-option key="2" label="已打款" value="2"/>
      </el-select>

      <el-date-picker
              v-model="queryField.time"
              size="small"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
      </el-date-picker>

      <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
    </el-row>
    <el-table class="cps-table-data"
            :data="tableData" size="small"
            border :height="height"
            style="width: 100%">
      <el-table-column
              prop="cash_out_id"
              label="id"
              width="80">
      </el-table-column>
      <el-table-column
              prop="amount"
              label="提现金额"
              width="120">
      </el-table-column>
      <el-table-column
          prop="zfb_account"
          label="支付宝账号"
          width="240">
      </el-table-column>
      <el-table-column
          prop="status"
          label="提现状态"
          width="120">
        <template slot-scope="scope">
          <div :style="{'color':scope.row.status === 2 ? 'rgba(34,153,4,0.94)' : 'rgba(141,141,138,0.94)'}">
            {{formatStatus(scope.row.status)}}
          </div>
        </template>
      </el-table-column>
      <el-table-column
              prop="add_time"
              label="提现时间"
              width="160">
      </el-table-column>
      <el-table-column
              prop="update_time"
              label="打款时间"
              width="160">
        <template slot-scope="scope">
          <div v-if="scope.row.status === 2">
            {{scope.row.update_time}}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="fixed-pagination"
            @current-change="loadData"
            background
            :page-size="15"
            :current-page="currentIndex"
            layout="total, prev, pager, next"
            :total="total">
    </el-pagination>
    </div>
  </div>
</template>
<script>
  import { queryMyCashOutList } from '@/api/request'

  export default {
    name: 'CashOut',
    data() {
      return {
        tableData: [],
        queryField: {
          admin_id: '',
          admin_name: '',
          status: '',
          time: [],
          start_time: '',
          end_time: ''
        },
        total: 0,
        currentIndex:1,
        height: window.innerHeight - 210,
      };
    },
    mounted() {
      this.loadData(1);
    },
    methods: {
      loadData(index) {
        if (this.queryField.time != null && this.queryField.time.length > 0) {
          this.queryField.start_time = this.queryField.time[0] + " 00:00:00";
          this.queryField.end_time = this.queryField.time[1] + " 23:59:59";
        }
        else {
          this.queryField.start_time = "";
          this.queryField.end_time = "";
        }
        let params = {
          pageNo: index,
          pageSize: 15,
          params : this.queryField,
        }
        queryMyCashOutList(params).then(res => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.currentIndex=index;
        });
      },
      formatStatus(value) {
        if (value === 1) {
          return "未打款"
        } else if (value === 2) {
          return "已打款"
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  /deep/ .is-active {
    background-color: unset !important;
  }
  .cps-table-data {
    /deep/ td {
      padding: 7.5px 0 !important;
    }
  }
</style>
