<template>
  <div class="cps-main-page">
    <el-breadcrumb separator=">">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="page-content">
    <el-row :gutter="30">
      <el-col :lg="6" :sm="12" v-for="(grid,i) in grids" :key="grid.name">
        <div
                class="dashboard-statistic"
                :class="grid.backgroundColor"
                @click="$emit('onChangeType', grid.type)"
        >
          <div class="grid-left">
            <el-image :src="grid.icon" style="width: 50px; height: 50px"></el-image>
          </div>

          <div class="grid-right">
            <p class="grid-name">{{ grid.name }}</p>
            <span v-if="i === 1 || i === 2 || i === 5 || i === 6 ">￥</span>
            <countTo
                    class="grid-number"
                    :startVal="0"
                    :endVal="grid.number"
                    :duration="3000"
            ></countTo>
          </div>
        </div>
      </el-col>
    </el-row>

  </div>

  <el-dialog title="冻结余额提现" :visible.sync="cashOutDialogShow">

    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="提现金额" prop="amount">
        <el-input v-model="form.amount" size="small" placeholder="请输入提现金额"></el-input>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="cashOutDialogShow = false">取 消</el-button>
      <el-button type="primary" @click="cashOut">确 定</el-button>
    </div>
  </el-dialog>

  </div>
</template>
<script>

  import { cashOut } from '@/api/request'

  import countTo from 'vue-count-to'
  import userCountImg from '@/assets/userCount.png'
  import zlsImg from '@/assets/zls.png'
  import syyeImg from '@/assets/syye.png'
  import todayRegisiterImg from '@/assets/todayRegisiter.png'
  import todayActiveImg from '@/assets/todayActive.png'
  import todayRechargeImg from '@/assets/todayRecharge.png'
  import lsImg from '@/assets/ls.png'
  import monthLsImg from '@/assets/monthLs.png'
  import {adminIndexCount} from '@/api/request'

export default {
  name: 'welcome',
  components: { countTo },
  data() {
    return {
      userCountImg,
      grids: [
        {
          type: '',
          icon: userCountImg,
          name: '总用户数',
          number: 0,
          backgroundColor: 'grid--red'
        },
        {
          type: '',
          icon: monthLsImg,
          name: '总流水',
          number: 0,
          backgroundColor: 'grid--blue'
        },
        {
          type: 'profit',
          icon: syyeImg,
          name: '收益余额',
          number: 0,
          backgroundColor: 'grid--green'
        },
        {
          type: '',
          icon: todayRegisiterImg,
          name: '今日注册',
          number: 0,
          backgroundColor: 'grid--yellow'
        },
        {
          type: '',
          icon: todayActiveImg,
          name: '今日活跃',
          number: 0,
          backgroundColor: 'grid--gr'
        },
        {
          type: '',
          icon: todayRechargeImg,
          name: '今日充值',
          number: 0,
          backgroundColor: 'grid--red_yell'
        },
        {
          type: '',
          icon: lsImg,
          name: '本月流水',
          number: 0,
          backgroundColor: 'grid--zi'
        }
      ],
      rules: {
        amount: [
          {required: true, message: '请输入提现金额'},
          {pattern: /^\d+([.]\d{1,2})?$/, trigger: 'blur', message: '提现金额格式不正确'}
        ],
      },
      cashOutDialogShow: false,
      form: {
        amount: '',
      },
    }
  },
  created() {
    this.getGridData()
  },
  mounted() {
    this.$on("onChangeType", gridType => {
      if (gridType === 'profit') {
        this.cashOutDialogShow = true;
      }
    });
  },
  methods: {
    getGridData() {
      adminIndexCount().then(res => {
        if (res.data.success) {
          const data=[];
          data[0] = res.data.data.userTotalCount;
          data[1] = res.data.data.totalRecharge;
          data[2] = res.data.data.profitBalance;
          data[3] = res.data.data.todayRegisterCount;
          data[4] = res.data.data.todayLoginCount;
          data[5] = res.data.data.todayRecharge;
          data[6] = res.data.data.monthRecharge;
          this.grids.forEach((item, index) => {
            item.number = Object.values(data)[index]
          })
        } else {
          this.$alert(res.data.error.message, '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
        }
      });
    },
    cashOut() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          cashOut(this.form.amount).then(res => {
            if (res.data.success){
              this.cashOutDialogShow = false;
              this.$message.success('操作成功，请等待客服打款');
              this.$refs['form'].resetFields();
              this.getGridData();
            }
            else {
              this.$message.error(res.data.error.message);
            }
          });
        } else {
          return false;
        }
      });

    }
  }
}
</script>

<style lang="scss" scoped>

.cps-main-page{
  padding-right: 10px;

  /deep/.el-dialog{
    max-width: 600px;

    .input-label{

    }

    .input-data{
      margin-top: 10px;
    }
  }
}

.dashboard-statistic {
  display: flex;
  position: relative;
  height: 108px;
  color: #fff;
  box-shadow: 0 0 10px rgba(100, 100, 100, 0.5);
  cursor: pointer;
  margin-bottom: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.95s;
    transition: width 0.5s;
  }

  &:hover::before {
    width: 100%;
    box-sizing: border-box;
  }

  .grid-left {
    width: 100px;
    line-height: 108px;
    vertical-align: center;
    text-align: center;
    /*实现垂直居中*/
    display: flex;
    align-items: center;
    justify-content: center;

    .grid-icon {
      font-size: 48px;
      vertical-align: middle;
    }
  }

  .grid-right {
    padding-left: 16px;

    p {
      color: #fff !important;
    }

    .grid-name {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .grid-number {
      font-size: 30px;
    }
  }
}

.grid {
  &--green {
    background-color: #06d6a0;
  }

  &--yellow {
    background-color: #ffd166;
  }

  &--blue {
    background-color: #06aed5;
  }

  &--red {
    background-color: #ef476f;
  }

  &--blue {
    background-color: #6464f0;
  }

  &--red_yell {
    background-color: #df673f;
  }

  &--zi {
    background-color: #d861f0;
  }

  &--gr {
    background-color: #349f3f;
  }
}
</style>
