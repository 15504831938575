<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>修改绑定手机</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="main-border">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="原手机号">
          <el-input v-model="form.name" size="small"></el-input>
          <el-button style="margin-left: 15px" size="small">发送验证码</el-button>
        </el-form-item>
        <el-form-item label="验证码" size="small">
          <el-input v-model="form.name" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">下一步</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main-border" v-show="false">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="新手机号">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item >
          <el-button>发送验证码</el-button>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'gameAdd',
  data() {
    return {
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      }
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    }
  }
}
</script>

<style scoped lang="scss">
  .el-input {
    width: 250px !important;
    min-width: 200px;
  }
  /deep/.el-input__inner {
    border-radius: 2px !important;
    border: 1px solid #aaaaac !important;
  }
  /deep/.el-form-item__label{
    font-weight: 600;
    font-size: 13px;
  }
  .main-button{
    font-weight: 700;
    width: 120px;
  }
  .el-button {
    font-weight: 700;
    width: 100px;
  }
</style>
