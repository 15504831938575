<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>礼包管理</el-breadcrumb-item>
      <el-breadcrumb-item>抽奖记录列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="page-content">
      <el-row @keyup.enter.native="loadData(1)">
        <el-input v-model="queryField.gameId" size="small" placeholder="游戏ID"></el-input>
        <el-button type="primary" icon="el-icon-search" size="small" @click="loadData(1)">查询
        </el-button>
      </el-row>
      <el-table class="cps-table-data"
                :data="tableData" size="small"
                border
                :height="height"
                style="width: 100%">
        <el-table-column
            prop="gameId"
            label="游戏ID"
            width="80">
        </el-table-column>
        <el-table-column
            prop="gameName"
            label="游戏名称"
            width="150">
        </el-table-column>
        <el-table-column
            prop="onlineNumber"
            label="在线人数"
            width="200">
        </el-table-column>
<!--        <el-table-column-->
<!--            label="操作">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button v-if="scope.row.provide === 0" type="text" size="small" @click="providePass(scope.row,1)">通过-->
<!--            </el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
      <el-pagination class="cps-table-data"
                     @current-change="loadData"
                     background
                     :page-size="18"
                     :current-page="currentIndex"
                     layout="total, prev, pager, next"
                     :total="total">
      </el-pagination>


    </div>
  </div>
</template>

<script>
import {listOnlineNumber} from "@/api/request";

export default {
  name: "OnlineList",
  data() {
    return {
      tableData:[],
      currentIndex:1,
      total: 0,
      height: window.innerHeight - 210,
      queryField: {
        gameId: '',
      }
    }
  },
  mounted() {
    this.loadData(1)
  },
  methods: {
    loadData(index) {
      let params = {
        pageNo: index,
        pageSize: 18,
        params: this.queryField,
      }
      listOnlineNumber(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentIndex = index;
      })
    },
  }
}
</script>

<style scoped>

</style>