<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>

    <div class="page-content">
    <el-row @keyup.enter.native="loadData(1)">
      <el-input v-model="queryField.user_id" size="small" placeholder="用户ID"></el-input>
      <el-input v-model="queryField.user_name" size="small" placeholder="用户名"></el-input>
      <el-input v-model="queryField.nick_name" size="small" placeholder="昵称"></el-input>
      <el-input v-model="queryField.phone" size="small" placeholder="手机号码"></el-input>
      <el-input v-model="queryField.main_user_id" size="small" placeholder="总账号id"></el-input>
      <el-date-picker
          size="small"
          v-model="queryField.start_time"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="开始日期">
      </el-date-picker>
      <el-date-picker
          size="small"
          v-model="queryField.end_time"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="结束日期">
      </el-date-picker>
      <el-button type="primary" @click="loadData(1)" icon="el-icon-search" size="small">查询</el-button>
      <el-button type="primary" @click="goUserAddPage" icon="el-icon-plus" size="small">新增</el-button>
    </el-row>
    <el-table class="cps-table-data"
                :data="tableData" size="small"
                border
                :height="height"
                style="width: 100%">

      <el-table-column
              prop="user_id"
              label="用户ID"
              width="80">
      </el-table-column>
      <el-table-column
              prop="user_name"
              label="用户名"
              width="230">
      </el-table-column>
      <el-table-column
              prop="nick_name"
              label="昵称"
              width="230">
      </el-table-column>
      <el-table-column
              prop="phone"
              label="手机号码"
              width="120">
      </el-table-column>
      <el-table-column
          prop="main_user_id"
          label="主账号id"
          width="120">
      </el-table-column>
      <el-table-column
              prop="child_status"
              :show-overflow-tooltip="true"
              :formatter="formatChildStatus"
              label="子账号状态"
              width="100">
      </el-table-column>
      <el-table-column
              prop="add_time"
              label="新增时间"
              width="140">
      </el-table-column>
      <el-table-column
              label="操作">
          <template slot-scope="scope">
            <el-button @click="detail(scope.row.user_id)" type="text" size="small">详情</el-button>
          </template>
      </el-table-column>
    </el-table>
    <el-pagination class="fixed-pagination"
            @current-change="loadData"
            background
            :current-page="currentIndex"
            :page-size="15"
            layout="total, prev, pager, next"
            :total="total">
    </el-pagination>
  </div>
  </div>
</template>
<script>
import {queryMyUserList} from '@/api/request'
  export default {
    name: 'userList',
    data() {
      return {
        tableData: [],
        queryField: {
          user_id: '',
          user_name: '',
          nick_name: '',
          phone: '',
          main_user_id:'',
          start_time:'',
          end_time:''
        },
        total: 0,
        currentIndex:1,
        height: window.innerHeight - 210,
        loading:false
      }
    },
    mounted() {
      this.loadData(1);
    },
    methods: {
      loadData(index) {
        if (this.queryField.start_time) {
          this.queryField.start_time += " 00:00:00";
        } else {
          this.queryField.start_time = "";
        }

        if (this.queryField.end_time) {
          this.queryField.end_time += " 23:59:59";
        } else {
          this.queryField.end_time = "";
        }
        let params = {
          pageNo: index,
          pageSize: 15,
          params: this.queryField
        }
        queryMyUserList(params).then(res => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.currentIndex=index
        })
      },
      detail(userId) {
        console.log(userId)
        this.$router.push({ path: `/userList/userDetail/${userId}`});
      },
      goUserAddPage() {
        this.$router.push({ path: "/userList/userAdd" });
      },
      formatChildStatus(row, column, cellValue) {
        let ret = ''
        if (cellValue===1) {
          ret = "是"
        } else {
          ret = "否"
        }
        return ret;
      },

    },
  }
</script>
