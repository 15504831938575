<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>提成管理</el-breadcrumb-item>
      <el-breadcrumb-item>提成查询</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>

    <div class="page-content">
      <el-row @keyup.enter.native="loadData(1)">
        <el-input v-model="queryField.cp_order_id" size="small" placeholder="cp订单号"></el-input>
        <el-input v-model="queryField.user_id" size="small" placeholder="用户ID"></el-input>
        <el-input v-model="queryField.user_name" size="small" placeholder="用户名"></el-input>
        <el-input v-model="queryField.order_amount" size="small" placeholder="充值>="></el-input>
        <el-autocomplete
            v-if="isSupper"
            class="inline-input"
            v-model="first"
            :fetch-suggestions="querySearch"
            value-key="nickName"
            placeholder="一级渠道"
            @select="handleSelect1"
        ></el-autocomplete>
        <el-autocomplete
            v-if="isSupper"
            class="inline-input"
            value-key="nickName"
            v-model="second"
            :fetch-suggestions="querySearch2"
            placeholder="二级渠道"
            @select="handleSelect2"
        ></el-autocomplete>
        <br><br>
        <el-date-picker
            v-model="queryField.time"
            size="small"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>

        <el-button type="primary" icon="el-icon-search" @click="loadData(1)" size="small">查询</el-button>
      </el-row>
      <el-row style="background-color: #f7f5f5; margin-top: 15px;padding-left: 10px;">
        <el-col :lg="3" key="总用户数">
          <div class="dashboard-statistic">
            <div class="grid-right">
              <p class="grid-name">订单金额(￥):{{orderMoney}}</p>
            </div>
          </div>
        </el-col>
        <el-col :lg="3" key="一级分成">
          <div class="dashboard-statistic">
            <div class="grid-right">
              <p class="grid-name">一级分成(￥):{{ firstMoney }}</p>
            </div>
          </div>
        </el-col>
        <el-col :lg="3" key="二级分成">
          <div class="dashboard-statistic">
            <div class="grid-right">
              <p class="grid-name">二级分成(￥):{{ secondMoney }}</p>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-table class="cps-table-data"
                :data="tableData" size="small"
                border :height="height"
                style="width: 100%">
        <el-table-column
            prop="share_id"
            label="ID"
            width="80">
        </el-table-column>
        <el-table-column
            prop="order_id"
            label="订单ID"
            width="120">
        </el-table-column>

        <el-table-column
            label="cp订单ID"
            prop="cp_order_id"
            width="120">
        </el-table-column>
        <el-table-column
            label="时间"
            prop="add_time"
            width="160">
        </el-table-column>
        <el-table-column
            label="用户ID"
            width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.user_id }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="用户名"
            width="230">
          <template slot-scope="scope">
            <span>{{ scope.row.user_name }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="order_amount"
            label="订单金额"
            width="120">
        </el-table-column>
        <el-table-column
            prop="discount_amount"
            label="优惠金额"
            width="120">
        </el-table-column>
        <el-table-column
            prop="share_amount"
            label="一级提成"
            width="120">
        </el-table-column>
        <el-table-column
            prop="share_amount_sub"
            label="二级提成"
            width="120">
        </el-table-column>

      </el-table>
      <el-pagination class="cps-table-data"
                     @current-change="loadData"
                     background
                     :page-size="15"
                     :current-page="currentIndex"
                     layout="total, prev, pager, next"
                     :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {channelDropDown, queryOrderShareList, subChannelDropDown, getOrderShareCount} from '@/api/request';

export default {
  name: 'orderPercentage',
  data() {
    return {
      userNum: '',
      firstMoney: '',
      secondMoney: '',
      orderMoney: '',
      isSupper: false,
      restaurants1: [],
      restaurants2: [],
      tableData: [],
      first: '',
      second: '',
      queryField: {
        cp_order_id: '',
        user_id: '',
        user_name: '',
        order_amount: '',
        time: [],
        start_time: '',
        end_time: '',
        channel_first: '',
        channel_second: '',
      },
      total: 0,
      currentIndex:1,
      height: window.innerHeight - 310,
    }
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.isSupper = user.groupId === "1";
    this.loadData(1);
    this.loadAll1();
    this.loadAll2();
    this.loadCountData();
  },
  methods: {
    loadCountData() {
      if (this.queryField.time != null && this.queryField.time.length > 0) {
        this.queryField.start_time = this.queryField.time[0] + " 00:00:00";
        this.queryField.end_time = this.queryField.time[1] + " 23:59:59";
      } else {
        this.queryField.start_time = "";
        this.queryField.end_time = "";
      }
      getOrderShareCount(this.queryField).then(res => {
        if (res.data.success) {
          let result = res.data.data;
          this.firstMoney=result.firstMoney;
          this.secondMoney=result.secondMoney;
          this.orderMoney=result.orderMoney;
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    loadData(index) {
      if (this.queryField.time != null && this.queryField.time.length > 0) {
        this.queryField.start_time = this.queryField.time[0] + " 00:00:00";
        this.queryField.end_time = this.queryField.time[1] + " 23:59:59";
      } else {
        this.queryField.start_time = "";
        this.queryField.end_time = "";
      }
      let params = {
        pageNo: index,
        pageSize: 15,
        params: this.queryField,
      }
      queryOrderShareList(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentIndex = index;
      });
      this.loadCountData();
    },
    save(row) {
      console.log(row)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.indexOf(queryString.toLowerCase()) === 0)
      }
    },
    querySearch(queryString, cb) {
      let restaurants = this.restaurants1
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    querySearch2(queryString, cb) {
      let restaurants = this.restaurants2
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    handleSelect1(item) {
      this.queryField.channel_first = item.value
      if (item.value === '') {
        this.loadAll2('0')
      } else {
        this.loadAll2(item.value)
      }

    },
    handleSelect2(item) {
      this.queryField.channel_second = item.value
    },
    loadAll1() {
      let me = this
      channelDropDown().then(res => {
        me.restaurants1 = []
        let item1 = {}
        item1.value = ''
        item1.nickName = '全部'
        me.restaurants1.push(item1)
        res.data.data.forEach(function (e) {
          if (!e.nick_name) {
            e.nick_name = e.admin_id
          }
          let item = {}
          item.value = e.admin_id
          item.nickName = e.nick_name
          me.restaurants1.push(item)
        })
      })
    },
    loadAll2(parentId) {
      let me = this
      subChannelDropDown(parentId).then(res => {
        me.restaurants2 = []
        let item1 = {}
        item1.value = ''
        item1.nickName = '全部'
        me.restaurants2.push(item1)
        let item2 = {}
        item2.value = '-1'
        item2.nickName = '一级自有'
        me.restaurants2.push(item2)
        res.data.data.forEach(function (e) {
          if (!e.nick_name) {
            e.nick_name = e.admin_id
          }
          let item = {}
          item.value = e.admin_id
          item.nickName = e.nick_name
          me.restaurants2.push(item)
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .is-active {
  background-color: unset !important;
}

.cps-table-data {
  /deep/ td {
    padding: 7.5px 0 !important;
  }
}
</style>
