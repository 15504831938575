<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限分配</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>

    <!--<el-alert
            :title="groupName"
            type="success"
            :closable="false"
            description=" ">
    </el-alert>-->
    <el-alert
            :title="groupName"
            type="warning"
            show-icon
            :closable="false"
            description="如果没有给 [查询] 功能授权，则表示本角色没有此菜单功能权限">
    </el-alert>
    <div class="page-content">
    <el-table class="cps-table-data"
            :data="tableData"
            size="small"
            border :height="height"
            :span-method="spanMethod"
            style="width: 100%">
      <el-table-column
              prop="parent_menu"
              label="上级菜单"
              width="180">
      </el-table-column>
      <el-table-column
              prop="menu_name"
              label="菜单名称"
              width="280">
      </el-table-column>

      <el-table-column
              label="授权">
          <template slot-scope="scope">
            <el-checkbox class="cps-checkbox" v-for="(item,i) in tableData[scope.$index].buttons"
                         :true-label="1" :false-label="0"
                          @change="changeAuth(item,scope.$index,i)"
                          v-model="item.open" :label="item.button_name" size="medium"></el-checkbox>

          </template>
      </el-table-column>
    </el-table>

    <el-col :span="8">
      <el-button @click="back">返回</el-button>
    </el-col>

   <!-- <el-row>
      <el-button  type="primary" icon="el-icon-circle-check" size="small">保存</el-button>
    </el-row>-->

  </div>
  </div>
</template>
<script>
  import { getMenuList,doAuth } from '@/api/request'
  export default {
    name: 'userList',
    data() {
      return {
        tableData : [],
        total: 2,
        mo: true,
        groupId: 0,
        groupName: '',
        height: window.innerHeight - 250,
      }
    },
    mounted() {
      const data = this.$route.params;
      this.groupId = data.group_id;
      this.groupName = "授权角色：" + data.group_name;
      getMenuList(this.groupId).then(res => {
        if (res.data.success) {
          this.tableData = res.data.data;
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    methods: {
      back() {
        this.$router.push({path: "/roleManage"});
      },
      loadData(index) {
      },
      detail(row) {
        console.log(row)
      },
      changeAuth(item,dataIndex,buttonIndex) {
        let params = {
          groupId: this.groupId,
          menuId: item.button_id,
          access: item.open
        };
        doAuth(params).then(res => {
          if (res.data.success) {
            this.$message.success("操作成功");
          } else {
            this.$message.error(res.data.error.message);
          }
        });
      },
      getRowSpan(span, parentId, rowIndex) {
        if (span === 1 && rowIndex > 0 && parentId === this.tableData[rowIndex - 1].parent_id ) {
          return 0;
        }
        if (rowIndex === this.tableData.length - 1 ) {
          return span;
        }
        if (this.tableData[rowIndex + 1].parent_id !== parentId) {
          return span;
        }
        return this.getRowSpan(++span, parentId, ++rowIndex);
      },
      spanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          let span = this.getRowSpan(1, row.parent_id, rowIndex);
          if (span === 0) {
            return {
              rowspan: 0,
              colspan: 0
            };
          } else {
            return {
              rowspan: span,
              colspan: 1
            };
          }
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .cps-main-page{
    .cps-table-data{
      margin-bottom: 15px;
      /deep/ .cell{
        line-height: 30px !important;
      }
      .el-checkbox {
        margin-right: 15px;
        /deep/ .el-checkbox__label {
          font-size: 12px;
        }
      }
    }

    .el-button{
      font-weight: 700;
      width: 80px;
      margin-bottom: 10px;
    }
  }
</style>
