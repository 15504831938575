<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        ><i class="el-icon-s-home"></i>首页</el-breadcrumb-item
      >
      <el-breadcrumb-item>礼包管理</el-breadcrumb-item>
      <el-breadcrumb-item>领取记录列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="page-content">
      <el-row @keyup.enter.native="loadData(1)">
        <el-input
          v-model="queryField.userId"
          size="small"
          placeholder="用户ID"
        ></el-input>
        <el-input
          v-model="queryField.userName"
          size="small"
          placeholder="用户名"
        ></el-input>
        <el-input
            v-model="queryField.roleName"
            size="small"
            placeholder="角色名"
        ></el-input>
        <el-input
          v-model="queryField.giftId"
          size="small"
          placeholder="礼包ID"
        ></el-input>
        <el-select
          v-model="queryField.giftIssueType"
          clearable
          placeholder="礼包类型"
        >
          <el-option
            v-for="item in options.giftIssueType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="queryField.giftState"
          clearable
          placeholder="礼包状态"
        >
          <el-option
            v-for="item in options.giftState"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="loadData(1)"
          icon="el-icon-search"
          size="small"
          >查询</el-button
        >
      </el-row>

      <el-table
        class="cps-table-data"
        :data="tableData"
        size="small"
        :height="height"
        border
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="领取ID：">
                <span>{{ props.row.giftRecordId }}</span>
              </el-form-item>
              <el-form-item label="礼包ID：">
                <span>{{ props.row.giftId }}</span>
              </el-form-item>
              <el-form-item label="上报id：">
                <span>{{ props.row.roleUserReport }}</span>
              </el-form-item>
              <el-form-item label="礼包码：">
                <span>{{ props.row.giftCode }}</span>
              </el-form-item>
              <el-form-item label="拒绝理由：">
                <span>{{ props.row.giftRejectReason }}</span>
              </el-form-item>
              <el-form-item label="礼包消费要求：">
                <span>{{ props.row.giftMoney }}</span>
              </el-form-item>
              <el-form-item label="申请时间：">
                <span>{{ props.row.giftApplicationTime }}</span>
              </el-form-item>
              <el-form-item label="发放时间：">
                <span>{{ props.row.giftCollectionTime }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column prop="userId" label="用户ID" width="60">
        </el-table-column>
        <el-table-column prop="userName" label="用户名" width="100">
        </el-table-column>
        <el-table-column prop="gameName" label="游戏名称" width="100">
        </el-table-column>
        <el-table-column prop="serverName" label="领取区服" width="100">
        </el-table-column>
        <el-table-column prop="roleName" label="角色名字" width="100">
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          align="left"
          label="上报id"
          width="100"
        >
          <template slot-scope="{ row }">
            <span>{{ row.roleUserReport }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          label="礼包名称"
          width="120"
        >
          <template slot-scope="{ row }">
            <span>{{ row.giftName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="rechargeAmount" label="玩家消费金额" width="100">
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          align="left"
          label="拒绝理由"
          width="100"
        >
          <template slot-scope="{ row }">
            <span>{{ row.giftRejectReason }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="giftCurrentTime"
          :formatter="dateFormat"
          label="消费日期"
          width="85"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="roleProfessionalService"
          label="专属客服"
          :formatter="formatRoleProfessionalService"
          width="70"
        >
        </el-table-column>
        <el-table-column prop="giftState" label="礼包状态" width="70">
          <template slot-scope="scope">
            <span style="color: #67c23a" v-if="scope.row.giftState == 1"
              >已发放</span
            >
            <span style="color: #e6a23c" v-else-if="scope.row.giftState == 2"
              >审核中</span
            >
            <span style="color: #f56c6c" v-else-if="scope.row.giftState == 3"
              >拒绝申请</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.giftIssueType == 1 &&
                scope.row.giftState != 1 &&
                scope.row.giftState != 3
              "
            >
              <el-button
                @click="auditChange(scope.row.giftRecordId, 1)"
                type="text"
                size="small"
                >通过申请</el-button
              >
              <el-button
                @click="auditChange(scope.row.giftRecordId, 3)"
                type="text"
                size="small"
                >拒绝审核</el-button
              >
            </div>
            <div v-else-if="scope.row.giftIssueType == 1">
              <span v-if="scope.row.giftState == 1"> 审核通过 </span>
              <span v-else-if="scope.row.giftState == 3"> 拒绝申请 </span>
              <el-button
                style="width: 50px"
                @click="auditChange(scope.row.giftRecordId, 2)"
                type="text"
                size="small"
              >
                撤销
              </el-button>
            </div>
            <div v-else-if="scope.row.giftIssueType == 0">
              <span>自动发放 </span>
            </div>
            <el-button
              @click="getContactInfo(scope.row.userName)"
              type="text"
              size="small"
              >查看联系方式</el-button
            >
            <el-button
              @click="setRoleUserReport(scope.row)"
              type="text"
              size="small"
            >
              修改上报ID
            </el-button>
            <el-button
              @click="
                updateGameRoleService(
                  scope.row.roleId,
                  scope.row.roleProfessionalService
                )
              "
              type="text"
              size="small"
              >修改专属客服
            </el-button>
            <el-button @click="setCode(scope.row)" type="text" size="small"
              >设置礼包码</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="fixed-pagination"
        @current-change="loadData"
        background
        :current-page="currentIndex"
        :page-size="11"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  queryContactInfo,
  queryGiftRecord,
  updateGameRoleService,
  updateRoleUserReport,
  updateState,
} from "@/api/request";
import moment from "moment";

export default {
  name: "GiftRecord",
  inject: ["reload"],
  data() {
    return {
      tableData: [],
      total: 0,
      currentIndex: 1,
      queryField: {
        userId: "",
        userName: "",
        roleName:"",
        giftId: "",
        giftIssueType: "1",
        giftState: "",
      },
      height: window.innerHeight - 210,
      options: {
        giftIssueType: [
          {
            value: "1",
            label: "客服发放",
          },
          {
            value: "0",
            label: "自动发放",
          },
        ],
        giftState: [
          {
            value: "2",
            label: "审核中",
          },
          {
            value: "1",
            label: "已发放",
          },
          {
            value: "3",
            label: "拒绝申请",
          },
        ],
      },
    };
  },
  mounted() {
    this.loadData(this.currentIndex);
  },
  methods: {
    loadData(index) {
      let params = {
        pageNo: index,
        pageSize: 11,
        params: this.queryField,
      };
      queryGiftRecord(params).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentIndex = index;
      });
    },
    auditChange(giftRecordId, giftState) {
      let params = {
        giftRecordId: giftRecordId,
        giftState: giftState,
        giftCode: "",
        giftRejectReason: "",
      };
      if (giftState == 3) {
        this.$prompt("请输入拒绝理由（为空则无）", "拒绝申请", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(({ value }) => {
            if (value == null) {
              updateState(params).then((res) => {
                if (res.data.success) {
                  this.$message.success("已拒绝申请");
                } else {
                  this.$message.error(res.data.error.message);
                }
              });
              this.loadData(this.currentIndex);
            } else {
              (params.giftRejectReason = value),
                updateState(params).then((res) => {
                  if (res.data.success) {
                    this.$message({
                      type: "success",
                      message: "已拒绝申请",
                    });
                  } else {
                    this.$message.error(res.data.error.message);
                  }
                });
              this.loadData(this.currentIndex);
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消审核",
            });
          });
      } else if (giftState === 2) {
        updateState(params).then((res) => {
          if (res.data.success) {
            this.$message.success("已撤销审核");
          } else {
            this.$message.error(res.data.error.message);
          }
        });
      } else if (giftState === 1) {
        this.$prompt("请输入礼包码（为空则不发送礼包码）", "礼包码发送", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(({ value }) => {
            if (value == null) {
              updateState(params).then((res) => {
                if (res.data.success) {
                  this.$message.success("已通过申请");
                } else {
                  this.$message.error(res.data.error.message);
                }
              });
              this.loadData(this.currentIndex);
            } else {
              (params.giftCode = value),
                updateState(params).then((res) => {
                  if (res.data.success) {
                    this.$message({
                      type: "success",
                      message: "审核通过，礼包码已发送!",
                    });
                  } else {
                    this.$message.error(res.data.error.message);
                  }
                });
              this.loadData(this.currentIndex);
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消审核",
            });
          });
      }
      this.loadData(this.currentIndex);
    },

    getContactInfo(userName) {
      let contactInfoData = [];
      let contactInfoShow = "";
      let params = {
        userName: userName,
      };

      queryContactInfo(params).then((res) => {
        contactInfoData = res.data;
        for (let i = 0; i < contactInfoData.length; i++) {
          contactInfoShow =
            "<p>" +
            (i + 1) +
            "：WX  " +
            contactInfoData[i].giftContactInfo +
            "&nbsp; QQ " +
            contactInfoData[i].giftContactQQ +
            "</p>";
        }
        this.$alert(contactInfoShow, "联系方式", {
          confirmButtonText: "确定",
          dangerouslyUseHTMLString: true,
        });
      });
    },

    updateGameRoleService(roleId, roleProfessionalService) {
      if (roleProfessionalService === 0) {
        roleProfessionalService = 1;
      } else {
        roleProfessionalService = 0;
      }

      let params = {
        roleId: roleId,
        roleProfessionalService: roleProfessionalService,
      };
      this.$confirm("此操作将修改专属客服状态, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          updateGameRoleService(params).then((res) => {
            this.loadData(this.currentIndex);
            if (res.data.success) {
              this.$message.success("已修改专属客服状态");
            } else {
              this.$message.error(res.data.error.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
          this.submitting = false;
        });
    },

    formatRoleProfessionalService(row, column, cellValue) {
      let ret = "";
      if (cellValue === 0) {
        ret = "无";
      } else if (cellValue === 1) {
        ret = "有";
      }
      return ret;
    },

    setCode(item) {
      let params = {
        giftRecordId: item.giftRecordId,
        giftState: 1,
        giftRejectReason: item.giftRejectReason,
        giftCode: "",
      };

      this.$prompt("请输入礼包码（设置礼包码后改为已发放）", "礼包码设置", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          (params.giftCode = value),
            updateState(params).then((res) => {
              if (res.data.success) {
                this.$message({
                  type: "success",
                  message: "礼包码已设置!",
                });
                this.loadData(this.currentIndex);
              } else {
                this.$message.error(res.data.error.message);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消设置",
          });
        });
    },

    setRoleUserReport(item) {
      this.$prompt("请输入上报id", "修改上报Id", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          let params = {
            roleId: item.roleId,
            roleUserReport: value,
          };
          updateRoleUserReport(params).then((res) => {
            if (res.data.success) {
              this.$message("修改成功");
            } else {
              this.$message.error(res.data.error.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },

    /*日期处理*/
    dateFormat: function (row, column) {
      var date = row[column.property];
      if (date === undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>

<style scoped lang="scss">
.el-tooltip__popper {
  font-size: 14px;
  max-width: 40%;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>