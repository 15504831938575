<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>微信列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="page-content">
      <el-row @keyup.enter.native="loadData(1)">
        <el-input v-model="queryField.userId" size="small" placeholder="用户ID"></el-input>
        <el-input v-model="queryField.userName" size="small" placeholder="用户名"></el-input>
        <el-input v-model="queryField.thirdUserName" size="small" placeholder="微信名"></el-input>
        <el-button type="primary" icon="el-icon-search" size="small" @click="loadData(1)">查询
        </el-button>
      </el-row>
      <el-table class="cps-table-data"
                :data="tableData" size="small"
                border
                :height="height"
                style="width: 100%">
        <el-table-column
            prop="userId"
            label="用户ID"
            width="80">
        </el-table-column>
        <el-table-column
            prop="userName"
            label="用户名"
            width="120">
        </el-table-column>
        <el-table-column prop="infoImgUrl" label="微信头像" width="80">
          <template slot-scope="scope">
            <el-image
                style="width: 30px; height: 30px; border-radius: 3px"
                :src="scope.row.infoImgUrl"
                fit="cover"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
            prop="thirdUserName"
            label="微信昵称"
            width="120">
        </el-table-column>
        <el-table-column
            prop="infoCode"
            label="微信唯一标志"
            width="250">
        </el-table-column>
      </el-table>
      <el-pagination class="cps-table-data"
                     @current-change="loadData"
                     background
                     :page-size="18"
                     :current-page="currentIndex"
                     layout="total, prev, pager, next"
                     :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {queryWeChatList} from "@/api/request";

export default {
  name: 'WeChatList',
  inject: ['reload'],
  data() {
    return {
      //判断
      isSupper: false,

      //数据
      tableData: [],

      currentIndex:1,
      total: 0,
      height: window.innerHeight - 210,
      queryField: {
        userId:'',
        userName:'',
        thirdUserName:'',
      },

      form: {
      },
    };
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.isSupper = user.groupId === "1";
    this.loadData(1);
  },
  methods: {
    loadData(index) {
      let params = {
        pageNo: index,
        pageSize: 18,
        params: this.queryField,
      }
      queryWeChatList(params).then(res => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.currentIndex = index;
      })
    },
    //工具类
    refresh() {
      this.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
.cps-main-page {
  .cps-table-data {
    td {
      .el-image {
        display: inherit;
      }
    }
  }
}
</style>

