<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
      <el-breadcrumb-item>游戏推广列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="page-content">
      <el-row @keyup.enter.native="loadData(1)">
        <el-input v-model="queryField.extendId" size="small" placeholder="推广ID"></el-input>
        <el-input v-model="queryField.gameId" size="small" placeholder="游戏ID"></el-input>
        <el-select v-model="queryField.indexShow" size="small" placeholder="是否在广告位">
          <el-option
              v-for="item in optionsIndexShow"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="queryField.detailViewType" size="small" placeholder="详细页展示类型">
          <el-option
              v-for="item in optionsDetailViewType"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search" size="small" @click="loadData(1)">查询
        </el-button>
        <el-button type="primary" icon="el-icon-plus" size="small" @click="toAdd">新增</el-button>
      </el-row>
      <el-table class="cps-table-data"
                :data="tableData" size="small"
                border
                :height="height"
                style="width: 100%">
        <el-table-column
            prop="extendId"
            label="推广ID"
            width="60">
        </el-table-column>
        <el-table-column
            prop="gameId"
            label="游戏ID"
            width="60">
        </el-table-column>
        <el-table-column
            prop="gameName"
            label="游戏名称"
            width="140">
        </el-table-column>
        <el-table-column
            prop="tag"
            label="游戏标签"
            width="180">
        </el-table-column>
        <el-table-column
            prop="indexShow"
            label="是否在广告位"
            :formatter="formatIndexShow"
            width="100">
        </el-table-column>
        <el-table-column
            prop="detailViewType"
            label="详细页展示类型"
            :formatter="formatDetailViewType"
            width="105">
        </el-table-column>
        <el-table-column
            prop="url"
            label="游戏视图链接"
            width="300">
        </el-table-column>
        <el-table-column
            prop="viewImgUrl"
            label="视频图片链接"
            width="300">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="toEdit(scope.row.extendId)">编辑</el-button>
            <el-button type="text" size="small" @click="toUpload(scope.row.extendId,scope.row.gameId)">视图上传</el-button>
            <el-button type="text" size="small" @click="toUploadIMG(scope.row.extendId,scope.row.gameId)">视频图片上传</el-button>
            <el-button type="text" size="small" @click="deleteById(scope.row.extendId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="cps-table-data"
                     @current-change="loadData"
                     background
                     :page-size="10"
                     :current-page="currentIndex"
                     layout="total, prev, pager, next"
                     :total="total">
      </el-pagination>

      <el-dialog title="视频或图片上传" :visible.sync="dialogFormVisible" :center=true width="400px" :show-close=true
                 :close-on-click-modal=false :close-on-press-escape="false">
        <el-upload
            class="avatar-uploader"
            action=""
            :data="param"
            :http-request='httpRequest'
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传mp4文件，且视频比例为16：9不超过5GB</div>
        </el-upload>
        <el-progress ref="appLoadProgress" :percentage="loadProgress"></el-progress>
      </el-dialog>

      <el-dialog title="视频遮罩层图片上传" :visible.sync="dialogFormVisible1" :center=true width="400px" :show-close=true
                 :close-on-click-modal=false :close-on-press-escape="false">
        <el-upload
            class="avatar-uploader"
            action=""
            :data="param"
            :http-request='httpRequestIMG'
            :show-file-list="false"
            :on-success="handleAvatarSuccessIMG"
            :before-upload="beforeAvatarUploadIMG">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传png/jpg文件，且不超过2M</div>
        </el-upload>
        <el-progress ref="iconLoadProgress" :percentage="loadProgress"></el-progress>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  deleteGameExtend,
  queryGameExtendList, setImgGameExtend, setUrlGameExtend,
  uploadApp, uploadAppIMG,
} from "@/api/request";

export default {
  name: 'GameAppList',
  props: {
    pictureUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isSupper: false,
      imgSuffix: '',
      imageUrl: '',
      dialogFormVisible: false,
      dialogFormVisible1: false,
      tableData: [],
      param: {
        extendId: '',
        gameId:'',
        progress: ''
      },
      loadProgress: 0,
      queryField: {
        gameId: '',
        indexShow: '',
        detailViewType: '',
      },
      optionsIndexShow: [
        {
          value: '',
          label: '广告位'
        }, {
          value: '1',
          label: '在广告位'
        }, {
          value: '2',
          label: '不在广告位'
        },],
      optionsDetailViewType: [
        {
          value: '',
          label: '展示类型'
        }, {
          value: '1',
          label: '图片'
        }, {
          value: '2',
          label: '视频'
        }],
      total: 0,
      height: window.innerHeight - 210,
      currentIndex: 1,
    };
  },
  mounted() {
    this.loadData(1);
  },
  methods: {
    loadData(index) {
      let params = {
        pageNo: index,
        pageSize: 11,
        params: this.queryField,
      }
      queryGameExtendList(params).then(res => {
            this.tableData = res.data.list;
            this.total = res.data.total;
            this.currentIndex = index;
          }
      )
    },
    deleteById(extendId) {
      this.$confirm('此操作将删除该推广, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          extendId: extendId,
        }
        deleteGameExtend(params).then(res => {
          this.$message.success('推广删除成功');
          this.loadData(this.currentIndex);
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    toAdd() {
      this.$router.replace("/game/extend/add")
    },
    toEdit(extendId) {
      this.$router.replace(`/game/extend/edit/${extendId}`)
    },
    toUpload(extendId,gameId) {
      this.dialogFormVisible = true;
      this.param.extendId = extendId;
      this.param.gameId = gameId;
      this.$nextTick(function () {
        this.param.progress = this.$refs["appLoadProgress"];
      })
    },
    toUploadIMG(extendId,gameId) {
      this.dialogFormVisible1 = true;
      this.param.extendId = extendId;
      this.param.gameId = gameId;
      this.$nextTick(function () {
        this.param.progress = this.$refs["iconLoadProgress"];
      })
    },
    formatIndexShow(row, column, cellValue) {
      let ret = ''
      if (cellValue === 1) {
        ret = "推广中"
      } else {
        ret = "没有推广"
      }
      return ret;
    },
    formatDetailViewType(row, column, cellValue) {
      let ret = ''
      if (cellValue === 1) {
        ret = "图片"
      } else {
        ret = "视频"
      }
      return ret;
    },

// 上传文件之前的钩子
    beforeAvatarUpload(file) {
      let filename = file.name;
      let pos = filename.lastIndexOf('.');
      let suffix = '';
      if (pos != -1) suffix = filename.substring(pos);
      this.imgSuffix = suffix;
      //判断文件格式 根据文件格式分流判断
      if (suffix === ".mp4"){
        if (!(file.size / 1024 / 1024 / 1024 < 5)){
          this.$message.error('上传文件大小不能超过 5GB!');
        }
        // else if (!((file.videoWidth / file.videoHeight) === (16 / 9))){
        //   this.$message.error('上传视频比例为16：9!');
        // }
        else {
          return true
        }
      }else if (suffix === ".jpeg" || suffix === ".png"){
        if (!(file.size / 1024 / 1024 < 2)){
          this.$message.error('上传图片大小不能超过 2MB!');
        }else {
          return true;
        }
      }else {
        this.$message.error('只能上传mp4,或jpeg、png文件！');
      }
      return false;
    },

    //文件检验
    beforeAvatarUploadIMG(file) {
      const isJPEG = file.type === 'image/jpeg';
      const isJPG = file.type === 'image/jpg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!(isJPG || isPNG || isJPEG)) {
        this.$message.error('上传图片只能是JPG或者PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      //文件名
      let filename = file.name;
      //.的个数
      let pos = filename.lastIndexOf('.');
      let suffix = '';
      //获得尾缀
      if (pos != -1) suffix = filename.substring(pos);
      //核对通过
      return (isJPG || isPNG) && isLt2M;
    },

    handleAvatarSuccess(file) {
      let param = {
        url:sessionStorage.getItem('qlUrl'),
        extendId:this.param.extendId,
      }
      setUrlGameExtend(param).then(res => {
        if (res.data.success) {
          this.$message.success("文件上传成功")
          this.loadData(this.currentIndex);
          this.dialogFormVisible = false;
        } else {
          this.$message.error(res.data.error.message);
        }
      })
    },
    handleAvatarSuccessIMG(file) {
      let param = {
        extendId:this.param.extendId,
        viewImgUrl:sessionStorage.getItem('qlUrl'),
      }
      setImgGameExtend(param).then(res => {
        if (res.data.success) {
          this.$message.success("文件上传成功")
          this.loadData(this.currentIndex);
          this.dialogFormVisible1 = false;
        } else {
          this.$message.error(res.data.error.message);
        }
      })
    },
    async httpRequest(item) {
      let result = await uploadApp(item)
    },
    //上传操作
    async httpRequestIMG(item) {
      let result = await uploadAppIMG(item)
    },
  }
}
</script>
<style lang="scss" scoped>
.cps-main-page {
  .cps-table-data {
    td {
      .el-image {
        display: inherit;
      }
    }
  }
}
</style>

